import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["recentHistory", "replyRecommendations"];
import { fromJS } from 'immutable';
import get from 'transmute/get';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
// @ts-expect-error module not typed
import ThreadHistory from 'conversations-message-history/thread-history/records/ThreadHistory';
import { fetchThreadListMemberDetails as fetchThreadListMemberDetailsClient } from '../clients/fetchThreadListMemberDetails';
import { FETCH_THREAD_LIST_MEMBER_DETAILS } from '../constants/asyncActionTypes';
import { ThreadDetails } from '../records/ThreadDetails';
export const fetchThreadListMemberDetails = createAsyncAction({
  requestFn: fetchThreadListMemberDetailsClient,
  actionTypes: FETCH_THREAD_LIST_MEMBER_DETAILS,
  toRecordFn: response => {
    const _ref = response || {},
      {
        recentHistory: threadHistory,
        replyRecommendations
      } = _ref,
      threadDetails = _objectWithoutPropertiesLoose(_ref, _excluded);
    return {
      threadListMemberDetails: new ThreadDetails(threadDetails),
      threadHistory: new ThreadHistory(Object.assign({
        replyRecommendations
      }, threadHistory)),
      // @ts-expect-error threadHistory is not typed on the BE, it's just unknown
      attachments: fromJS(get('attachments', threadHistory))
    };
  }
});