import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["portalId"];
import memoize from 'transmute/memoize';
import { isObject, pick } from '../../utils/common';
import { getHref } from '../../utils/wrappers';
const initPayloadKeys = new Set(['fromObjectId', 'fromObjectTypeId', 'origin']);
function isEmptyObject(obj) {
  return isObject(obj) && Object.keys(obj).length === 0;
}
function hasOrigin(payload) {
  return isObject(payload) && 'origin' in payload && typeof payload.origin === 'string';
}
const hasValidOrigin = memoize(payload => {
  if (!hasOrigin(payload)) {
    return false;
  }
  try {
    return !!new URL(payload.origin).host;
  } catch (_unused) {
    return false;
  }
}, payload => hasOrigin(payload) ? payload.origin : 'unset-origin');
export function hasFromObject(payload) {
  return !!(isObject(payload) && 'fromObjectId' in payload && 'fromObjectTypeId' in payload);
}
export function hasValidFromObject(payload) {
  return Boolean(hasFromObject(payload) && typeof payload.fromObjectId === 'number' && !isNaN(payload.fromObjectId) && payload.fromObjectTypeId && typeof payload.fromObjectTypeId === 'string' && payload.fromObjectTypeId.length);
}
export function hasValidInitPayload(payload) {
  if (!isObject(payload)) {
    return false;
  }
  if (isEmptyObject(payload)) {
    return true;
  }
  return (!hasFromObject(payload) || hasValidFromObject(payload)) && (!hasOrigin(payload) || hasValidOrigin(payload));
}
export function getValidatedInitPayload(payload) {
  if (!hasValidInitPayload(payload)) {
    return {};
  }
  return pick(initPayloadKeys, payload);
}
export function getBillingWizardURL(params) {
  const {
      portalId
    } = params,
    rest = _objectWithoutPropertiesLoose(params, _excluded);
  const parameters = Object.assign({}, hasValidFromObject(rest) && {
    fromObjectId: rest.fromObjectId.toString(),
    fromObjectTypeId: rest.fromObjectTypeId
  }, {
    origin: hasValidOrigin(rest) ? rest.origin : getHref()
  });
  return `/billing-wizard-ui/${portalId}/create?${new URLSearchParams(Object.entries(parameters)).toString()}`;
}