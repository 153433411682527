import { userInfoSync } from 'hub-http/userInfo';
import PortalIdParser from 'PortalIdParser';
import { getFrom } from '../LocalStorage';
export const isSkeletonStateValid = skeletonState => {
  if (!skeletonState) {
    return false;
  }
  const hasValidState = typeof skeletonState.state === 'object' && skeletonState.state != null;
  if (hasValidState) {
    return true;
  }
  return false;
};
export function makeSkeletonStateKey() {
  const userId = userInfoSync().user.user_id;
  return `${PortalIdParser.get()}:${userId}:SKELETON`;
}
export const getSkeletonStateSafely = () => {
  const localStorageKey = makeSkeletonStateKey();
  const skeletonState = getFrom(localStorageKey);

  // Return an empty state if there is not one in the cache
  if (!isSkeletonStateValid(skeletonState)) {
    return {
      state: {},
      cleanupTimestamp: Date.now()
    };
  }
  return skeletonState;
};