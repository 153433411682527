import { Plugin, PluginKey } from 'prosemirror-state';
import uuid from '../utils/uuid';
export function isContentfulState(editorState) {
  if (editorState.doc.content.size <= 2) {
    return false;
  }
  const contentFragment = editorState.doc.content;
  let contentfulCount = 0;
  for (let i = contentFragment.childCount - 1; i >= 0; i--) {
    const child = contentFragment.child(i);
    // Ignore some node types
    if (child.type.name === 'branding' || child.type.name === 'emailReplyHistory') {
      continue;
    }

    // Return immediately if a child has real content
    if (child.content.size > 0) {
      return true;
    }

    // Need to see at least two contentful nodes
    if (++contentfulCount > 1) {
      return true;
    }
  }
  return false;
}
export const getPlaceholderPlugin = placeholderString => {
  return new Plugin({
    key: new PluginKey(`PlaceholderPlugin_${uuid()}`),
    view() {
      return {
        update: view => {
          // Grab the editor body container, which is two elements up from the
          // editor itself. We use this element in particular because it's
          // location in the dom does not interfere with the editor
          const editorBodyContainer = view.dom.parentElement && view.dom.parentElement.parentElement;
          if (editorBodyContainer) {
            // These two constants get the current state of the the editors
            // content and if there is a placeholder already
            const stateHasContent = isContentfulState(view.state);
            const currentPlaceholderElement = [...editorBodyContainer.children].find(childElement => {
              return childElement.classList.contains('ProseMirrorPlaceholder');
            });

            /*
              There are four states the placeholder could be in:
                1) The state has content and there is a placeholder
                2) The state has content and there is not a placeholder
                3) The state does not have content and there is a placeholder
                4) The state does not have cotent and there is not a placeholder
               Of the above, 2 and 3 are acceptable states while 1 and 4 are not.
              The below if else if statement take 1 and makes it 2, and takes 4
              and makes it 3.
            */
            if (stateHasContent && currentPlaceholderElement) {
              currentPlaceholderElement.remove();
            } else if (!stateHasContent && !currentPlaceholderElement) {
              const placeholderElement = document.createElement('span');
              placeholderElement.classList.add('ProseMirrorPlaceholder');
              placeholderElement.innerText = placeholderString;
              editorBodyContainer.prepend(placeholderElement);
            }
          }
        }
      };
    }
  });
};