import { registerQuery, useQuery } from 'data-fetching-client';
import { USER_SETTINGS, userAttributesClient } from '../api/userSettingsAPI';
const fetchUserSettings = () => userAttributesClient.batchFetch({
  [USER_SETTINGS.HIDE_MANUAL_EMAIL_BUTTON_POPOVER]: 'false'
});
const USER_SETTINGS_QUERY = registerQuery({
  fieldName: 'userSettings',
  fetcher: fetchUserSettings
});
export const useUserSettings = (skip = false) => useQuery(USER_SETTINGS_QUERY, {
  skip
});