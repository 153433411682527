import DefaultServiceClient from './DefaultServiceClient';
import DefaultService from './DefaultService';
import WrappedClient from '../api/WrappedClient';
export const createDefaultService = ({
  appName,
  bindPortalIdToClient,
  portalId,
  track,
  tracker,
  apiClient = DefaultServiceClient,
  gates
}) => new DefaultService({
  appName,
  portalId,
  track,
  tracker,
  apiClient: bindPortalIdToClient ? WrappedClient(apiClient, portalId) : apiClient,
  gates
});