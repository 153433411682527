import { CallWidgetStates } from 'calling-internal-common/widget-status/constants/CallWidgetStates';
import { ToWorkerMessageTypes } from 'calling-cross-tab-library/messages/ToWorkerMessageTypes';
import { addBaseMessageProperties } from 'calling-cross-tab-library/messages/addBaseMessageProperties';
import { MessageRelayTypes } from 'calling-cross-tab-library/types/MessageRelayTypes';
export const createUpdateWidgetDetailsMessage = ({
  widgetDetails,
  originConnectionId
}) => Object.assign({}, addBaseMessageProperties(MessageRelayTypes.APP_DATA_UPDATE), {
  type: ToWorkerMessageTypes.UPDATE_WIDGET_DETAILS,
  originConnectionId,
  payload: {
    updatedWidgetDetails: widgetDetails
  }
});
export const createUpdateCallSettingsMessage = ({
  callSettings,
  originConnectionId
}) => Object.assign({}, addBaseMessageProperties(MessageRelayTypes.APP_DATA_UPDATE), {
  type: ToWorkerMessageTypes.UPDATE_CALL_SETTINGS,
  originConnectionId,
  payload: {
    updatedCallSettings: callSettings
  }
});
export const createResetActiveCallDetailsMessage = originConnectionId => Object.assign({}, addBaseMessageProperties(MessageRelayTypes.APP_DATA_UPDATE), {
  type: ToWorkerMessageTypes.UPDATE_WIDGET_DETAILS,
  originConnectionId,
  payload: {
    updatedWidgetDetails: {
      activeCallDetails: undefined,
      callStatus: CallWidgetStates.READY,
      transferInformation: undefined
    }
  }
});
export const createFromWorkerStandaloneHeartbeatResponse = requestId => Object.assign({}, addBaseMessageProperties(MessageRelayTypes.TO_WORKER), {
  type: ToWorkerMessageTypes.FROM_WORKER_STANDALONE_HEARTBEAT_RESPONSE,
  destinationConnectionId: MessageRelayTypes.FROM_WORKER,
  responseRequest: {
    requestId
  }
});
export const createFromWorkerActiveCallConnectionPingResponse = requestId => Object.assign({}, addBaseMessageProperties(MessageRelayTypes.TO_WORKER), {
  type: ToWorkerMessageTypes.FROM_WORKER_ACTIVE_CALL_CONNECTION_PING_RESPONSE,
  destinationConnectionId: MessageRelayTypes.FROM_WORKER,
  responseRequest: {
    requestId
  }
});