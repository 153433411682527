export let SLAStatus;
(function (SLAStatus) {
  SLAStatus["IN_SLA"] = "IN_SLA";
  SLAStatus["BREACHED"] = "BREACHED";
  SLAStatus["SLA_WARNING"] = "SLA_WARNING";
  SLAStatus["RESOLVED_IN_SLA"] = "RESOLVED_IN_SLA";
  SLAStatus["RESOLVED_OUTSIDE_SLA"] = "RESOLVED_OUTSIDE_SLA";
})(SLAStatus || (SLAStatus = {}));
export let SLAType;
(function (SLAType) {
  SLAType["TIME_TO_CLOSE"] = "TIME_TO_CLOSE";
  SLAType["TIME_TO_RESPOND"] = "TIME_TO_RESPOND";
})(SLAType || (SLAType = {}));