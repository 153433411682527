import { buildAudit } from 'conversations-message-history/audit/operators/buildAudit';
import { AssignmentRemoval, FilterChangeRemoval, HardDeletionRemoval, InboxChangeRemoval, MultipleRemoval, ThreadStatusRemoval, TicketAssociationRemoval, UnknownRemoval, ThreadUpdate } from 'find-and-filter-data/realtime-view-member-schema/public';
import { buildThreadListMember, buildPartialThreadListMembers, getLatestReceivedTimestamp, getThreadId, getSeen } from 'find-and-filter-data/view-members-schema/protected';
import { isViewMemberProperty } from 'find-and-filter-data/view-members-schema/public';
import { List, OrderedMap } from 'immutable';
import curry from 'transmute/curry';
import get from 'transmute/get';
import isUndefined from 'transmute/isUndefined';
import map from 'transmute/map';
import pipe from 'transmute/pipe';
import reduce from 'transmute/reduce';
import set from 'transmute/set';
import setIn from 'transmute/setIn';
import toJS from 'transmute/toJS';
import { indexThreadListMembers } from './indexThreadListMembers';
const getSeenByAgentIds = get('seenByAgentIds');
const setThreadRemovalMembers = set('members');
const getThreadRemovalMembers = get('members');
const getThreadUpdateMembers = get('members');
const setThreadUpdateMembers = setIn(['members']);
const setSeen = setIn(['seen']);
const resolveSeen = (currentMember, addedMember) => {
  const addedSeenValue = getSeen(addedMember);
  const shouldUseCurrentMemberSeenValue = addedSeenValue === null && currentMember;
  return shouldUseCurrentMemberSeenValue ? setSeen(getSeen(currentMember), addedMember) : addedMember;
};

/**
 * @description The backend can't always serve information
 * on whether an update has been seen yet. If a member
 * being merged into an existing one has a more recent timestamp,
 * set return a seen value of false. Otherwise, use the seen
 * value defined on the incoming message (if one is defined).
 * @param currentMember
 * @param nextPartialMember
 */
function resolveSeenFromPartial(currentMember, nextPartialMember) {
  const originalTimestamp = getLatestReceivedTimestamp(currentMember);
  const nextTimestamp = getLatestReceivedTimestamp(nextPartialMember);
  const originalSeen = getSeen(currentMember);
  const nextSeen = getSeen(nextPartialMember);

  // The next member represents a newer value. The default
  // expectation here is that the user hasn't seen this new
  // value yet.
  if (nextTimestamp && originalTimestamp && nextTimestamp > originalTimestamp) {
    return false;
  }

  // Only return the next seen value if the partial defines
  // one (can be null).
  if (!isUndefined(nextSeen)) {
    return Boolean(nextSeen);
  }
  return originalSeen;
}
function applyPartialToViewMember(viewMember, partialMember) {
  const isMemberSeen = resolveSeenFromPartial(viewMember, partialMember);
  const merged = reduce(viewMember,
  // @ts-expect-error The reduce function's aggregator type is incorrect; it does not supply keys as the third function parameter
  (memberRecord, value, key) => {
    return isViewMemberProperty(key) ? set(key, value, memberRecord) : memberRecord;
  }, partialMember);
  return set('seen', isMemberSeen, merged);
}

/**
 * @description Given a Map of indexed thread list members and
 * a map of indexed partial members, merge into existing
 * members using mergeIndexedViewMembers or build new members if
 * they don't exist in the source map.
 * @argument indexedViewMembers
 * @argument indexedPartialMembers
 */
function mergeIndexedViewMembers(indexedViewMembers, indexedPartialMembers) {
  return indexedPartialMembers.reduce((indexedMembers, partialMember, threadId) => {
    const existingMember = get(threadId, indexedMembers);
    const nextMember = existingMember ? applyPartialToViewMember(existingMember, partialMember) : buildThreadListMember(toJS(partialMember));
    return indexedMembers.set(threadId, nextMember);
  }, indexedViewMembers);
}

/**
 * Merges added members into the indexed map of ThreadListMembers
 * @param addedMembers
 * @param currentMembers
 */
const mergeAdded = curry((addedMembers, currentMembers) => {
  const addedMembersWithNonNullSeen = addedMembers.map(addedMember => {
    const threadId = getThreadId(addedMember);
    const currentMember = currentMembers.get(`${threadId}`);
    return resolveSeen(currentMember, addedMember);
  }).toList();
  return currentMembers.merge(indexThreadListMembers(addedMembersWithNonNullSeen));
});

/**
 * Merges updated members into the indexed map of ThreadListMembers, creating
 * new ones if needed.
 * @param updatedMembers
 * @param currentMembers
 */
const mergeUpdated = curry((incomingMemberChanges, currentMembers) => {
  const partialMembersWithUpdates = incomingMemberChanges.reduce((acc, update) => {
    const membersWithUpdates = getThreadUpdateMembers(update).map(partialMember => setIn(['update'], setThreadUpdateMembers(null, update), partialMember));
    const indexedMembers = indexThreadListMembers(membersWithUpdates);
    return acc.merge(indexedMembers);
  }, OrderedMap());
  const merged = mergeIndexedViewMembers(currentMembers, partialMembersWithUpdates);
  return merged;
});
const mergeRemovals = curry((removals, currentMembers) => {
  const partialMembersWithRemovals = removals.reduce((accumulator, removal) => {
    const members = getThreadRemovalMembers(removal);
    const indexedMembersWithRemovals = members.reduce((membersAccumulator, partialMember) => {
      const threadId = getThreadId(partialMember);
      const partialMemberWithRemoval = setIn(['removal'],
      // @ts-expect-error members is typed to not be null, but leaving this to avoid logic changes
      setThreadRemovalMembers(null, removal), partialMember);
      return membersAccumulator.set(`${threadId}`, partialMemberWithRemoval);
    }, OrderedMap());
    return accumulator.merge(indexedMembersWithRemovals);
  }, OrderedMap());
  const merged = mergeIndexedViewMembers(currentMembers, partialMembersWithRemovals);
  return merged;
});
const buildThreadRemoval = attributes => {
  const {
    audit,
    members,
    ['@type']: type
  } = attributes;
  switch (type) {
    case 'ASSIGNMENT':
      {
        const {
          assignee
        } = attributes;
        return AssignmentRemoval({
          ['@type']: type,
          assignee,
          audit: audit ? buildAudit(audit) : null,
          members: buildPartialThreadListMembers(members)
        });
      }
    case 'FILTER_CHANGE':
      {
        const {
          filtered
        } = attributes;
        return FilterChangeRemoval({
          ['@type']: type,
          audit: audit ? buildAudit(audit) : null,
          filtered,
          members: buildPartialThreadListMembers(members)
        });
      }
    case 'THREAD_STATUS':
      {
        const {
          status
        } = attributes;
        return ThreadStatusRemoval({
          ['@type']: type,
          audit: audit ? buildAudit(audit) : null,
          members: buildPartialThreadListMembers(members),
          status
        });
      }
    case 'TICKET_ASSOCIATION':
      {
        return TicketAssociationRemoval({
          ['@type']: type,
          audit: audit ? buildAudit(audit) : null,
          members: buildPartialThreadListMembers(members)
        });
      }
    case 'HARD_DELETION':
      {
        return HardDeletionRemoval({
          ['@type']: type,
          audit: audit ? buildAudit(audit) : null,
          members: buildPartialThreadListMembers(members)
        });
      }
    case 'INBOX_CHANGE':
      {
        const {
          inboxId
        } = attributes;
        return InboxChangeRemoval({
          ['@type']: type,
          audit: audit ? buildAudit(audit) : null,
          members: buildPartialThreadListMembers(members),
          inboxId
        });
      }
    case 'MULTIPLE':
      {
        const {
          changes
        } = attributes;
        return MultipleRemoval({
          ['@type']: type,
          audit: audit ? buildAudit(audit) : null,
          changes: buildThreadRemovals(changes),
          members: buildPartialThreadListMembers(members)
        });
      }
    case 'UNKNOWN':
    default:
      {
        return UnknownRemoval({
          audit: audit ? buildAudit(audit) : null,
          members: buildPartialThreadListMembers(members)
        });
      }
  }
};
function buildThreadRemovals(removals) {
  return List(map(buildThreadRemoval, removals));
}
const buildThreadUpdate = attributes => {
  const {
    audit,
    members
  } = attributes;
  return ThreadUpdate({
    audit: buildAudit(audit),
    members: buildPartialThreadListMembers(members)
  });
};
const buildThreadUpdates = updates => {
  return List(map(buildThreadUpdate, updates));
};
const buildThreadAdded = currentAgentId => addedThreadListMember => {
  let threadListMember = buildThreadListMember(addedThreadListMember);
  const seenAgentIds = getSeenByAgentIds(addedThreadListMember);
  if (!!seenAgentIds && seenAgentIds.includes(currentAgentId)) {
    threadListMember = setIn(['seen'], true, threadListMember);
  }
  return threadListMember;
};
const buildThreadsAdded = (added, currentAgentId) => {
  return List(map(buildThreadAdded(currentAgentId), added));
};

/**
 * @description This function will take a realtime ThreadsUpdated message and
 * apply all the changes to the given map of ViewMembers, returning a new map
 * with all those applied changes.
 *
 * @param viewMembers
 * @param viewMembersUpdated
 * @param currentAgentId Needed to resolve seen correctly for added ViewMembers
 */
export function mergeViewMembersWithUpdates(viewMembers, viewMembersUpdated, currentAgentId) {
  const added = buildThreadsAdded(viewMembersUpdated.added, currentAgentId);
  const updated = buildThreadUpdates(viewMembersUpdated.updated);
  const removals = buildThreadRemovals(viewMembersUpdated.removed);
  const updatedMembers = pipe(mergeAdded(added), mergeUpdated(updated), mergeRemovals(removals))(viewMembers);
  return updatedMembers;
}