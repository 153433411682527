import { PaymentStatus, SubscriptionActions, SubscriptionStatus } from '../types/subscriptions';
import { isNone, isNumericAndEqual, isPositive, isSome, values } from './common';
const SUBSCRIPTION_MODIFIABLE_STATUSES = new Set([SubscriptionStatus.Active, SubscriptionStatus.PastDue, SubscriptionStatus.Unpaid, SubscriptionStatus.Scheduled, SubscriptionStatus.Paused]);
const SUBSCRIPTION_MODIFIABLE_AND_ACTIVE_STATUSES = new Set([SubscriptionStatus.Active, SubscriptionStatus.Scheduled, SubscriptionStatus.Paused]);
const SUBSCRIPTION_DELETABLE_STATUSES = new Set([SubscriptionStatus.Canceled, SubscriptionStatus.Expired]);
export function isSubscriptionEditable(status) {
  return SUBSCRIPTION_MODIFIABLE_STATUSES.has(status);
}
export function isSubcriptionEditableAndActive(status) {
  return SUBSCRIPTION_MODIFIABLE_AND_ACTIVE_STATUSES.has(status);
}
export function isSubscriptionDeletable(status) {
  return SUBSCRIPTION_DELETABLE_STATUSES.has(status);
}
export function isSubscriptionCredit(immediateDue) {
  return isSome(immediateDue) && immediateDue < 0;
}
export function isSubscriptionPaymentProcessing(status) {
  return status === PaymentStatus.Processing || status === PaymentStatus.ProcessingRefund;
}

/**
 * The first payment is considered processing if:
 * - the subscription has no completed payments and the last payment status is processing.
 * - the subscription has 0 - 1 completed payment and the last payment status is processing.
 * This is because the completed payments count is incremented when the payment *starts* processing,
 * So depending on:
 * - latency updating the CRM object(s)
 * - whether or not the subscription is scheduled
 * the completed payments count may be null, 0 or 1.
 */
export function getIsFirstPaymentProcessing(completedPayments, paymentStatus) {
  const isPaymentProcessing = paymentStatus === PaymentStatus.Processing;
  if (isNone(completedPayments) && isPaymentProcessing) {
    return true;
  }
  return (completedPayments === 0 || completedPayments === 1) && isPaymentProcessing;
}
export function isComplete(subscription) {
  return isPositive(subscription.numberOfExpectedPayments) && isNumericAndEqual(subscription.numberOfExpectedPayments, subscription.numberOfCompletedPayments) && subscription.status !== SubscriptionStatus.Scheduled;
}

/**
 * A convenience wrapper around Array.reduce.
 * Much of the subscription action logic is powered by keying into objects based on an ActionType.
 * This makes it easy to create a Record<ActionTypes, T>
 * @example
 * const actions = makeActionsRecord<boolean>(() => false);
 * console.log(actions)
 * // {
 * //   cancel: false,
 * //   edit: false,
 * //   update-payment-method: false,
 * //   change-billing-date: false,
 * // }
 *
 * const actionState = useAppSelector(s => s.actions);
 * const actionsThatAreOpen = makeActionsRecord<{ isOpen: boolean }>((actionName) => {
 *   return {
 *     isOpen: actionState[actionName].isOpen,
 *   }
 * });
 * console.log(actionsThatAreOpen)
 * // {
 * //   cancel: { isOpen: false },
 * //   edit: { isOpen: false },
 * //   update-payment-method: { isOpen: false },
 * //   change-billing-date: { isOpen: true },
 * // }
 */
export function makeActionsRecord(entryCallback, startingValue = {}) {
  return values(SubscriptionActions).reduce((acc, actionName) => {
    acc[actionName] = entryCallback(actionName);
    return acc;
  }, startingValue);
}