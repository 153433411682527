import { SUBSCRIPTION_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { registerMutation } from 'data-fetching-client';
import apiClient from 'hub-http/clients/apiClient';
export function deleteSubscription({
  objectId
}) {
  return apiClient.delete(`inbounddb-objects/v1/crm-objects/${SUBSCRIPTION_TYPE_ID}/${objectId}`);
}
export const DELETE_SUBSCRIPTION = registerMutation({
  fieldName: 'deleteResponse',
  args: ['objectId'],
  fetcher: deleteSubscription
});