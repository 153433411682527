import { establishConnection, sendTestEmail, checkTestEmail
// @ts-expect-error not typed
} from '../actions/TestMessageActions';
const LANG_ROUTE = 'InboxConnectUtils.wizard.testMessage.';
export default [{
  message: `${LANG_ROUTE}establishingConnection`,
  operation: establishConnection
}, {
  message: `${LANG_ROUTE}sendingTestEmail`,
  operation: sendTestEmail
}, {
  message: `${LANG_ROUTE}verifyingConnection`,
  operation: checkTestEmail
}];