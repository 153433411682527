import styled from 'styled-components';
import { GREAT_WHITE } from 'HubStyleTokens/colors';
import UIFlex from 'UIComponents/layout/UIFlex';
import { SIDEBAR_BASE_Z_INDEX } from '../constants/SidebarConstants';
const shadowStyle = '0 0 45px rgba(0, 0, 0, 0.2)';
const CollapsableSidebar = styled(UIFlex).withConfig({
  displayName: "CollapsableSidebar",
  componentId: "sc-9s79ne-0"
})(["position:absolute;z-index:", ";overflow:hidden;width:", "px !important;max-width:", "px !important;min-width:", "px !important;box-shadow:", ";transition:all 200ms cubic-bezier(0.42,0,0.58,1);border-right:1px solid ", ";"], SIDEBAR_BASE_Z_INDEX, ({
  width
}) => width, ({
  fullWidth
}) => fullWidth, ({
  collapsedWidth
}) => collapsedWidth, ({
  showShadow
}) => showShadow ? shadowStyle : 'none', GREAT_WHITE);
export default CollapsableSidebar;