export const GOOGLE_FREE = 'GOOGLE_FREE';
export const GOOGLE_APPS = 'GOOGLE_APPS';
export const GOOGLE_FREE_API = 'GOOGLE_FREE_API';
export const GOOGLE_APPS_API = 'GOOGLE_APPS_API';
export const HUBSPOT_HOSTED = 'HUBSPOT_HOSTED';
export const OUTLOOK = 'OUTLOOK';
export const OUTLOOK365 = 'OUTLOOK365';
export const OUTLOOK_IMAP = 'OUTLOOK_IMAP';
export const UNKNOWN_IMAP = 'UNKNOWN_IMAP';
export const EXCHANGE = 'EXCHANGE';
export const AOL = 'AOL';
export const O365_IMAP = 'O365_IMAP';
export const FASTMAIL = 'FASTMAIL';
export const UNKNOWN = 'UNKNOWN';