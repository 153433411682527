'use es6';

import { Record } from 'immutable';
const GenericInboxRecord = Record({
  acceptUntrustedCert: false,
  created: null,
  customUsername: '',
  email: '',
  id: null,
  imapHost: '',
  imapPort: 993,
  password: '',
  smtpHost: '',
  smtpPort: 465,
  updated: null,
  smtpSameCreds: true,
  smtpUsername: '',
  smtpPassword: '',
  useStaticIps: false,
  settingsSource: ''
}, 'GenericInboxRecord');
GenericInboxRecord.fromJS = json => {
  if (!json || typeof json !== 'object') {
    return json;
  }
  const genericInbox = GenericInboxRecord(json);
  return genericInbox;
};
export default GenericInboxRecord;