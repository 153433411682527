import { BILLING_READ, SKILLS_ACCESS } from '../constants/scopes';
import { memoizedScopesSet, useUserInfo } from './useUserInfo';
export const useScopes = () => memoizedScopesSet(useUserInfo());
export const useScope = scope => useScopes().has(scope);
export const createHasScopeHook = scope => () => useScope(scope);

/*
  Put individual scope-check hooks below this comment
  For single scope checks, just do `const useHasMyScope = createHasScopeHook(MY_SCOPE)`
  Since hooks should not be called conditionally, for multi-scope checks, do
    const useHasMultipleScopes = () => {
      const scopes = useScopes();

      return scopes.has(MY_SCOPE) &&
        scopes.has(OTHER_SCOPE) &&
          scopes.has(THIRD_SCOPE);
    };
*/

export const useHasSkillsAccessScope = createHasScopeHook(SKILLS_ACCESS);
export const useHasBillingReadScope = createHasScopeHook(BILLING_READ);