import { buildTlmFromViewMember } from '../../../view-members-schema/protected';
import { ViewMembersPage } from '../../../view-members-schema/public';
import { indexThreadListMembers } from '../thread-list-member/indexThreadListMembers';

/**
 * These next two functions exist solely to deal with the transitory state between
 * when ViewMembersPageResponseObject is deprecated but in use and before
 * ViewMembersPageResponse and SpamMembersPageResponse are fully implemented.
 * */
function hasPagedResults(page) {
  return page.pagedResult && page.pagedResult.results && page.pagedResult.results.length > 0;
}
function hasViewMemberPagedResults(page) {
  return page.viewMemberPagedResult && page.viewMemberPagedResult.results && page.viewMemberPagedResult.results.length > 0;
}
function isViewMemberPage(maybeViewMemberPage) {
  return maybeViewMemberPage && maybeViewMemberPage.viewMemberPagedResult && maybeViewMemberPage.viewMemberPagedResult !== undefined;
}
function parseViewMembersPage(page) {
  let pagedResult;
  const onlyPagedResults = hasPagedResults(page) && !hasViewMemberPagedResults(page);
  if (onlyPagedResults) {
    return {
      latestPlaybackMessageTimestamp: page.latestPlaybackMessageTimestamp,
      totalCount: page.totalCount,
      results: page.pagedResult.results,
      offset: page.pagedResult.offset,
      hasMore: page.pagedResult.hasMore
    };
  }
  if (isViewMemberPage(page)) {
    pagedResult = page.viewMemberPagedResult;
  } else {
    pagedResult = page.pagedResult;
  }
  return {
    latestPlaybackMessageTimestamp: 'latestPlaybackMessageTimestamp' in page ? page.latestPlaybackMessageTimestamp : undefined,
    totalCount: page.totalCount,
    results: pagedResult.results,
    offset: pagedResult.offset,
    hasMore: pagedResult.hasMore
  };
}

/**
 * This still supports the ViewMemberPageResponseObject as the type is functionally the same as SpamMemberPageResponse.
 * ViewMemberPageResponseObject does not accurately reflect that the operating property is viewMemberPagedResult, not pagedResult.
 * */
export function buildViewMembersPage(page) {
  const {
    latestPlaybackMessageTimestamp,
    hasMore,
    results,
    offset,
    totalCount
  } = parseViewMembersPage(page);
  const members = results.map(viewMember => buildTlmFromViewMember(viewMember));
  const indexedMembers = indexThreadListMembers(members);
  return new ViewMembersPage({
    hasMore,
    indexedMembers,
    offset,
    totalCount,
    latestAppliedPlaybackMessageTimestamp: latestPlaybackMessageTimestamp
  });
}