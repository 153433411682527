import http from 'conversations-http/clients/http';
import { mapContactsToEmailAddressRecords } from '../operators/mapContactsToEmailAddressRecords';
export const fetchEmailAddressRecords = ({
  emailAddresses
}) => http.get('contacts/v1/contact/emails/batch', {
  query: {
    email: emailAddresses.toArray(),
    resolveOwner: false,
    showSourceMetadata: false,
    identityProfileMode: 'all',
    showPastListMemberships: false,
    formSubmissionMode: 'none',
    showPublicToken: false,
    propertyMode: 'value_only',
    showAnalyticsDetails: false,
    resolveAssociations: false
  }
}).then(contacts => {
  return mapContactsToEmailAddressRecords(Object.values(contacts));
});