/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { checkThreadMembership } from './filterResultsActions';
import { selectFilterValues } from './selectFilterValues';
export const DOES_THREAD_MATCH_FILTERS_TIMEOUT = 2000;
export const doesThreadMatchFilters = ({
  inboxId,
  threadId
}) => (dispatch, getState) => {
  const filterValues = selectFilterValues(getState());
  if (threadId && inboxId) {
    setTimeout(() => {
      dispatch(checkThreadMembership({
        threadId: +threadId,
        filterValues,
        inboxId
      }));
    }, DOES_THREAD_MATCH_FILTERS_TIMEOUT);
  }
};