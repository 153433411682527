import { useCallback, useEffect, useRef } from 'react';
import { fetchCalleeDetails } from '../http/fetchCalleeDetails';
import memoizeWithTimeout from '../../utils/memoize';
import { useAsyncData } from '../../async-data/hooks/useAsyncData';
import { FAILED, SUCCEEDED, STARTED } from '../../async-data/constants/AsyncStatuses';
import { CalleeOmnibusResult } from '../../callees/records/CalleeOmnibusResult';
const memoizedFetchCalleeDetails = memoizeWithTimeout((objectId, objectTypeId) => {
  return fetchCalleeDetails({
    objectId,
    objectTypeId
  });
}, 5000);
export const useCalleeDetails = (objectId, objectTypeId) => {
  const {
    data,
    status,
    updateAsyncData
  } = useAsyncData(null);
  const isMounted = useRef(false);
  const updateState = useCallback(updates => {
    updateAsyncData(updates);
  }, [updateAsyncData]);
  const fetchData = useCallback(() => {
    updateState({
      status: STARTED
    });
    if (objectId && objectTypeId) {
      memoizedFetchCalleeDetails(objectId, objectTypeId).then(results => {
        if (isMounted.current) {
          const calleeOmnibusResult = new CalleeOmnibusResult(results);
          updateState({
            status: SUCCEEDED,
            data: calleeOmnibusResult
          });
        }
      }).catch(error => {
        if (isMounted.current) {
          updateState({
            status: FAILED,
            error
          });
        }
      });
    }
  }, [objectId, objectTypeId, updateState]);
  useEffect(() => {
    isMounted.current = true;
    fetchData();
    return () => {
      isMounted.current = false;
    };
  }, [fetchData]);
  return {
    data,
    status
  };
};