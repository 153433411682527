const connectedAccountsBase = 'connectedaccounts/v1';
const facsimileBase = 'facsimile/v1';
export const facsimileInboxes = `${facsimileBase}/inboxes`;
export const discoverImapSettings = emailAddress => `${facsimileInboxes}/generic-settings/discover?email=${emailAddress}`;
export const createImapInbox = `${facsimileInboxes}/generic/custom`;
export const createExchangeInbox = `${facsimileInboxes}/exchange/create`;
export const exchangeAutodiscovery = `${facsimileInboxes}/exchange/autodiscover`;
export const providerByDomain = domain => `${facsimileBase}/provider/domain/${domain}`;
export const provider = () => `${facsimileBase}/provider`;
export const providerByEmailAddress = emailAddress => `${facsimileBase}/provider/email/${emailAddress}`;
export const enableCrmConnectedAccount = emailAddress => `${connectedAccountsBase}/feature/CRM_EMAIL_INTEGRATION/account/${emailAddress}?state=ENABLED`;
export const removeAllConnectionsForEmail = emailAddress => `${connectedAccountsBase}/emails/${encodeURIComponent(emailAddress)}/all`;