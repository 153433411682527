import { createUpdateCalleeMessage } from 'calling-global-api/lib/internalEventMessageCreators';
import { postInternalMessage } from '../initialize';
import { triggerHostMessageUpdateCalleeNumber } from '../triggerHostMessage/triggerHostMessageUpdateCalleeNumber';
export default function updateCalleeNumber({
  objectId,
  objectTypeId,
  rawValue,
  property
}) {
  const message = createUpdateCalleeMessage({
    objectId,
    objectTypeId,
    rawValue,
    property
  });

  // Sends to iframe
  postInternalMessage(message);

  // Sends to host
  triggerHostMessageUpdateCalleeNumber({
    objectId,
    objectTypeId,
    rawValue,
    property
  });
}