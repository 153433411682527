import { RECURRING_BILLING_FREQUENCY, TERM } from 'customer-data-objects/lineItem/PropertyNames';
import { BILLING_FREQUENCY_NAMES } from 'customer-data-objects/product/BillingFrequency';
import { getMonthsPerBillingFrequency, getWeeksPerBillingFrequency, isWeeklyOrBiweeklyBillingFrequency } from 'customer-data-properties/v2/utils/period';
import { getTermHasLength } from 'products-ui-components/utils/calculations/terms';
import { getProperty } from './objectAccessors';
import { getLineItem, getLineItems, isLineItem } from './subject';
export function isRecurring(lineItem) {
  const recurringBillingFrequency = getProperty(isLineItem(lineItem) ? getLineItem(lineItem) : lineItem, RECURRING_BILLING_FREQUENCY);
  return isBillingFrequencyRecurring(recurringBillingFrequency);
}
export function isBillingFrequencyRecurring(billingFrequency) {
  return BILLING_FREQUENCY_NAMES.includes(billingFrequency);
}
export function hasAtLeastOneFixed(lineItems) {
  return getLineItems(lineItems).some(lineItem => !isRecurring(lineItem));
}
export function hasAtLeastOneRecurring(lineItems) {
  return getLineItems(lineItems).some(lineItem => isRecurring(lineItem));
}
export function hasAtLeastOneRecurringWithoutTerm(lineItems) {
  return getLineItems(lineItems).some(lineItem => {
    const term = getProperty(lineItem, TERM);
    return isRecurring(lineItem) && !getTermHasLength(term);
  });
}
export function getRecurringLineItemsWithTerm(lineItems) {
  return [...lineItems.entries()].filter(([, lineItem]) => {
    const term = getProperty(lineItem, TERM);
    return isRecurring(lineItem) && getTermHasLength(term);
  }).reduce((acc, [key, lineItem]) => acc.set(key, lineItem), new Map());
}
export function sortByBillingFrequency(billingFrequencyA, billingFrequencyB) {
  // If both are weekly/biweekly, sort by weeks per rate period
  if (isWeeklyOrBiweeklyBillingFrequency(billingFrequencyA) && isWeeklyOrBiweeklyBillingFrequency(billingFrequencyB)) {
    return getWeeksPerBillingFrequency(billingFrequencyA) - getWeeksPerBillingFrequency(billingFrequencyB);
    // If one is weekly/biweekly and the other isn't, it should come first
  } else if (isWeeklyOrBiweeklyBillingFrequency(billingFrequencyA)) {
    return -1;
  } else if (isWeeklyOrBiweeklyBillingFrequency(billingFrequencyB)) {
    return 1;
    // Otherwise, sort by months per rate period
  } else {
    return getMonthsPerBillingFrequency(billingFrequencyA) - getMonthsPerBillingFrequency(billingFrequencyB);
  }
}
export function getUniqueBillingFrequencyArray(lineItems) {
  const uniqueBillingFrequencies = new Set();
  lineItems === null || lineItems === void 0 || lineItems.forEach(lineItem => {
    const frequency = getProperty(lineItem, 'recurringbillingfrequency');
    if (frequency) {
      uniqueBillingFrequencies.add(frequency);
    }
  });
  return Array.from(uniqueBillingFrequencies);
}