module.exports = {
  "copilotPreviewPageView": {
    "name": "copilot-preview-view",
    "class": "view",
    "properties": {
      "action": {
        "type": [
          "page_view"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-addon-copilot-preview",
    "version": "1"
  },
  "copilotPreviewPrimaryAction": {
    "name": "copilot-preview-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "primary_action_click",
        "primary_action_auto_generated"
      ],
      "command": {
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-addon-copilot-preview",
    "version": "1"
  },
  "copilotPreviewClosePreview": {
    "name": "copilot-preview-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "close_preview"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-addon-copilot-preview",
    "version": "1"
  },
  "copilotPreviewChatWithChatSpot": {
    "name": "copilot-preview-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "ask_a_question"
      ],
      "followUpMessage": {
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-addon-copilot-preview",
    "version": "1"
  },
  "copilotPreviewRefreshSummary": {
    "name": "copilot-preview-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "refresh_summary"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-addon-copilot-preview",
    "version": "1"
  },
  "aiButtonInteraction": {
    "name": "aiButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "featureId": {
        "type": "string",
        "isOptional": true
      },
      "featureCategoryId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copilotActionButtonInteraction": {
    "name": "copilotActionButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "prompt": {
        "type": "string",
        "isOptional": true
      },
      "hasPrefillValues": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copyMessageInteraction": {
    "name": "copyMessageInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_copied"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "feedbackInteraction": {
    "name": "inlineFeedbackInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_upvoted",
          "prompt_upvote_removed",
          "prompt_downvoted",
          "prompt_downvote_removed"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copilotActionButtonPageView": {
    "name": "aiComponentsView",
    "class": "view",
    "properties": {
      "action": [
        "page_view"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "callTabPageLoad": {
    "name": "call tab page load",
    "class": "view",
    "namespace": "inbound-calling-ui",
    "version": "1"
  },
  "inboundCallNotificationShown": {
    "name": "inbound call notification shown",
    "class": "view",
    "properties": {
      "callId": {
        "type": "string",
        "isOptional": true
      },
      "isChannelConnectedRepNumber": {
        "type": "boolean",
        "isOptional": true
      },
      "connectedChannelType": {
        "type": "string",
        "isOptional": true
      },
      "numberOfUsersRung": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "inbound-calling-ui",
    "version": "1"
  },
  "callTabAlertView": {
    "name": "call tab alert view",
    "class": "view",
    "properties": {
      "alertType": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "inbound-calling-ui",
    "version": "1"
  },
  "callTabBannerView": {
    "name": "call tab banner view",
    "class": "view",
    "properties": {
      "bannerType": {
        "type": "string"
      },
      "errorCode": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "inbound-calling-ui",
    "version": "1"
  },
  "callTabInteraction": {
    "name": "call tab interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "answer call",
        "reject call",
        "hang up call",
        "mute/unmute button click",
        "open keypad",
        "audio devices icon click",
        "open test audio devices",
        "start call from dropdown",
        "click reconnect button",
        "initiate transfer request button click",
        "confirm transfer handoff button click",
        "cancel transfer request button click",
        "answer transfer request button click",
        "reject transfer request button click",
        "hold button click",
        "click open new tab button",
        "record/unrecord button click"
      ],
      "appIdentifier": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "inbound-calling-ui",
    "version": "1"
  },
  "callingInteraction": {
    "name": "calling interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "provider_name": {
        "type": "string",
        "isOptional": true
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "calling_SelectCallOutCome": {
    "name": "calling interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "select call outcome"
      ],
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "provider_name": {
        "type": "string",
        "isOptional": true
      },
      "outcomeType": {
        "type": "string"
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "calling_SelectCallType": {
    "name": "calling interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "select call type"
      ],
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "provider_name": {
        "type": "string",
        "isOptional": true
      },
      "callType": {
        "type": "string"
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "calling_RateCall": {
    "name": "calling interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "rate call quality"
      ],
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "provider_name": {
        "type": "string",
        "isOptional": true
      },
      "callRating": {
        "type": "number"
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "calling_CallFromHubSpotAppResponse": {
    "name": "call from hubspot app response",
    "class": "view",
    "properties": {
      "outcomeType": {
        "type": "string"
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "Calling_DropDownInteractions": {
    "name": "call drop down interactions",
    "class": "interaction",
    "properties": {
      "action": [
        "start call from dropdown",
        "call the first number",
        "call secondary numbers",
        "add \"to\" number property",
        "change \"from\" number",
        "change calling device",
        "open calling widget"
      ],
      "source": {
        "type": "string",
        "isOptional": true
      },
      "selectFromNumberType": {
        "type": "string",
        "isOptional": true
      },
      "previousFromNumberType": {
        "type": "string",
        "isOptional": true
      },
      "selectToNumberType": {
        "type": "string",
        "isOptional": true
      },
      "previousToNumberType": {
        "type": "string",
        "isOptional": true
      },
      "selectToNumberPosition": {
        "type": "number",
        "isOptional": true
      },
      "selectDeviceType": {
        "type": "string",
        "isOptional": true
      },
      "previousDeviceType": {
        "type": "string",
        "isOptional": true
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "calling_SaveCall": {
    "name": "calling interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "save call"
      ],
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "provider_name": {
        "type": "string",
        "isOptional": true
      },
      "outcomeType": {
        "type": "string",
        "isOptional": true
      },
      "callType": {
        "type": "string",
        "isOptional": true
      },
      "noteCharacterCount": {
        "type": "string",
        "isOptional": true
      },
      "callRecording": [
        "yes",
        "no"
      ],
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "calling_droppedCallModalShown": {
    "name": "dropped call modal shown",
    "class": "view",
    "properties": {
      "trigger": {
        "type": "string",
        "isOptional": true
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "calling_droppedCallModalInteraction": {
    "name": "dropped call modal interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "report dropped call",
        "do not report dropped call"
      ],
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "calling_ProviderSwitch": {
    "name": "calling interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "provider switch"
      ],
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "provider_name": {
        "type": "string",
        "isOptional": true
      },
      "switchFrom": {
        "type": "string"
      },
      "switchTo": {
        "type": "string"
      },
      "switchSource": {
        "type": "string",
        "isOptional": true
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "calling_FromNumberSwitch": {
    "name": "calling interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "Change \"from\" number"
      ],
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "provider_name": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberType": {
        "type": "string"
      },
      "previousPhoneNumberType": {
        "type": "string"
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "calling_NetworkQuality": {
    "name": "calling interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "Network Quality"
      ],
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "provider_name": {
        "type": "string",
        "isOptional": true
      },
      "callSid": {
        "type": "string"
      },
      "lowMosAlertTrackingDuration": {
        "type": "number"
      },
      "lowMosAlertDuration": {
        "type": "number"
      },
      "totalLowMosAlertCount": {
        "type": "number"
      },
      "lowMosSampleValues": {
        "type": "array"
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "callSummary": {
    "name": "call summary interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "communicator_bannerInteraction": {
    "name": "banner interaction",
    "class": "interaction",
    "properties": {
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "bannerType": {
        "type": "string",
        "isOptional": true
      },
      "cta": {
        "type": "string",
        "isOptional": true
      },
      "errorCode": {
        "type": "string",
        "isOptional": true
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "communicator_callActivation": {
    "name": "call activation",
    "class": "activation",
    "namespace": "calling",
    "properties": {
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "communicator_bannerView": {
    "name": "view banner",
    "class": "interaction",
    "properties": {
      "bannerType": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "bannerName": {
        "type": "string",
        "isOptional": true
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "communicator_firstCallActivation": {
    "name": "first call activation",
    "class": "activation",
    "properties": {
      "screen": [
        "first activation"
      ],
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "callUsage_startCall": {
    "name": "start call",
    "class": "usage",
    "properties": {
      "action": [
        "start call"
      ],
      "callMedium": {
        "type": "string"
      },
      "provider": {
        "type": "string"
      },
      "isChannelConnectedFromNumber": {
        "type": "boolean",
        "isOptional": true
      },
      "objectId": {
        "type": "string",
        "isOptional": true
      },
      "objectType": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberType": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "ticketId": {
        "type": "number",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "callUsage_createFollowUpTask": {
    "name": "create followup task",
    "class": "usage",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "provider_name": {
        "type": "string",
        "isOptional": true
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "callUsage_createDefaultFollowUpTask": {
    "name": "create default followup task",
    "class": "usage",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "provider_name": {
        "type": "string",
        "isOptional": true
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "callUsage_createEditedFollowUpTask": {
    "name": "create edited followup task",
    "class": "usage",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "provider_name": {
        "type": "string",
        "isOptional": true
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "calling_numberInference": {
    "name": "phone number inference",
    "class": "interaction",
    "properties": {
      "wasRegionInferred": {
        "type": "boolean"
      },
      "wasParseResultEquivalentWithNoInference": {
        "type": "boolean",
        "isOptional": true
      },
      "regionCode": {
        "type": "string"
      },
      "channel": {
        "type": "string"
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "callingExtensions_initialLoadTime": {
    "name": "calling extensions initial load time",
    "class": "interaction",
    "properties": {
      "action": "string",
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "provider_name": {
        "type": "string",
        "isOptional": true
      },
      "application_id": {
        "type": "number",
        "isOptional": true
      },
      "version": [
        "1",
        "2"
      ],
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "callingExtensions_startCallTime": {
    "name": "calling extensions time to start call from dial number",
    "class": "interaction",
    "properties": {
      "action": "string",
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "provider_name": {
        "type": "string",
        "isOptional": true
      },
      "application_id": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "communicator_timelineInteraction": {
    "name": "timeline interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "provider_name": {
        "type": "string",
        "isOptional": true
      },
      "callType": {
        "type": "string",
        "isOptional": true
      },
      "outcomeType": {
        "type": "string",
        "isOptional": true
      },
      "hasVoicemail": {
        "type": "boolean",
        "isOptional": true
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "calling_callUpgradeClicked": {
    "name": "call upgrade clicked",
    "class": "interaction",
    "properties": {
      "source": {
        "type": "string"
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  }
};