import styled from 'styled-components';
export const ShareToolTabLeftSection = styled.div.withConfig({
  displayName: "ShareToolSections__ShareToolTabLeftSection",
  componentId: "sc-1t05nhh-0"
})(["width:50%;padding-right:48px;"]);
export const ShareToolTabRightSection = styled.div.withConfig({
  displayName: "ShareToolSections__ShareToolTabRightSection",
  componentId: "sc-1t05nhh-1"
})(["width:50%;"]);
export const ShareToolTabWholeSection = styled.div.withConfig({
  displayName: "ShareToolSections__ShareToolTabWholeSection",
  componentId: "sc-1t05nhh-2"
})(["width:auto;"]);