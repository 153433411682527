import Loadable from 'UIComponents/decorators/Loadable';
import EmptyComponent from './EmptyComponent';
const AsyncFileManager = Loadable({
  loader: () => import( /* webpackChunkName: "file-manager" */'../dlbs/FileManagerEntry').catch(
  // resolving because this ends up getting caught by the ErrorBoundary
  // which makes for a better user experience. however, the error
  // that propagates is one related to React trying to render
  // the resolved result, i.e., the Error instance.
  () => {
    throw new Error('failed to load the FileManagerEntry');
  }),
  LoadingComponent: EmptyComponent
});
export default AsyncFileManager;