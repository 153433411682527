import { getData } from 'conversations-async-data/async-data/operators/getters';
import { isStarted } from 'conversations-async-data/async-data/operators/statusComparators';
import { usePubSubHealthCheck } from 'conversations-internal-pub-sub/redux/hooks/usePubSubHealthCheck';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useThreadDetails } from '../../../thread-details-feature/public/hooks/useThreadDetails';
import { getAgentToAgentChannelName, getAgentToVisitorChannelName, getIsAssignedToBot, getIsClosed, getIsUnassigned, isOpening } from 'conversations-thread-data/thread-details/public/operators';
// @ts-expect-error module not typed
import { publishCommentMessage } from '../../_internal/actions/publishCommentMessage';
import { publishMessage } from '../../_internal/actions/publishMessage';
// @ts-expect-error module not typed
import { republishMessage } from '../../_internal/actions/republishMessage';
export const useThreadViewPublishing = ({
  threadId
}) => {
  const dispatch = useDispatch();
  const {
    assignToCurrentAgent,
    asyncAssignmentStatus,
    asyncThreadDetails,
    openThread
  } = useThreadDetails({
    threadId
  });
  const handlePublishComment = useCallback(message => {
    const threadDetails = getData(asyncThreadDetails);
    const channel = getAgentToAgentChannelName(threadDetails);
    dispatch(publishCommentMessage({
      message,
      threadId,
      channel
    }));
  }, [asyncThreadDetails, threadId, dispatch]);
  const {
    canPublish
  } = usePubSubHealthCheck();
  const handlePublishMessage = useCallback((commonMessage, publishOptions) => {
    if (canPublish) {
      const threadDetails = getData(asyncThreadDetails);
      const opening = isOpening(asyncThreadDetails);
      const isClosed = getIsClosed(threadDetails) || false;
      if (!opening && isClosed) {
        openThread();
      }
      const unassigned = getIsUnassigned(threadDetails);
      const assignedToBot = getIsAssignedToBot(threadDetails);
      const assigning = isStarted(asyncAssignmentStatus);
      if ((unassigned || assignedToBot) && !assigning) assignToCurrentAgent();
      dispatch(publishMessage(commonMessage, publishOptions));
    }
  }, [assignToCurrentAgent, asyncAssignmentStatus, asyncThreadDetails, canPublish, dispatch, openThread]);
  const handleRepublishMessage = useCallback(message => {
    const threadDetails = getData(asyncThreadDetails);
    const channel = getAgentToVisitorChannelName(threadDetails);
    dispatch(republishMessage({
      message,
      threadId,
      channel
    }));
  }, [asyncThreadDetails, threadId, dispatch]);
  return {
    publishComment: handlePublishComment,
    publishMessage: handlePublishMessage,
    republishMessage: handleRepublishMessage
  };
};