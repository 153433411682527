import { buildEngagementDetailsMessageFromAsync } from 'conversations-message-history/engagement-details-message/operators/buildEngagementDetailsMessageFromAsync';
import { isRawEngagementAsyncMessage, isRawEngagmentCreationMessage, isRawEngagmentUpdateMessage } from 'conversations-message-history/engagement-details-message/operators/engagmentAsyncMessageIdentifiers';
//@ts-expect-error File isn't typed
import { historyMessageReceived } from '../actions/historyMessageReceived';
import { replaceHistoryMessage } from '../actions/replaceHistoryMessage';
import { removeThreadMessageFromHistory } from '../actions/removeThreadMessageFromHistory';
const hasData = messageObject => {
  return Boolean(messageObject) && messageObject.data !== undefined;
};
const ENGAGMENT_COMMENT_PROPERTY = '@Comment';
const isEngagementCommentAblyMessage = data => data.propertiesChanged.includes(ENGAGMENT_COMMENT_PROPERTY);
export const handleTicketMessage = ({
  message,
  threadId
}) => dispatch => {
  if (!hasData(message) || !threadId) {
    return;
  }
  const {
    data
  } = message;
  if (isRawEngagementAsyncMessage(data)) {
    if (isRawEngagmentCreationMessage(data)) {
      const messageRecord = buildEngagementDetailsMessageFromAsync(data);
      dispatch(historyMessageReceived({
        threadId,
        message: messageRecord
      }));
      return;
    }
    if (isRawEngagmentUpdateMessage(data)) {
      if (isEngagementCommentAblyMessage(data)) {
        return; // This is handled by HD realtime.
      }
      if (data.propertiesChanged.length === 0) {
        dispatch(removeThreadMessageFromHistory({
          messageId: data.objectId,
          threadId
        }));
        return;
      }
      const messageRecord = buildEngagementDetailsMessageFromAsync(data);
      dispatch(replaceHistoryMessage({
        threadId,
        message: messageRecord
      }));
    }
  }
};