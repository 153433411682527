import { createOpenWidgetMessage, createSelectToNumberMessage, createSelectedCallProviderMessage, createShowSettingsStateMessage } from 'calling-global-api/lib/internalEventMessageCreators';
import { toJS } from '../utils/toJS';
import { triggerHostMessageOpenWidget } from '../triggerHostMessage/triggerHostMessageOpenWidget';
import { postInternalMessage } from '../initialize';
export default function openWidget(options) {
  const {
    toSubject,
    startCall = false,
    addPhoneNumber = false,
    registerFromNumber = false,
    learnMore = false,
    callingProvider
  } = options || {};
  const openWidgetMessage = createOpenWidgetMessage();
  postInternalMessage(openWidgetMessage);

  // CallProviderViewport, CallingWidget, etc are all listening for this
  triggerHostMessageOpenWidget(options);
  const objectTypeId = toSubject && toSubject.objectTypeId;
  const objectId = toSubject && toSubject.objectId;
  if (objectTypeId && objectId && toSubject.numberPropertyName) {
    if (!startCall) {
      const toNumberMessage = createSelectToNumberMessage(toSubject);
      postInternalMessage(toNumberMessage);
    }
  } else if (options && callingProvider) {
    const callingProviderObject = toJS(callingProvider);
    const callProviderMessage = createSelectedCallProviderMessage(callingProviderObject);
    postInternalMessage(callProviderMessage);
  } else if (registerFromNumber || learnMore || addPhoneNumber) {
    const showSettingsMessage = createShowSettingsStateMessage({
      registerFromNumber,
      learnMore,
      addPhoneNumber,
      toSubject
    });
    postInternalMessage(showSettingsMessage);
  }
}