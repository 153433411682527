/* hs-eslint ignored failing-rules */
/* eslint-disable no-duplicate-imports */

import { getData } from 'conversations-async-data/async-data/operators/getters';
// @ts-expect-error getters is not typed
import { getMessages } from 'conversations-message-history/thread-history/operators/getters';
// @ts-expect-error cmfComparators is not typed
import { isCommonMessageFormat } from 'conversations-message-history/common-message-format/operators/cmfComparators';
// @ts-expect-error commonMessageFormatGetters is not typed
import { getId } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { getGenericChannelIdForMessage } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { isMessagePublished } from 'conversations-message-history/common-message-format/operators/isMessagePublished';
import { FEEDBACK_GENERIC_CHANNEL_ID } from 'conversations-thread-data/generic-channels/public/constants';
import { getUsedMessageId } from 'conversations-thread-data/thread-details/public/operators';
import { useThreadDetails } from '../../thread-details-feature/public/hooks/useThreadDetails';
import { getGenericChannelsUsed } from 'conversations-thread-data/thread-details/public/operators';
// @ts-expect-error useThreadHistory is not typed
import { useThreadHistory } from '../../thread-histories/hooks/useThreadHistory';
import { getLatestChannelUsedForChannelIds } from '../../thread-histories/operators/getLatestChannelUsedForChannelIds';
import { getUsedGenericChannelId } from 'conversations-thread-data/thread-details/public/operators';
import { useAsyncGenericChannelsWithCapabilities } from 'composer-data/channel-capabilities/public/hooks';
export function useCanReplyToChannels({
  genericChannelId
}) {
  const {
    data
  } = useAsyncGenericChannelsWithCapabilities();
  const currentCapabilties = genericChannelId && data && data[genericChannelId] && data[genericChannelId].capabilities;
  const allowConversationStart = currentCapabilties && currentCapabilties.allowConversationStart;
  const channelsWithoutOutgoingMessage = allowConversationStart && data && Object.keys(data).map(Number).filter(channelId => {
    const {
      capabilities
    } = data[channelId];
    return capabilities && !capabilities.allowOutgoingMessages;
  });
  return channelsWithoutOutgoingMessage || [];
}

/**
 * Find the newest message whose Channel ID exactly matches a preferredChannelId.
 * If no such message exists, instead return the newest message whose Channel ID
 * is any of the otherAllowedChannelIds. Return null in all other cases.
 *
 * @param listOfMessages
 * @param preferredChannelId
 * @param otherAllowedChannelIds
 * @returns
 */
const getNewestMessageWithChannelId = (listOfMessages, preferredChannelId, otherAllowedChannelIds) => {
  let messageMatchingAnyAllowedChannel = null;
  for (let i = listOfMessages.size - 1; i >= 0; i--) {
    const message = listOfMessages.get(i);
    const channelIdOfThisMessage = getGenericChannelIdForMessage(message);
    const messageIsNotPublished = !isMessagePublished(message);
    const messageIsNotCommonFormat = !isCommonMessageFormat(message);
    const noLooseMatchYet = !messageMatchingAnyAllowedChannel;
    if (messageIsNotPublished || messageIsNotCommonFormat) {
      continue;
    }
    if (channelIdOfThisMessage === preferredChannelId) {
      return message;
    }
    if (noLooseMatchYet && otherAllowedChannelIds.includes(channelIdOfThisMessage)) {
      messageMatchingAnyAllowedChannel = message;
    }
  }
  return messageMatchingAnyAllowedChannel;
};

/**
 * @description Returns the id for the last message in ThreadHistory
 * of the specified genericChannelId. If no message of that generic channel
 * can be found within ThreadHistory, it will check the genericChannelsUsed
 * field within ThreadDetails.
 */

export const useLatestMessageIdForChannel = ({
  genericChannelId,
  threadId
}) => {
  const {
    asyncThreadHistory
  } = useThreadHistory({
    threadId
  });
  const threadHistory = getData(asyncThreadHistory);
  const {
    asyncThreadDetails
  } = useThreadDetails({
    threadId
  });
  const threadDetails = getData(asyncThreadDetails);
  const canReplyToChannels = useCanReplyToChannels({
    genericChannelId
  });
  const allowedChannelIds = genericChannelId ? canReplyToChannels.concat(genericChannelId) : canReplyToChannels;
  if (!genericChannelId) return null;
  const mapOfMessages = threadHistory && getMessages(threadHistory);
  let latestMessage = null;
  if (mapOfMessages) {
    latestMessage = getNewestMessageWithChannelId(mapOfMessages.toList(), genericChannelId, allowedChannelIds);
  }
  const didNotFindMessage = !latestMessage;
  if (didNotFindMessage && threadDetails) {
    let genericChannelsUsed = getGenericChannelsUsed(threadDetails);
    /*  @ts-expect-error Jun-5-2024, 19:34UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
    genericChannelsUsed = genericChannelsUsed.filter(channelUsed => getUsedGenericChannelId(channelUsed) !== FEEDBACK_GENERIC_CHANNEL_ID);
    const lastUsed = getLatestChannelUsedForChannelIds({
      /*  @ts-expect-error Jun-5-2024, 19:34UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
      genericChannelsUsed,
      channelIds: allowedChannelIds
    });
    return lastUsed && getUsedMessageId(lastUsed) || null;
  }
  return latestMessage && getId(latestMessage) || null;
};