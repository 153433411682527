// adapted from: https://git.hubteam.com/HubSpot/conversations-internal-schema/blob/24c061ac8fe4f3aee9b0a5f69a2d760a19563fd5/static/js/routing-rules/operators/isValid.js
import { List as ImmutableList } from 'immutable';
import count from 'transmute/count';
import { AI_CHATBOT, OWNER, USERS_AND_TEAMS } from '../constants/RoutingTypes';
import { getFallback, getTeamIds, getType, getUserIds } from './routingRulesGetters';
const checkIsList = value => ImmutableList.isList(value) || Array.isArray(value);
const checkIds = ids => checkIsList(ids) && count(ids) > 0;
const validUsersAndTeamsRoutingRules = routingRules => checkIds(getUserIds(routingRules)) || checkIds(getTeamIds(routingRules));
export const isValid = routingRules => {
  if (!routingRules) {
    return false;
  }
  const type = getType(routingRules);
  switch (type) {
    case USERS_AND_TEAMS:
      // if the type is USERS_AND_TEAMS, we know the type is UsersAndTeamsRoutingRules
      return validUsersAndTeamsRoutingRules(routingRules);
    case OWNER:
    case AI_CHATBOT:
      return isValid(getFallback(routingRules));
    default:
      return true;
  }
};