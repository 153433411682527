import { createSelector } from 'reselect';
import { getFromAddress, getEmailAuthenticationStatus as getAutheticationStatus } from 'conversations-message-history/common-message-format/operators/emailMetadataGetters';
import { getLatestVisitorMessage } from 'conversations-message-history/thread-history/operators/getLatestVisitorMessage';
import { EMAIL_GENERIC_CHANNEL_ID } from 'conversations-thread-data/generic-channels/public/constants';
// @ts-expect-error file not typed
import { getCurrentThreadHistory } from 'conversations-thread-data/thread-histories/public/selectors/getCurrentThreadHistory';
// @ts-expect-error module not typed
import { getFocusedThreadDetails } from '../../thread-details/selectors/getFocusedThreadDetails';
import { getOriginalGenericChannelId } from 'conversations-thread-data/thread-details/public/operators';
export const getEmailAndAuthenticationStatus = createSelector([getFocusedThreadDetails, getCurrentThreadHistory], (threadDetails, threadHistory) => {
  let email;
  let authenticationStatus;
  if (threadDetails && getOriginalGenericChannelId(threadDetails) === EMAIL_GENERIC_CHANNEL_ID && threadHistory) {
    const latestEmail = getLatestVisitorMessage(threadHistory, EMAIL_GENERIC_CHANNEL_ID);
    email = latestEmail && getFromAddress(latestEmail);
    authenticationStatus = latestEmail && getAutheticationStatus(latestEmail);
  }
  return {
    email: email || null,
    authenticationStatus: authenticationStatus || null
  };
});