import { createSlice } from '@reduxjs/toolkit';
import { getSearch, setSearch } from '../../search-and-filter-schema/protected';
import { DEFAULT_CHAT_FILTERS } from './defaultChatFilters';
import { clearFilterValues, clearSearchValue, clearSearchAndFilterValues, setFilterValue, setFilterValues } from './filterValuesActions';
import updateChatFiltersByFilterKey from './updateChatFiltersByFilterKey';
const initialState = {
  data: DEFAULT_CHAT_FILTERS
};
export const filterValuesSlice = createSlice({
  name: 'filterValues',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(clearFilterValues, state => {
      const searchQuery = getSearch(state.data);
      state.data = setSearch(searchQuery, initialState.data);
    }).addCase(clearSearchValue, state => {
      const defaultSearchText = getSearch(initialState.data);
      state.data = setSearch(defaultSearchText, state.data);
    }).addCase(clearSearchAndFilterValues, () => {
      return initialState;
    }).addCase(setFilterValue, (state, {
      payload: {
        filterKey,
        filterValue
      }
    }) => {
      state.data = updateChatFiltersByFilterKey(filterKey, filterValue, state.data);
    }).addCase(setFilterValues, (state, {
      payload
    }) => {
      state.data = state.data.merge(payload);
    });
  }
});
export const {
  getInitialState
} = filterValuesSlice;
export default filterValuesSlice.reducer;