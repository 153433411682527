import I18n from 'I18n';
import { DAYS } from '../constants';
import { linkToUsersAndTeamSettings } from '../../urls/linkToUsersAndTeamSettings';
export const formatTime = minutes => {
  /*
   * Converting the time to utc to ignore timezone, we are only interested in the chosen hour here
   * more about moment.utc() -> https://momentjs.com/docs/#/parsing/utc/
   */
  return I18n.moment(I18n.moment.duration(minutes, 'minutes').asMilliseconds()).utc().format('LT');
};
const segmentIsToday = ({
  days
}, now) => {
  const dayOfWeekIdx = now.day();
  // Our DAYS array starts with Mon-Fri, Sat-Sun, Everyday so Sunday is on position 3
  const dayOfWeek = DAYS[dayOfWeekIdx + 3];
  // MONDAY_TO_FRIDAY
  const matchesMtoF = dayOfWeekIdx > 0 && dayOfWeekIdx < 6 && days === 'MONDAY_TO_FRIDAY';
  // SATURDAY_SUNDAY, individual day, or EVERY_DAY
  const matchesDay = days.includes(dayOfWeek) || days === 'EVERY_DAY';
  return matchesMtoF || matchesDay;
};
export const getTodaysWorkingHours = (workingHours, timezone) => {
  if (!workingHours || !timezone) {
    return;
  }
  const now = I18n.moment().tz(timezone);
  const todayHours = workingHours.filter(segment => segmentIsToday(segment, now));
  return todayHours.length > 0 ? todayHours : undefined;
};
export const getCurrentWorkingHour = (workingHours, timezone) => {
  const now = I18n.moment.tz(timezone);
  // moment.minutes() returns the minutes of the hour, but the segment gives minutes from the start of the day
  const minutesIntoToday = I18n.moment(now.minutes() + now.hours() * 60);
  return workingHours.find(segment => {
    if (segmentIsToday(segment, now)) {
      return minutesIntoToday.isBetween(segment.startMinute, segment.endMinute, undefined, '[)' // inclusive of start, not end
      );
    }
    return false;
  });
};
const getTZLabelToDisplay = (timezone, timezoneList) => {
  const tzRegion = timezone.split('/')[0];
  const tzOptionsArr = timezoneList.find(tzObj => tzObj.value === tzRegion);
  if (tzOptionsArr) {
    const tzOption = tzOptionsArr.options.find(el => el.value === timezone);
    return tzOption && tzOption.text;
  }
  return undefined;
};
export const formatTodayWorkingHours = (workingHours, timezone, timezoneList, options = {}) => {
  const todayWorkingHours = getTodaysWorkingHours(workingHours, timezone);
  if (!todayWorkingHours || todayWorkingHours.length === 0 || !timezone) {
    return {
      formattedWorkingHours: '',
      formattedWorkingHoursList: [],
      formattedTimezone: ''
    };
  }
  const now = I18n.moment().tz(timezone);
  const minutesIntoToday = now.minutes() + now.hours() * 60;
  const formattedWorkingHoursList = todayWorkingHours.filter(segment => options.shouldIncludeExpired || segment.endMinute > minutesIntoToday).sort((a, b) => a.startMinute - b.startMinute).map(segment => {
    const startTime = formatTime(segment.startMinute);
    const endTime = formatTime(segment.endMinute);
    return `${startTime} - ${endTime}`;
  });
  const formattedWorkingHours = I18n.formatList(formattedWorkingHoursList);
  return {
    formattedWorkingHours,
    formattedWorkingHoursList,
    formattedTimezone: timezoneList && getTZLabelToDisplay(timezone, timezoneList) || ''
  };
};
export const getUserWorkingHoursURL = userId => `${linkToUsersAndTeamSettings()}user/${userId}?tab=preferencesTab&subtab=workingHours`;