import http from 'hub-http/clients/apiClient';
import userInfo from 'hub-http/userInfo';
const BASE_URI = 'users/v1/app/attributes';
const BULK_GET_URI = `${BASE_URI}/bulk-get`;
export function fetchUserSetting(keys) {
  return userInfo().then(({
    user
  }) => {
    const formattedKeys = Array.isArray(keys) ? keys : [keys];
    return http.put(BULK_GET_URI, {
      data: {
        keys: formattedKeys,
        user_id: user.user_id
      }
    }).then(settings => {
      return settings.attributes.reduce((acc, setting) => {
        if (!(setting && setting.value)) {
          return acc;
        }
        const parsedSetting = JSON.parse(setting.value);
        /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
        return Object.assign({}, acc, parsedSetting);
      }, {});
    });
  });
}
export function saveUserSetting(settingName, key, value) {
  return userInfo().then(({
    user
  }) => {
    const uriKey = encodeURIComponent(settingName);
    const uriUserId = encodeURIComponent(user.user_id);
    return http.post(`${BASE_URI}?key=${uriKey}&user_id=${uriUserId}`, {
      data: {
        key: uriKey,
        value: JSON.stringify({
          [key]: value
        })
      }
    });
  });
}