import { useEffect, useState } from 'react';
export const useSelectedFromValue = (connectedEmailsLoading, connectedEmails, defaultFromEmail) => {
  const [selectedFromValue, setSelectedFromValue] = useState(null);
  useEffect(() => {
    if (!selectedFromValue && !connectedEmailsLoading && connectedEmails.length) {
      const defaultEmailFromValue = connectedEmails.find(({
        email
      }) => email === defaultFromEmail);
      const initialSelectedFromValue = defaultEmailFromValue || connectedEmails[0];
      setSelectedFromValue(initialSelectedFromValue);
    }
  }, [connectedEmailsLoading, connectedEmails, selectedFromValue, defaultFromEmail]);
  return [selectedFromValue, setSelectedFromValue];
};