import http from 'conversations-http/clients/http';
import { TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
const DEFAULT_LIMIT = 100;
export const fetchSearchViewMembersService = ({
  crmOffset,
  filterGroups,
  inboxId,
  limit = DEFAULT_LIMIT,
  offsetTimestamp,
  offsetId,
  primaryOffsetValue,
  secondaryOffsetValue,
  searchQuery,
  sortDirection = 'DESC',
  sortProperty
}) => {
  return http.post('/conversations-custom-views-member/v1/discover/helpdesk', {
    data: {
      crmOffset,
      filterGroups,
      inboxId,
      limit,
      objectTypeId: TICKET_TYPE_ID,
      offsetTimestamp,
      offsetId,
      primaryOffsetValue,
      secondaryOffsetValue,
      maxPreviewLength: 200,
      searchQuery,
      sortDirection,
      userRequestedSortProperty: sortProperty,
      includeHiddenFromVisitor: true
    },
    timeout: 30000
  });
};