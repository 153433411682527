// @ts-expect-error untyped
import { enableAccount } from '../api/EmailIntegrationApi';
// @ts-expect-error untyped
import { updateAutomation, fetchAutomation } from '../api/AutomationApi';
// @ts-expect-error untyped
import { createTestEmail, queryTestEmail } from '../api/TestMessageAPI';
import { discover, createImapInbox, createExchangeInbox
// @ts-expect-error untyped
} from '../api/GenericInboxApi';
import * as ServiceUrls from './ServiceUrls';
// @ts-expect-error untyped
import { getInboxByEmail } from '../selectors/inbox';
import userInfo from 'hub-http/userInfo';
const REQUIRED_CONFIG_PARAMS = ['apiClient', 'appName', 'portalId'];
export default class DefaultService {
  constructor(config) {
    REQUIRED_CONFIG_PARAMS.forEach(key => {
      if (!config[key]) {
        throw new Error(`${key} required in DefaultService config`);
      }
    });
    const {
      apiClient,
      appName,
      portalId,
      track,
      tracker,
      gates
    } = config;
    if (!track && !tracker) {
      throw new Error('Need at least a hublytics track function or tracker object');
    }
    this.apiClient = apiClient;
    this.appName = appName;
    this.portalId = portalId;
    this.trackFunction = track;
    this.tracker = tracker;
    this.gates = gates;
  }
  getAppName() {
    return this.appName;
  }
  getTracker() {
    return this.tracker;
  }
  getPortalId() {
    return this.portalId;
  }

  // Typically userInfo would be used directly, but some users of InboxConnectUtils,
  // have to manually pass in a preloaded list of gates to InboxConnectWizardCore.
  getGates() {
    if (this.gates) {
      return Promise.resolve(this.gates);
    } else {
      return userInfo().then(({
        portal: {
          enabled_gates
        }
      }) => {
        return enabled_gates;
      });
    }
  }
  enableConnectedAccount(emailAddress) {
    return enableAccount(this.apiClient, emailAddress);
  }
  createTestEmail(emailAddress) {
    return createTestEmail(this.apiClient, emailAddress);
  }
  queryTestEmail(emailId) {
    return queryTestEmail(this.apiClient, emailId);
  }
  getProviderNameByDomain(domain) {
    return this.apiClient.get(ServiceUrls.providerByDomain(domain)).then(provider => provider.get('name'));
  }

  /**
   * These calls specify timeouts because the DNS lookup service may throttle/firewall
   * requests if provider isn't cached, this avoids unreasonable response times for client.
   */
  getProvider() {
    return this.apiClient.get(ServiceUrls.provider(), {
      timeout: 5000
    });
  }
  getProviderByEmailAddress(emailAddress) {
    return this.apiClient.get(ServiceUrls.providerByEmailAddress(emailAddress), {
      timeout: 5000
    });
  }
  getFacsimileInboxByEmail(emailAddress) {
    return this.apiClient.get(ServiceUrls.facsimileInboxes).then(getInboxByEmail(this.portalId, emailAddress));
  }
  discoverInboxSettings(emailAddress) {
    return discover(this.apiClient, emailAddress);
  }
  createImapInbox(data) {
    return createImapInbox(this.apiClient, this.portalId, data);
  }
  createExchangeInbox(data) {
    return createExchangeInbox(this.apiClient, this.portalId, data);
  }
  track(payload) {
    if (this.trackFunction) {
      this.trackFunction(payload);
    }
  }
  removeAllConnectionsForEmail(emailAddress) {
    return this.apiClient.delete(ServiceUrls.removeAllConnectionsForEmail(emailAddress));
  }
  getUserInfo() {
    return userInfo();
  }
  setEnableAutomation(emailAddress, selectedAutomation) {
    return updateAutomation(this.apiClient, this.portalId, emailAddress, selectedAutomation);
  }
  getEnableAutomation(emailAddress) {
    return fetchAutomation(this.apiClient, this.portalId, emailAddress);
  }
}