// TODO: get rid of transmute usage; but need to make sure `Assignee` is never an Immutable type
import { readUnhandledEnumCase } from 'rpc-client-utils';
import get from 'transmute/get';
import { AVAILABLE, AWAY, UNKNOWN } from '../constants/agent-state';
const isHumanAgent = agent => {
  return !!get('userId', agent);
};
const isBotAgent = agent => {
  return !!get('id', agent);
};
export const getAgent = assignee => {
  return get('agent', assignee);
};
export const getAgentId = assignee => {
  const agent = getAgent(assignee);
  if (isHumanAgent(agent)) {
    return get('userId', agent);
  }
  return null;
};
export const getEmail = assignee => {
  const agent = getAgent(assignee);
  if (isHumanAgent(agent)) {
    return get('email', agent);
  }
  return null;
};
export const getFirstName = assignee => {
  const agent = getAgent(assignee);
  if (isHumanAgent(agent)) {
    return get('firstName', agent) || null;
  }
  return null;
};
export const getLastName = assignee => {
  const agent = getAgent(assignee);
  if (isHumanAgent(agent)) {
    return get('lastName', agent) || null;
  }
  return null;
};
export const getBotId = assignee => {
  const agent = getAgent(assignee);
  if (isBotAgent(agent)) {
    return get('id', agent);
  }
  return null;
};
export const getAgentState = get('agentState');
export const getAvatarUrl = get('avatarUrl');
export const getIsAssignable = get('isAssignable');
export const getActivationStatus = get('activationStatus');
export const isBot = assignee => isBotAgent(getAgent(assignee));
export const isHuman = assignee => isHumanAgent(getAgent(assignee));
const mapActivationStatus = status => {
  const activationStatus = readUnhandledEnumCase(status);
  if (activationStatus === 'ACTIVE' || activationStatus === 'DEACTIVATED' || activationStatus === 'REMOVED') {
    return activationStatus;
  }
  throw new Error(`The activation status for an agent has an unknown value of "${activationStatus}"`);
};
const mapAgentState = agentState => {
  switch (agentState) {
    case 'available':
      return AVAILABLE;
    case 'away':
      return AWAY;
    default:
      return UNKNOWN;
  }
};
export const convertRPCHumanToAssignee = responder => {
  var _responder$firstName, _responder$lastName, _responder$avatarUrl;
  const assignee = {
    activationStatus: mapActivationStatus(responder.activationStatus),
    agent: {
      email: responder.email || '',
      firstName: (_responder$firstName = responder.firstName) !== null && _responder$firstName !== void 0 ? _responder$firstName : null,
      lastName: (_responder$lastName = responder.lastName) !== null && _responder$lastName !== void 0 ? _responder$lastName : null,
      userId: responder.userId,
      isInWorkingHours: responder.isInWorkingHours || false,
      isOutOfOffice: responder.isOutOfOffice || false
    },
    agentState: mapAgentState(responder.availabilityStatus),
    avatarUrl: (_responder$avatarUrl = responder.avatarUrl) !== null && _responder$avatarUrl !== void 0 ? _responder$avatarUrl : null,
    id: `${responder.userId}`,
    isAssignable: true
  };
  if (responder.outOfOfficeEndsAt) {
    assignee.agent.outOfOfficeEndsAt = responder.outOfOfficeEndsAt;
  }
  return assignee;
};
export const convertAssignableHumanToAssignee = responder => {
  var _responder$avatar;
  const assignee = {
    activationStatus: responder.activationStatus,
    agent: {
      email: responder.email || '',
      firstName: responder.firstName,
      lastName: responder.lastName,
      userId: responder.userId,
      isInWorkingHours: responder.isInWorkingHours,
      isOutOfOffice: responder.isOutOfOffice
    },
    agentState: responder.agentState || 'UNKNOWN',
    avatarUrl: (_responder$avatar = responder.avatar) !== null && _responder$avatar !== void 0 ? _responder$avatar : null,
    id: `${responder.userId}`,
    isAssignable: responder.assignable
  };
  if (responder.outOfOfficeEndsAt) {
    assignee.agent.outOfOfficeEndsAt = responder.outOfOfficeEndsAt;
  }
  return assignee;
};