import once from 'transmute/once';
import memoize from 'transmute/memoize';
import { getHost, getHref, getSearch } from './wrappers';

/**
 * @description Check if the current host is a QA environment
 * @warn do not use directly, only exported for testing
 * @note do not use directly, only exported for testing */
export function __isQA(host = getHost()) {
  const [subdomain, domain, tld] = host.split('.');
  return (subdomain === 'app' || subdomain === 'local') && domain === 'hubspotqa' && tld === 'com';
}

/**
 * @description Check if the current host is a QA environment */
export const isQA = once(() => __isQA());
export const getQueryParams = memoize(() => new URLSearchParams(decodeURIComponent(getSearch())), getSearch);
export function getURLParameter(parameterName) {
  return getQueryParams().get(parameterName);
}
export function replaceURL(newUrl) {
  window.history.replaceState(null, '', newUrl);
}
export function stripURLParams() {
  replaceURL(decodeURI(getHref()).split('?')[0]);
}
export function removeURLParam(parameter) {
  const urlObject = new URL(decodeURI(getHref()));
  urlObject.searchParams.delete(parameter);
  const newUrl = urlObject.toString();
  replaceURL(newUrl);
}