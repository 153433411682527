'use es6';

import * as PromptableInboxConnectOptInFlowContainer from 'InboxConnectUtils/components/PromptableInboxConnectOptInFlowContainer';
import ConnectInbox from './lib/ConnectInbox';
import { GMAIL_OAUTHOR_BASE } from './utils/Links';
import serviceTypes from './constants/serviceTypes';
const _gmailLegalPrompt = PromptableInboxConnectOptInFlowContainer.gmail;
class ConnectGmailInbox extends ConnectInbox {
  constructor() {
    super(GMAIL_OAUTHOR_BASE, serviceTypes.gmail, _gmailLegalPrompt);
  }
}
export default new ConnectGmailInbox();