'use es6';

import PropTypes from 'prop-types';
import InboxTypes from '../../constants/InboxTypes';
import { enumValues } from '../../utils/EnumUtils';
import { serviceShape } from '../../service/ServiceShape';
export default {
  connectError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  emailAddress: PropTypes.string,
  isAutodetectFlow: PropTypes.bool,
  isAllowCustomEmailInput: PropTypes.bool,
  inboxType: PropTypes.oneOf(enumValues(InboxTypes)),
  portalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  restrictProvider: PropTypes.bool.isRequired,
  selectedClient: PropTypes.string,
  setConnectError: PropTypes.func.isRequired,
  setEmailAddress: PropTypes.func.isRequired,
  onWizardDone: PropTypes.func.isRequired,
  setSelectedClient: PropTypes.func.isRequired,
  setShownStep: PropTypes.func.isRequired,
  setConnectedAccount: PropTypes.func.isRequired,
  trackingClient: PropTypes.object.isRequired,
  allowedProviders: PropTypes.arrayOf(PropTypes.string),
  oauthOptions: PropTypes.object,
  service: serviceShape.isRequired,
  showExtensionInstall: PropTypes.bool.isRequired,
  inboxCreateContext: PropTypes.object,
  onStateChange: PropTypes.func,
  onCancel: PropTypes.func,
  selectedAutomation: PropTypes.bool,
  setSelectedAutomation: PropTypes.func.isRequired,
  whatToExpectDescription: PropTypes.node
};