const bindPortalId = (func, portalId) => (url, options = {}, ...args) => {
  const query = options.query || {};
  const boundOptions = Object.assign({}, options, {
    portalId,
    query: Object.assign({}, query, {
      portalId
    })
  });
  return func(url, boundOptions, ...args);
};
const injectCalls = ['get', 'put', 'post', 'delete'];
export default ((apiClient, portalId) => injectCalls.reduce((prev, val) => Object.assign({}, prev, {
  [val]: bindPortalId(apiClient[val], portalId)
}), {}));