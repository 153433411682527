/**
 * Events should follow the pattern <EVENT_NAME>_<ORIGIN>_MSG to avoid overlap with redux etc.
 * ORIGINS: EMBED (Iframeable instance), HOST (Parent page).
 * If the message is ORIGIN could be either HOST or EMBED omit an ORIGIN
 *
 * If any updates are made the the content or naming of these events make sure to bump the version:
 * calling-internal-common/constants/twilioWidgetOptions => WIDGET_MESSAGE_VERSION
 * this will avoid user issues when using the app.
 *
 */

export let ToCallingInstanceMessageTypes;
(function (ToCallingInstanceMessageTypes) {
  ToCallingInstanceMessageTypes["START_CALL_HOST_MSG"] = "START_CALL_HOST_MSG";
  ToCallingInstanceMessageTypes["LEARN_MORE_HOST_MSG"] = "LEARN_MORE_HOST_MSG";
  ToCallingInstanceMessageTypes["REGISTER_FROM_NUMBER_HOST_MSG"] = "REGISTER_FROM_NUMBER_HOST_MSG";
  ToCallingInstanceMessageTypes["ADD_PHONE_NUMBER_HOST_MSG"] = "ADD_PHONE_NUMBER_HOST_MSG";
  ToCallingInstanceMessageTypes["SET_THREAD_ID_HOST_MSG"] = "SET_THREAD_ID_HOST_MSG";
  ToCallingInstanceMessageTypes["SELECT_TO_NUMBER_HOST_MSG"] = "SELECT_TO_NUMBER_HOST_MSG";
  ToCallingInstanceMessageTypes["SET_WIDGET_CAPABILITIES_HOST_MSG"] = "SET_WIDGET_CAPABILITIES_HOST_MSG";
  ToCallingInstanceMessageTypes["SHOW_SETTINGS_STATE_HOST_MSG"] = "SHOW_SETTINGS_STATE_HOST_MSG";
  ToCallingInstanceMessageTypes["UPDATE_READY_STATE_HOST_MSG"] = "UPDATE_READY_STATE_HOST_MSG";
  ToCallingInstanceMessageTypes["SET_IS_QUEUE_TASK_HOST_MSG"] = "SET_IS_QUEUE_TASK_HOST_MSG";
  ToCallingInstanceMessageTypes["END_CALL_HOST_MSG"] = "END_CALL_HOST_MSG";
  ToCallingInstanceMessageTypes["DISCARD_FOLLOW_UP_TASK_HOST_MSG"] = "DISCARD_FOLLOW_UP_TASK_HOST_MSG";
  ToCallingInstanceMessageTypes["CONFIRM_WIDGET_RESET_REQUEST_HOST_MSG"] = "CONFIRM_WIDGET_RESET_REQUEST_HOST_MSG";
  ToCallingInstanceMessageTypes["RESET_WIDGET_HOST_MSG"] = "RESET_WIDGET_HOST_MSG";
  ToCallingInstanceMessageTypes["SET_DEFAULT_ASSOCIATIONS_THIRD_PARTY_HOST_MSG"] = "SET_DEFAULT_ASSOCIATIONS_THIRD_PARTY_HOST_MSG";
  ToCallingInstanceMessageTypes["SET_DEFAULT_ASSOCIATIONS_THIRD_PARTY_EMBED_MSG"] = "SET_DEFAULT_ASSOCIATIONS_THIRD_PARTY_EMBED_MSG";
  ToCallingInstanceMessageTypes["INCOMING_CALL_MSG"] = "INCOMING_CALL_MSG";
  ToCallingInstanceMessageTypes["OPEN_WIDGET_HOST_MSG"] = "OPEN_WIDGET_HOST_MSG";
})(ToCallingInstanceMessageTypes || (ToCallingInstanceMessageTypes = {}));
export let ToHostInstanceFromIframeMessageTypes;
(function (ToHostInstanceFromIframeMessageTypes) {
  ToHostInstanceFromIframeMessageTypes["CALL_ANSWERED_EMBED_MSG"] = "CALL_ANSWERED_EMBED_MSG";
  ToHostInstanceFromIframeMessageTypes["CALL_ENDED_EMBED_MSG"] = "CALL_ENDED_EMBED_MSG";
  ToHostInstanceFromIframeMessageTypes["CALL_SAVED_EMBED_MSG"] = "CALL_SAVED_EMBED_MSG";
  ToHostInstanceFromIframeMessageTypes["CALL_STARTED_EMBED_MSG"] = "CALL_STARTED_EMBED_MSG";
  ToHostInstanceFromIframeMessageTypes["INCOMING_CALL_EMBED_MSG"] = "INCOMING_CALL_EMBED_MSG";
  ToHostInstanceFromIframeMessageTypes["CONFIRM_WIDGET_RESET_RESPONSE_EMBED_MSG"] = " CONFIRM_WIDGET_RESET_RESPONSE_EMBED_MSG";
  ToHostInstanceFromIframeMessageTypes["CONFIRM_WIDGET_RESET_REQUEST_HOST_MSG_ACK"] = "CONFIRM_WIDGET_RESET_REQUEST_HOST_MSG_ACK";
  ToHostInstanceFromIframeMessageTypes["SET_DEFAULT_ASSOCIATIONS_THIRD_PARTY_HOST_MSG_ACK"] = "SET_DEFAULT_ASSOCIATIONS_THIRD_PARTY_HOST_MSG_ACK";
  ToHostInstanceFromIframeMessageTypes["NOTIFICATION_EMBED_MSG"] = "NOTIFICATION_EMBED_MSG";
  ToHostInstanceFromIframeMessageTypes["SET_MINIMUM_DIMENSIONS_EMBED_MSG"] = "SET_MINIMUM_DIMENSIONS_EMBED_MSG";
  ToHostInstanceFromIframeMessageTypes["CREATE_ZORSE_TICKET_EMBED_MSG"] = "CREATE_ZORSE_TICKET_EMBED_MSG";
  ToHostInstanceFromIframeMessageTypes["SET_ONBOARDING_STATUS_EMBED_MSG"] = "SET_ONBOARDING_STATUS_EMBED_MSG";
  ToHostInstanceFromIframeMessageTypes["REFRESH_CALLEE_OMNIBUS_EMBED_MSG"] = "REFRESH_CALLEE_OMNIBUS_EMBED_MSG";
  ToHostInstanceFromIframeMessageTypes["CREATED_FOLLOWUP_TASK_EMBED_MSG"] = "CREATED_FOLLOWUP_TASK_EMBED_MSG";
  ToHostInstanceFromIframeMessageTypes["RENDER_DISCARD_FOLLOW_UP_TASK_EMBED_MSG"] = "RENDER_DISCARD_FOLLOW_UP_TASK_EMBED_MSG";
  ToHostInstanceFromIframeMessageTypes["SWITCH_TO_THIRD_PARTY_EMBED_MSG"] = "SWITCH_TO_THIRD_PARTY_EMBED_MSG";
  ToHostInstanceFromIframeMessageTypes["RELOAD_EMBED_MSG"] = "RELOAD_EMBED_MSG";
  ToHostInstanceFromIframeMessageTypes["WIDGET_EMBED_HOST_SYNC"] = "WIDGET_EMBED_HOST_SYNC";
  ToHostInstanceFromIframeMessageTypes["HIDE_WIDGET_EMBED_MSG"] = "HIDE_WIDGET_EMBED_MSG";
})(ToHostInstanceFromIframeMessageTypes || (ToHostInstanceFromIframeMessageTypes = {}));
export let BidirectionalMessageTypes;
(function (BidirectionalMessageTypes) {
  BidirectionalMessageTypes["UPDATE_CALLEE_NUMBER_MSG"] = "UPDATE_CALLEE_NUMBER_MSG";
  BidirectionalMessageTypes["ADD_CALLABLE_ASSOCIATION_MSG"] = "ADD_CALLABLE_ASSOCIATION_MSG";
  BidirectionalMessageTypes["REMOVE_CALLABLE_ASSOCIATION_MSG"] = "REMOVE_CALLABLE_ASSOCIATION_MSG";
  BidirectionalMessageTypes["SELECTED_CALL_PROVIDER_MSG"] = "SELECTED_CALL_PROVIDER_MSG";
  BidirectionalMessageTypes["SELECTED_CALL_PROVIDER_SETTING_MSG"] = "SELECTED_CALL_PROVIDER_SETTING_MSG";
  BidirectionalMessageTypes["SELECTED_FROM_NUMBER_MSG"] = "SELECTED_FROM_NUMBER_MSG";
  BidirectionalMessageTypes["SELECTED_CALL_METHOD_MSG"] = "SELECTED_CALL_METHOD_MSG";
})(BidirectionalMessageTypes || (BidirectionalMessageTypes = {}));
// HOST -> CALLING INSTANCE
export const START_CALL_HOST_MSG = ToCallingInstanceMessageTypes.START_CALL_HOST_MSG;
export const SELECT_TO_NUMBER_HOST_MSG = ToCallingInstanceMessageTypes.SELECT_TO_NUMBER_HOST_MSG;
export const LEARN_MORE_HOST_MSG = ToCallingInstanceMessageTypes.START_CALL_HOST_MSG;
export const REGISTER_FROM_NUMBER_HOST_MSG = ToCallingInstanceMessageTypes.REGISTER_FROM_NUMBER_HOST_MSG;
export const ADD_PHONE_NUMBER_HOST_MSG = ToCallingInstanceMessageTypes.ADD_PHONE_NUMBER_HOST_MSG;
export const SET_THREAD_ID_HOST_MSG = ToCallingInstanceMessageTypes.SET_THREAD_ID_HOST_MSG;
export const SET_WIDGET_CAPABILITIES_HOST_MSG = ToCallingInstanceMessageTypes.SET_WIDGET_CAPABILITIES_HOST_MSG;
export const UPDATE_READY_STATE_HOST_MSG = ToCallingInstanceMessageTypes.UPDATE_READY_STATE_HOST_MSG;
export const SET_IS_QUEUE_TASK_HOST_MSG = ToCallingInstanceMessageTypes.SET_IS_QUEUE_TASK_HOST_MSG;
export const END_CALL_HOST_MSG = ToCallingInstanceMessageTypes.END_CALL_HOST_MSG;
export const DISCARD_FOLLOW_UP_TASK_HOST_MSG = ToCallingInstanceMessageTypes.DISCARD_FOLLOW_UP_TASK_HOST_MSG;
export const CONFIRM_WIDGET_RESET_REQUEST_HOST_MSG = ToCallingInstanceMessageTypes.CONFIRM_WIDGET_RESET_REQUEST_HOST_MSG;
export const RESET_WIDGET_HOST_MSG = ToCallingInstanceMessageTypes.RESET_WIDGET_HOST_MSG;
export const SET_DEFAULT_ASSOCIATIONS_THIRD_PARTY_HOST_MSG = ToCallingInstanceMessageTypes.SET_DEFAULT_ASSOCIATIONS_THIRD_PARTY_HOST_MSG;
export const SHOW_SETTINGS_STATE_HOST_MSG = ToCallingInstanceMessageTypes.SHOW_SETTINGS_STATE_HOST_MSG;
export const OPEN_WIDGET_HOST_MSG = ToCallingInstanceMessageTypes.OPEN_WIDGET_HOST_MSG;

// HOST <-> CALLING INSTANCE
export const UPDATE_CALLEE_NUMBER_MSG = BidirectionalMessageTypes.UPDATE_CALLEE_NUMBER_MSG;
export const ADD_CALLABLE_ASSOCIATION_MSG = BidirectionalMessageTypes.ADD_CALLABLE_ASSOCIATION_MSG;
export const REMOVE_CALLABLE_ASSOCIATION_MSG = BidirectionalMessageTypes.REMOVE_CALLABLE_ASSOCIATION_MSG;
export const SELECTED_CALL_PROVIDER_MSG = BidirectionalMessageTypes.SELECTED_CALL_PROVIDER_MSG;
export const SELECTED_CALL_PROVIDER_SETTING_MSG = BidirectionalMessageTypes.SELECTED_CALL_PROVIDER_SETTING_MSG;
export const SELECTED_CALL_METHOD_MSG = BidirectionalMessageTypes.SELECTED_CALL_METHOD_MSG;
export const SELECTED_FROM_NUMBER_MSG = BidirectionalMessageTypes.SELECTED_FROM_NUMBER_MSG;

// CALLING IFRAME -> HOST
export const CONFIRM_WIDGET_RESET_REQUEST_HOST_MSG_ACK = ToHostInstanceFromIframeMessageTypes.CONFIRM_WIDGET_RESET_REQUEST_HOST_MSG_ACK;
export const SET_DEFAULT_ASSOCIATIONS_THIRD_PARTY_HOST_MSG_ACK = ToHostInstanceFromIframeMessageTypes.SET_DEFAULT_ASSOCIATIONS_THIRD_PARTY_HOST_MSG_ACK;
export const NOTIFICATION_EMBED_MSG = ToHostInstanceFromIframeMessageTypes.NOTIFICATION_EMBED_MSG;
export const SET_MINIMUM_DIMENSIONS_EMBED_MSG = ToHostInstanceFromIframeMessageTypes.SET_MINIMUM_DIMENSIONS_EMBED_MSG;
export const CREATE_ZORSE_TICKET_EMBED_MSG = ToHostInstanceFromIframeMessageTypes.CREATE_ZORSE_TICKET_EMBED_MSG;
export const SET_ONBOARDING_STATUS_EMBED_MSG = ToHostInstanceFromIframeMessageTypes.SET_ONBOARDING_STATUS_EMBED_MSG;
export const REFRESH_CALLEE_OMNIBUS_EMBED_MSG = ToHostInstanceFromIframeMessageTypes.REFRESH_CALLEE_OMNIBUS_EMBED_MSG;
export const CREATED_FOLLOWUP_TASK_EMBED_MSG = ToHostInstanceFromIframeMessageTypes.CREATED_FOLLOWUP_TASK_EMBED_MSG;
export const RENDER_DISCARD_FOLLOW_UP_TASK_EMBED_MSG = ToHostInstanceFromIframeMessageTypes.RENDER_DISCARD_FOLLOW_UP_TASK_EMBED_MSG;
export const SWITCH_TO_THIRD_PARTY_EMBED_MSG = ToHostInstanceFromIframeMessageTypes.SWITCH_TO_THIRD_PARTY_EMBED_MSG;
export const RELOAD_EMBED_MSG = ToHostInstanceFromIframeMessageTypes.RELOAD_EMBED_MSG;
export const HIDE_WIDGET_EMBED_MSG = ToHostInstanceFromIframeMessageTypes.HIDE_WIDGET_EMBED_MSG;
export const INCOMING_CALL_EMBED_MSG = ToHostInstanceFromIframeMessageTypes.INCOMING_CALL_EMBED_MSG;
export const CALL_STARTED_EMBED_MSG = ToHostInstanceFromIframeMessageTypes.CALL_STARTED_EMBED_MSG;
export const CALL_ANSWERED_EMBED_MSG = ToHostInstanceFromIframeMessageTypes.CALL_ANSWERED_EMBED_MSG;
export const CALL_ENDED_EMBED_MSG = ToHostInstanceFromIframeMessageTypes.CALL_ENDED_EMBED_MSG;
export const CALL_SAVED_EMBED_MSG = ToHostInstanceFromIframeMessageTypes.CALL_SAVED_EMBED_MSG;