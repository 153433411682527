import { useMemo } from 'react';
import { closeActionModal, openActionModal } from '../store/actionsSlice';
import { useAppDispatch, useAppSelector } from '../store/rootReducer';
import { SubscriptionActions, SubscriptionStatus } from '../types/subscriptions';
import { makeActionsRecord } from '../utils/subscriptionUtils';
import { useTracker } from '../utils/usageTracker';
import { useActionValidation } from './useActionValidation';
import { useFetchActionEligibility } from './useFetchActionEligibility';
import { useSubscription } from './useSubscription';
// The idea here is for this hook to accomplish two things
// 1. ensure the user can in fact perform each action
//    has permission, the action is able to be performed (E.G. no pending payment etc)
//    and return an appropriate validation message for each action
// 2. provide methods to control each action modal
// that's it. Everything else is the purview of the individual modal
export function useSubscriptionActions() {
  const dispatch = useAppDispatch();
  const {
    refetch
  } = useFetchActionEligibility({
    fetchPolicy: 'cache-first'
  });
  const {
    trackInteraction
  } = useTracker();
  const subscription = useSubscription();
  const validation = useActionValidation();
  const actionState = useAppSelector(s => s.actions);
  return useMemo(() => makeActionsRecord(actionName => {
    const action = actionState[actionName];
    const validationMessage = validation[actionName];
    return {
      isOpen: action.isOpen,
      validation: validationMessage,
      open() {
        if (validationMessage) {
          return;
        }
        switch (actionName) {
          case SubscriptionActions.Cancel:
            trackInteraction('cancelSubscriptionActionClicked');
            break;
          case SubscriptionActions.Edit:
            trackInteraction('editSubscriptionActionClicked');
            break;
          case SubscriptionActions.UpdatePaymentMethod:
            trackInteraction('updateSubscriptionPaymentMethodActionClicked');
            break;
          case SubscriptionActions.EditNextPaymentDate:
          case SubscriptionActions.EditNextBillingDate:
            trackInteraction('updateSubscriptionNextPaymentDateActionClicked');
            break;
          case SubscriptionActions.Pause:
            if (subscription.status === SubscriptionStatus.Paused) {
              trackInteraction('resumeSubscriptionActionClicked');
            } else {
              trackInteraction('pauseSubscriptionActionClicked');
            }
            break;
          case SubscriptionActions.Delete:
            trackInteraction('deleteSubscriptionActionClicked');
            break;
          default:
            break;
        }
        dispatch(openActionModal(actionName));
      },
      close() {
        dispatch(closeActionModal(actionName));
      },
      revalidate: async () => {
        await refetch();
      }
    };
  }), [refetch, actionState, validation, subscription, dispatch, trackInteraction]);
}