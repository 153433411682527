import I18n from 'I18n';
import { AI_SUMMARY, ATTACHMENTS, AT_MENTIONS, CONTENT_ASSISTANT, FORMATTED_HYPERLINKS, IMAGES, PLAIN_TEXT, RICH_TEXT, INSERT_CORE, INSERT_TEMPLATES, INVOICES, MODEL_SMART_COMPOSE, PAYMENT_LINKS, SUBMIT_ON_ENTER, USER_DEFAULT_FONT, CONTENT_ASSISTANT_SLASH_COMMAND, CONTENT_ASSISTANT_INLINE_MENU } from 'conversations-thread-data/channel-configuration/public/constants';
import { getAttachmentCount, getAttachmentFileTypes, getFormatStyles, getInlineStyles, getMaxAttachmenSizeBytes, getMaxTotalAttachmentSizeBytes, getPlainTextHyperlinksEnabled, getRichTextEnabled, getTextStyles } from 'conversations-thread-data/channel-configuration/public/operators';
import { useMemo } from 'react';
import get from 'transmute/get';
function isEditingCapability(capability) {
  return [CONTENT_ASSISTANT, INSERT_CORE, INSERT_TEMPLATES, PAYMENT_LINKS, SUBMIT_ON_ENTER, MODEL_SMART_COMPOSE, USER_DEFAULT_FONT, INVOICES].includes(capability);
}
const getChannelCapabilitiesConfig = (capability, {
  editorConfig = {},
  fileAttachmentsConfig = {},
  renderExistingFileSelect,
  threadId
}) => {
  // NOTE - only defined if defaults are overridden
  const inlineStyleOverrides = getInlineStyles(editorConfig);
  const textStyleOverrides = getTextStyles(editorConfig);
  const formatStyleOverrides = getFormatStyles(editorConfig);
  const richTextEnabled = getRichTextEnabled(editorConfig);
  const plainTextHyperlinksEnabled = getPlainTextHyperlinksEnabled(editorConfig);
  return {
    [ATTACHMENTS]: {
      attachmentFileTypes: getAttachmentFileTypes(fileAttachmentsConfig),
      maxAttachmentSizeBytes: getMaxAttachmenSizeBytes(fileAttachmentsConfig),
      maxAttachmentCount: getAttachmentCount(fileAttachmentsConfig),
      maxTotalAttachmentSizeBytes: getMaxTotalAttachmentSizeBytes(fileAttachmentsConfig),
      renderExistingFileSelect
    },
    [IMAGES]: {
      renderExistingFileSelect
    },
    [CONTENT_ASSISTANT_SLASH_COMMAND]: {},
    [CONTENT_ASSISTANT_INLINE_MENU]: {},
    [AI_SUMMARY]: {
      threadId
    },
    [AT_MENTIONS]: {},
    [PLAIN_TEXT]: {
      useDefaultPlugins: !richTextEnabled,
      enablePlainTextHyperlinks: plainTextHyperlinksEnabled
    },
    [RICH_TEXT]: {
      inlineStyles: inlineStyleOverrides ? inlineStyleOverrides.length > 0 && {
        includedStyles: inlineStyleOverrides
      } : {},
      textStyles: formatStyleOverrides ? formatStyleOverrides.length > 0 : true,
      formatStyles: textStyleOverrides ? textStyleOverrides.length > 0 : true,
      tooltip: I18n.text('composer-ui.prosemirror.plugins.content.label')
    },
    [FORMATTED_HYPERLINKS]: {},
    [PAYMENT_LINKS]: {
      paymentLinkReferrerParameter: get('paymentLinkReferrerParameter', editorConfig),
      paymentLinksShowNewTag: get('paymentLinksShowNewTag', editorConfig)
    },
    [INVOICES]: {
      invoicesShowNewTag: get('invoicesShowNewTag', editorConfig)
    }
  }[capability];
};
const getEditingCapabilitiesConfig = capability => {
  return {
    [CONTENT_ASSISTANT]: {},
    [INSERT_CORE]: {},
    [INSERT_TEMPLATES]: {},
    [PAYMENT_LINKS]: {},
    [SUBMIT_ON_ENTER]: {},
    [MODEL_SMART_COMPOSE]: {},
    [USER_DEFAULT_FONT]: {},
    [INVOICES]: {},
    [CONTENT_ASSISTANT_INLINE_MENU]: {},
    [CONTENT_ASSISTANT_SLASH_COMMAND]: {}
  }[capability];
};

/**
 * @description This hook represents the application-side
 * implementation of the editor capabilities API. It supports
 * all capabilities (channel + editing) configured above.
 * @param {Array<String>} capabilities
 * @param {Object} options any extra runtime data required to
 * build the basic capabilities configuration object
 */
export const useProsemirrorEditorConfig = (capabilities, {
  editorConfig,
  fileAttachmentsConfig,
  renderExistingFileSelect,
  threadId
}) => {
  return useMemo(() => capabilities.reduce((accumulator, capability) => {
    const capabilityConfig = isEditingCapability(capability) ? getEditingCapabilitiesConfig(capability) : getChannelCapabilitiesConfig(capability, {
      editorConfig,
      renderExistingFileSelect,
      fileAttachmentsConfig,
      threadId
    });
    if (capabilityConfig) {
      // @ts-expect-error Type '{}' is not assignable to type 'CapabilityFixMe & AtMentionsCapabilityData'.
      accumulator[capability] = capabilityConfig;
    }
    return accumulator;
  }, {}), [capabilities, editorConfig, fileAttachmentsConfig, renderExistingFileSelect, threadId]);
};