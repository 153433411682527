// @ts-expect-error not typed
import DetectClientStep from 'InboxConnectUtils/components/steps/DetectClientStep';
// @ts-expect-error not typed
import ConnectInboxStep from 'InboxConnectUtils/components/steps/ConnectInboxStep';
// @ts-expect-error not typed
import OptIntoAutomationStep from 'InboxConnectUtils/components/steps/OptIntoAutomationStep';
// @ts-expect-error not typed
import ImapInboxStep from 'InboxConnectUtils/components/steps/ImapInboxStep';
// @ts-expect-error not typed
import ExchangeInboxStep from '../components/steps/ExchangeInboxStep';
// @ts-expect-error not typed
import FinishStep from 'InboxConnectUtils/components/steps/FinishStep';
// @ts-expect-error not typed
import ChooseProviderStep from 'InboxConnectUtils/components/steps/ChooseProviderStep';
// @ts-expect-error not typed
import FailureStep from 'InboxConnectUtils/components/steps/FailureStep';
// @ts-expect-error not typed
import TestMessageStep from 'InboxConnectUtils/components/steps/TestMessageStep';
// @ts-expect-error not typed
import PostSetupStep from '../components/steps/PostSetupStep';
// @ts-expect-error not typed
import EmailInputStep from 'InboxConnectUtils/components/steps/EmailInputStep';
// @ts-expect-error not typed
import CustomEmailDetectStep from 'InboxConnectUtils/components/steps/CustomEmailDetectStep';
export default {
  DETECT_PROVIDER_STEP: {
    component: DetectClientStep
  },
  CHOOSE_PROVIDER_STEP: {
    component: ChooseProviderStep
  },
  OPT_INTO_AUTOMATION_STEP: {
    component: OptIntoAutomationStep
  },
  EMAIL_INPUT_STEP: {
    component: EmailInputStep
  },
  CUSTOM_EMAIL_DETECT_STEP: {
    component: CustomEmailDetectStep
  },
  CONNECT_INBOX_STEP: {
    component: ConnectInboxStep
  },
  IMAP_INBOX_STEP: {
    component: ImapInboxStep
  },
  EXCHANGE_INBOX_STEP: {
    component: ExchangeInboxStep
  },
  TEST_MESSAGE_STEP: {
    component: TestMessageStep
  },
  FAILURE_STEP: {
    component: FailureStep
  },
  POST_SETUP_STEP: {
    component: PostSetupStep
  },
  FINISH_STEP: {
    component: FinishStep
  }
};