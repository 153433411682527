import { createSlice } from '@reduxjs/toolkit';
import { inboxUpdated } from 'find-and-filter-data/realtime-updates/public';
import { threadSeen } from 'find-and-filter-data/view-members-data/protected';
import { fetchViews, pollViews } from 'find-and-filter-data/views-data/public';
import { generateViewId } from 'find-and-filter-data/views-schema/public';
import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { viewMemberSeen, viewMemberUnread } from '../../../view-members-data/public';
import { threadListsUpdated } from '../counts/viewCountsActions';
import { applyOperationsToCountOffset } from '../updates/applyOperationsToCountOffset';
import { buildUpdatesFromInboxUpdate } from '../updates/buildUpdatesFromInboxUpdate';
import { buildUpdatesFromThreadListsUpdates } from '../updates/buildUpdatesFromThreadListsUpdates';
const initialState = {
  countOffsetsByViewId: ImmutableMap(),
  trackedThreadsByViewId: ImmutableMap()
};
function getThreadIdFromOperation(operation) {
  const objectKey = operation.objectKey;
  const objectKeyThreadId = objectKey && objectKey.threadId;
  return objectKeyThreadId || operation.threadId;
}
function isThreadListCountUpdate(operation) {
  const {
    threadId
  } = operation;
  if (threadId === null || typeof threadId === 'number') return true;
  return false;
}
function getObjectIdFromOperation(operation) {
  return operation.objectKey && operation.objectKey.objectId;
}
const getItemId = operation => getThreadIdFromOperation(operation) || !isThreadListCountUpdate(operation) && getObjectIdFromOperation(operation);
const isOperationForTrackedThread = (state, operation) => {
  const trackedThreads = state.trackedThreadsByViewId.get(operation.viewId);
  const threadId = getThreadIdFromOperation(operation);
  return trackedThreads && trackedThreads.has(threadId);
};
const applyOperationsToState = (operations, state) => {
  const countOffsetsByViewId = applyOperationsToCountOffset(state.countOffsetsByViewId, operations);
  const trackedThreadsByViewId = operations.reduce((trackedThreads, operation) => trackedThreads.update(operation.viewId, (set = ImmutableSet()) => {
    const itemId = getItemId(operation);
    switch (operation.operation) {
      case 'ADD':
      case 'TRACK_UNREAD':
        if (itemId) return set.add(Number(itemId));
        break;
      case 'REMOVE':
        if (itemId) return set.remove(Number(itemId));
        break;
      default:
        return set;
    }
    return set;
  }), state.trackedThreadsByViewId);
  return {
    countOffsetsByViewId,
    trackedThreadsByViewId
  };
};
function applyThreadSeenAction(state, action) {
  const {
    threadId,
    customViewIds,
    threadLists
  } = action.payload;
  const threadListOperations = threadLists.map(({
    threadListId,
    threadListType
  }) => ({
    operation: 'REMOVE',
    viewId: generateViewId({
      threadListId,
      threadListType
    }),
    threadId: parseInt(threadId, 10),
    update: null
  }));
  const customViewOperations = customViewIds.map(customViewId => ({
    operation: 'REMOVE',
    update: null,
    viewId: generateViewId({
      customViewId
    }),
    threadId: parseInt(threadId, 10)
  }));
  const operations = threadListOperations.concat(customViewOperations);
  return applyOperationsToState(operations, state);
}
function applyViewMemberSeenAction(state, action) {
  const {
    customViewIds,
    objectId
  } = action.payload;
  const customViewOperations = customViewIds.map(customViewId => ({
    operation: 'REMOVE',
    update: null,
    viewId: generateViewId({
      customViewId
    }),
    threadId: parseInt(objectId, 10)
  }));
  return applyOperationsToState(customViewOperations, state);
}
function applyViewMemberUnreadAction(state, action) {
  const {
    customViewIds,
    objectId
  } = action.payload;
  const customViewOperations = customViewIds.map(customViewId => ({
    operation: 'TRACK_UNREAD',
    update: null,
    viewId: generateViewId({
      customViewId
    }),
    threadId: parseInt(objectId, 10)
  }));
  return applyOperationsToState(customViewOperations, state);
}
export const viewUnreadStatusSlice = createSlice({
  name: 'viewUnreadStatus',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchViews.fulfilled, () => {
      return initialState;
    }).addCase(pollViews.fulfilled, () => {
      return initialState;
    }).addCase(
    // TODO - when THREAD_SEEN is replaced by threadSeen this case can be deleted
    // and applyThreadSeenAction no longer needs to exist separately
    'THREAD_SEEN', (state, action) => {
      return applyThreadSeenAction(state, action);
    }).addCase(threadSeen, (state, action) => {
      return applyThreadSeenAction(state, action);
    }).addCase(viewMemberSeen, (state, action) => {
      return applyViewMemberSeenAction(state, action);
    }).addCase(viewMemberUnread, (state, action) => {
      return applyViewMemberUnreadAction(state, action);
    }).addCase(inboxUpdated, (state, action) => {
      const {
        currentAgentId,
        message
      } = action.payload;
      const {
        operations,
        unreadOperations
      } = buildUpdatesFromInboxUpdate(message.inboxMemberUpdates, currentAgentId);
      const unreadThreadsOperations = unreadOperations.filter(operation => !isOperationForTrackedThread(state, operation));
      const updateOperations = operations.concat(unreadThreadsOperations).filter(operation => {
        if (operation.update && operation.update.seenByAgentIds) {
          return operation.update.seenByAgentIds.includes(currentAgentId) === false;
        }
        return true;
      });
      return applyOperationsToState(updateOperations, state);
    }).addCase(threadListsUpdated, (state, action) => {
      const {
        threadListsUpdate,
        agentId
      } = action.payload;
      const {
        operations,
        unreadOperations
      } = buildUpdatesFromThreadListsUpdates(threadListsUpdate.threadListType, threadListsUpdate.updates, agentId);
      const unreadThreadsOperations = unreadOperations.filter(operation => !isOperationForTrackedThread(state, operation));
      const updateOperations = operations.concat(unreadThreadsOperations).filter(operation => {
        if (operation.update && operation.update.seenByAgentIds) {
          return operation.update.seenByAgentIds.includes(agentId) === false;
        }
        return true;
      });
      return applyOperationsToState(updateOperations, state);
    });
  }
});