import { useCallback, useEffect } from 'react';
import { setFrom } from '../localStorage/LocalStorage';
import { getSkeletonStateSafely, makeSkeletonStateKey } from '../localStorage/skeletonState/skeletonStateUtils';
const THIRTY_DAYS_IN_MS = 30 * 24 * 60 * 60 * 1000;
const SEVEN_DAYS_IN_MS = 7 * 24 * 60 * 60 * 1000;
export const useSkeletonState = ({
  cardId,
  objectId,
  objectTypeId
}) => {
  var _localStorageSkeleton;
  const localStorageSkeletonState = getSkeletonStateSafely();
  const localStorageKey = makeSkeletonStateKey();
  const objectCoordinatesKey = `${objectTypeId}:${objectId}`;
  const skeletonState = (_localStorageSkeleton = localStorageSkeletonState.state[objectCoordinatesKey]) === null || _localStorageSkeleton === void 0 ? void 0 : _localStorageSkeleton[cardId];
  const setSkeletonState = useCallback(value => {
    setFrom(localStorageKey, Object.assign({}, localStorageSkeletonState, {
      state: Object.assign({}, localStorageSkeletonState.state, {
        [objectCoordinatesKey]: Object.assign({}, localStorageSkeletonState.state[objectCoordinatesKey], {
          [cardId]: {
            timestamp: Date.now(),
            value
          }
        })
      })
    }));
  }, [cardId, localStorageKey, localStorageSkeletonState, objectCoordinatesKey]);
  useEffect(() => {
    const now = Date.now();
    const updatedState = structuredClone(localStorageSkeletonState);

    // Once a week clean up the state
    if (now - updatedState.cleanupTimestamp > SEVEN_DAYS_IN_MS) {
      Object.keys(updatedState.state).forEach(objectCoordinates => {
        const entry = updatedState.state[objectCoordinates];
        if (entry) {
          Object.keys(entry).forEach(card => {
            const cardEntry = entry[card];
            if (cardEntry && now - cardEntry.timestamp > THIRTY_DAYS_IN_MS) {
              delete entry[cardId];
            }
          });
        }
      });
      updatedState.cleanupTimestamp = now;
      setFrom(localStorageKey, updatedState);
    }
  }, [cardId, localStorageKey, localStorageSkeletonState]);
  return [skeletonState, setSkeletonState];
};