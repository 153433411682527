/* eslint-disable conversations/no-private-internals-import */

import { bulkOperationsSlice } from 'find-and-filter-data/bulk-operations/protected';
import { latestOmnibusTimestampSlice } from 'find-and-filter-data/realtime-updates/protected';
import { filterResultsSlice } from 'find-and-filter-data/search-and-filter-data/_internal/filterResultsSlice';
import { filterValuesSlice } from 'find-and-filter-data/search-and-filter-data/_internal/filterValuesSlice';
import { sortOrderSlice } from 'find-and-filter-data/search-and-filter-data/_internal/sortOrder';
import { viewCountsSlice, viewUnreadStatusSlice } from 'find-and-filter-data/view-counts-and-unread/protected';
import { viewMembersUpdatesSlice } from 'find-and-filter-data/view-member-optimistic-updates/protected';
import { helpDeskViewMembersSlice, viewMembersSlice } from 'find-and-filter-data/view-members-data/protected';
import { fallbackModeReducer, spamThreadListSlice, viewsSlice } from 'find-and-filter-data/views-data/protected';
import { visitorsSlice } from 'find-and-filter-data/visitors/protected';
import { combineReducers } from 'redux';

/**
 * @description This describes the shape of the Redux state - assumes that
 * the findAndFilterData reducer is in the root of the store.
 */

/**
 * The findAndFilterData rootReducer - this should sit in the root level of your
 * Redux store until we can create our own Redux store. Please do not modify the name.
 */
export const findAndFilterData = combineReducers({
  bulkOperations: bulkOperationsSlice.reducer,
  fallbackModeEnabled: fallbackModeReducer,
  helpDeskViewMembers: helpDeskViewMembersSlice.reducer,
  latestOmnibusTimestamp: latestOmnibusTimestampSlice.reducer,
  searchAndFilter: combineReducers({
    filterResults: filterResultsSlice.reducer,
    filterValues: filterValuesSlice.reducer,
    sortOrder: sortOrderSlice.reducer
  }),
  spamThreadList: spamThreadListSlice.reducer,
  viewCounts: viewCountsSlice.reducer,
  viewMembers: viewMembersSlice.reducer,
  viewMembersUpdates: viewMembersUpdatesSlice.reducer,
  viewUnreadStatus: viewUnreadStatusSlice.reducer,
  views: viewsSlice.reducer,
  visitors: visitorsSlice.reducer
});