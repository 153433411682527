import { PUBLISH_MESSAGE } from '../constants/asyncActionTypes';
import { createAction } from 'redux-actions';
import { getPubSubClient } from 'conversations-internal-pub-sub/redux/selectors/pubSubClientGetters';
import { deserialize, serialize
// @ts-expect-error module not typed
} from 'conversations-message-history/common-message/serializers/messageSerializer';
import { closeThread } from '../../../thread-actions/public/clients';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
import { silenceErrorAlert } from 'conversations-error-reporting/error-actions/builders/silenceErrorAlert';
// @ts-expect-error module not typed
import { getClientType } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { getGenericChannelId } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { Metrics } from '../../../common/Metrics';
// @ts-expect-error module not typed
import { isCommonMessageFormat } from 'conversations-message-history/common-message-format/operators/cmfComparators';
// @ts-expect-error module not typed
import { isThreadComment } from 'conversations-message-history/comment-message/operators/cmComparators';
const {
  STARTED: PUBLISH_MESSAGE_STARTED,
  SUCCEEDED: PUBLISH_MESSAGE_SUCCEEDED,
  FAILED: PUBLISH_MESSAGE_FAILED
} = PUBLISH_MESSAGE;
function logMetrics(message) {
  if (isThreadComment(message)) {
    const clientType = getClientType(message);
    Metrics.counter('publish-comment', {
      type: 'reply',
      clientType
    }).increment();
  } else if (isCommonMessageFormat(message)) {
    const commonMessage = message;
    const genericChannelId = getGenericChannelId(commonMessage);
    const clientType = getClientType(commonMessage);
    const isHubSpotChannelId = genericChannelId < 1010;
    Metrics.counter('publish-common-message', {
      genericChannelId: isHubSpotChannelId ? `${genericChannelId}` : 'CUSTOM_CHANNEL',
      type: 'reply',
      clientType
    }).increment();
  }
}
export const publishMessageStarted = createAction(PUBLISH_MESSAGE_STARTED);
export const publishMessageSucceeded = createAction(PUBLISH_MESSAGE_SUCCEEDED);
export const publishMessageFailed = createAction(PUBLISH_MESSAGE_FAILED, ({
  channel,
  error,
  message,
  threadId,
  transient
}) => ({
  channel,
  error,
  message,
  threadId,
  transient
}), ({
  allowRetry
}) => allowRetry ? silenceErrorAlert() : {});
export const publishMessage = ({
  channel,
  message,
  threadId,
  transient = false,
  options = {},
  onError
}) => (dispatch, getState) => {
  const client = getPubSubClient(getState());
  if (!client) return undefined;
  dispatch(publishMessageStarted({
    transient,
    threadId,
    message,
    channel
  }));
  const publishData = {
    message: serialize(message),
    channel,
    threadId
  };
  const publishMessageClient = () => {
    return client.publish(publishData).then(({
      data: json
    }) => {
      const publishedMessage = deserialize({
        json
      });
      logMetrics(message);
      dispatch(publishMessageSucceeded({
        message: publishedMessage,
        transient,
        threadId
      }));
    });
  };
  return publishMessageClient().then(() => {
    if (options.closeOnSuccess) {
      return closeThread({
        threadId
      }).catch(error => {
        const {
          message: errorMessage,
          status,
          responseText
        } = error;
        reportError({
          error: new Error('Failed to close thread after publish.'),
          extra: {
            errorMessage,
            status,
            responseText
          },
          tags: {
            isVisibleToUser: true
          }
        });
      });
    } else {
      return Promise.resolve();
    }
  }).catch(error => {
    if (onError) onError();
    dispatch(publishMessageFailed({
      channel,
      error,
      message,
      transient,
      threadId
    }));
  });
};