import { getRecipients, getSenders } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getSingleDeliveryIdentifier } from 'conversations-message-history/common-message-format/operators/recipientGetters';
import { getDeliveryIdentifierValue } from 'conversations-message-history/common-message-format/operators/deliveryIdentifierGetters';
import { getDeliveryIdentifier as getSenderDeliveryIdentifier } from 'conversations-message-history/common-message-format/operators/senderGetters';
import { useMessageSubscription } from './useMessageSubscription';
import { HS_PHONE_NUMBER } from 'conversations-message-history/common-message-format/constants/deliveryIdentifierTypes';
import { SMS_GENERIC_CHANNEL_ID } from 'conversations-thread-data/generic-channels/public/constants';
export const useIsOptedOut = ({
  commonMessage,
  contactVid,
  currentEditorKey
}) => {
  const recipients = getRecipients(commonMessage);
  const recipient = recipients ? recipients.first() : null;
  const recipientDeliveryIdentifier = recipient && getSingleDeliveryIdentifier(recipient);
  const recipientPhoneNumber = recipientDeliveryIdentifier && getDeliveryIdentifierValue(recipientDeliveryIdentifier);
  const senders = getSenders(commonMessage);
  const sender = senders && senders.first();
  const senderDeliveryIdentifier = sender && getSenderDeliveryIdentifier(sender);
  const senderPhoneNumber = senderDeliveryIdentifier && senderDeliveryIdentifier.type === HS_PHONE_NUMBER && getDeliveryIdentifierValue(senderDeliveryIdentifier);
  const {
    messageSubscription
  } = useMessageSubscription({
    deferred: currentEditorKey !== SMS_GENERIC_CHANNEL_ID || !recipientPhoneNumber,
    senderPhoneNumber: senderPhoneNumber || '',
    recipientPhoneNumber: recipientPhoneNumber || '',
    contactVid
  });
  return !!messageSubscription && messageSubscription.subscriptionStatus === 'OPT_OUT';
};