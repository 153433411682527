export const EMAIL_RATE_LIMITED = 'EMAIL_RATE_LIMITED';
export const EMAIL_ATTACHMENT_PROCESSING_FAILURE = 'EMAIL_ATTACHMENT_PROCESSING_FAILURE';
export const SHORT_MESSAGES_MEDIA_FILE_TOO_LARGE = 'SHORT_MESSAGES_MEDIA_FILE_TOO_LARGE';
export const SHORT_MESSAGES_MEDIA_FILE_EMPTY = 'SHORT_MESSAGES_MEDIA_FILE_EMPTY';
export const SHORT_MESSAGES_SENDING_PHONE_NUMBER_MISCONFIGURED = 'SHORT_MESSAGES_SENDING_PHONE_NUMBER_MISCONFIGURED';
export const SHORT_MESSAGES_RECIPIENT_NOT_ENGAGED_IN_SESSION = 'SHORT_MESSAGES_RECIPIENT_NOT_ENGAGED_IN_SESSION';
export const SHORT_MESSAGES_RECIPIENT_NOT_OPTED_IN = 'SHORT_MESSAGES_RECIPIENT_NOT_OPTED_IN';
export const SHORT_MESSAGES_RECIPIENT_OPTED_OUT = 'SHORT_MESSAGES_RECIPIENT_OPTED_OUT';
export const SHORT_MESSAGES_RECIPIENT_DOES_NOT_USE_WHATSAPP = 'SHORT_MESSAGES_RECIPIENT_DOES_NOT_USE_WHATSAPP';
export const SHORT_MESSAGES_LIMITED = 'SHORT_MESSAGES_LIMITED';
export const SHORT_MESSAGES_MISSING_SCOPE = 'SHORT_MESSAGES_MISSING_SCOPE';
export const A2P_10DLC_CAMPAIGN_SUSPENDED = 'A2P_10DLC_CAMPAIGN_SUSPENDED';
export const SHORT_MESSAGES_SENDING_PHONE_NUMBER_SAME_AS_RECIPIENT = 'SHORT_MESSAGES_SENDING_PHONE_NUMBER_SAME_AS_RECIPIENT';
export const SHORT_MESSAGES_TEMPLATE_NOT_USABLE = 'SHORT_MESSAGES_TEMPLATE_NOT_USABLE';
export const SHORT_MESSAGES_UNRENDERABLE_TEMPLATE = 'SHORT_MESSAGES_UNRENDERABLE_TEMPLATE';
export const SHORT_MESSAGES_WHATSAPP_QUALITY_RATE_LIMIT_EXCEEDED = 'SHORT_MESSAGES_WHATSAPP_QUALITY_RATE_LIMIT_EXCEEDED';
export const SHORT_MESSAGES_WHATSAPP_TEMPLATE_MAX_HEADER_LENGTH_EXCEEDED = 'SHORT_MESSAGES_WHATSAPP_TEMPLATE_MAX_HEADER_LENGTH_EXCEEDED';
export const SHORT_MESSAGES_WHATSAPP_UNSUPPORTED_MEDIA_TYPE = 'SHORT_MESSAGES_WHATSAPP_UNSUPPORTED_MEDIA_TYPE';
export const SHORT_MESSAGES_AGENT_SERVER_ERROR = 'SHORT_MESSAGES_AGENT_SERVER_ERROR';
export const SHORT_MESSAGES_AGENT_TIMEOUT = 'SHORT_MESSAGES_AGENT_TIMEOUT';
export const SHORT_MESSAGES_UNREACHABLE_NUMBER = 'SHORT_MESSAGES_UNREACHABLE_NUMBER';
export const SHORT_MESSAGES_UNACCEPTABLE_PHONE_NUMBER = 'SHORT_MESSAGES_UNACCEPTABLE_PHONE_NUMBER';
export const SHORT_MESSAGES_MESSAGE_BLOCKED = 'SHORT_MESSAGES_MESSAGE_BLOCKED';
export const SHORT_MESSAGES_NOT_A_MOBILE_NUMBER = 'SHORT_MESSAGES_NOT_A_MOBILE_NUMBER';
export const SHORT_MESSAGES_CARRIER_DAILY_LIMIT_REACHED = 'SHORT_MESSAGES_CARRIER_DAILY_LIMIT_REACHED';
export const SHORT_MESSAGES_AGENT_UNKNOWN_DELIVERY_ERROR = 'SHORT_MESSAGES_AGENT_UNKNOWN_DELIVERY_ERROR';
export const SHORT_MESSAGES_WHATSAPP_MESSAGE_NOT_DELIVERED_TO_MAINTAIN_HEALTHY_ECOSYSTEM = 'SHORT_MESSAGES_WHATSAPP_MESSAGE_NOT_DELIVERED_TO_MAINTAIN_HEALTHY_ECOSYSTEM';
export const SHORT_MESSAGES_WHATSAPP_RECIPIENT_PART_OF_AN_EXPERIMENT = 'SHORT_MESSAGES_WHATSAPP_RECIPIENT_PART_OF_AN_EXPERIMENT';
export const SHORT_MESSAGES_WHATSAPP_VIRTUAL_SENDING_PHONE_NUMBER_AND_DISPLAY_NAME_NOT_APPROVED_YET = 'SHORT_MESSAGES_WHATSAPP_VIRTUAL_SENDING_PHONE_NUMBER_AND_DISPLAY_NAME_NOT_APPROVED_YET';
export const SHORT_MESSAGES_SMS_RECIPIENT_IS_A_LANDLINE = 'SHORT_MESSAGES_SMS_RECIPIENT_IS_A_LANDLINE';
export const SHORT_MESSAGES_SMS_RECIPIENT_PHONE_NUMBER_TYPE_IS_NOT_SENDABLE = 'SHORT_MESSAGES_SMS_RECIPIENT_PHONE_NUMBER_TYPE_IS_NOT_SENDABLE';