import { getGates } from 'conversations-thread-data/auth/public/selectors';
import { LIVE_CHAT_GENERIC_CHANNEL_ID } from 'conversations-thread-data/generic-channels/public/constants';
import { gate } from 'hub-http/gates';
import { useSelector } from 'react-redux';
export function useDisplayCloseLiveChatButton({
  currentChannelId
}) {
  const gates = useSelector(getGates);
  const isUngatedForCloseLiveChat = gates.includes(gate('Communication:CloseLiveChat'));
  const isCurrentChannelLiveChat = currentChannelId === LIVE_CHAT_GENERIC_CHANNEL_ID;
  const displayCloseLiveChatButton = isUngatedForCloseLiveChat && isCurrentChannelLiveChat;
  return displayCloseLiveChatButton;
}