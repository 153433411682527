import { List, fromJS } from 'immutable';
import { USERS_AND_TEAMS } from '../constants/RoutingTypes';
import RoutingRuleBase from './RoutingRuleBase';
const checkIsList = values => List.isList(values);
const ensureIsList = values => {
  // need to do this to tell TS we already have a list, so we can just return
  // this list
  if (checkIsList(values)) {
    return values;
  }
  return List(values);
};
class UsersAndTeamsRoutingRules extends RoutingRuleBase({
  '@type': USERS_AND_TEAMS,
  userIds: List(),
  teamIds: List()
}, 'UsersAndTeamsRoutingRules') {
  constructor(options) {
    super(fromJS(options || {}));
    return this.update('teamIds', teamIds => ensureIsList(teamIds)).update('userIds', userIds => ensureIsList(userIds));
  }
}
export const buildUsersAndTeamsRoutingRules = options => new UsersAndTeamsRoutingRules(options);
export default UsersAndTeamsRoutingRules;