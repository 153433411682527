import Raven from 'raven-js';
import { useMemo } from 'react';
import { useAgentVisitorDeliveryIdentifiers } from '../../../delivery-identifiers/public/hooks';
import { useSessionQuota } from './useSessionQuota';
const reportMissingIdentifiers = ({
  threadId,
  genericChannelId,
  agentDeliveryIdentifier,
  visitorDeliveryIdentifiers
}) => {
  Raven.capturePageEvent(`Error in fetching conversation session quota: delivery identifiers not found`, {
    extra: {
      genericChannelId,
      agentDeliveryIdentifier,
      visitorDeliveryIdentifiers,
      threadId
    }
  });
};
const reportTooManyVisitorIdentifiers = ({
  threadId,
  genericChannelId,
  visitorDeliveryIdentifiers
}) => {
  Raven.capturePageEvent(`Multiple recipient/visitor delivery identifiers in session quota request`, {
    extra: {
      threadId,
      genericChannelId,
      visitorDeliveryIdentifiers
    }
  });
};
export const useMessageLimitData = ({
  threadId,
  genericChannelId,
  deferred
}) => {
  const {
    loading: loadingDeliveryIdentifiers,
    error: fetchDeliveryIdentifiersError,
    threadAgentDeliveryIdentifierData,
    threadVisitorDeliveryIdentifiersData
  } = useAgentVisitorDeliveryIdentifiers({
    threadId,
    genericChannelId,
    onCompleted: response => {
      var _response$threadAgent, _response$threadVisit;
      const agentDeliveryIdentifier = (_response$threadAgent = response.threadAgentDeliveryIdentifierData) === null || _response$threadAgent === void 0 ? void 0 : _response$threadAgent.deliveryIdentifier;
      const visitorDeliveryIdentifiers = (_response$threadVisit = response.threadVisitorDeliveryIdentifiersData) === null || _response$threadVisit === void 0 ? void 0 : _response$threadVisit.map(data => data.deliveryIdentifier);
      if (!agentDeliveryIdentifier || !visitorDeliveryIdentifiers || !visitorDeliveryIdentifiers.length) {
        reportMissingIdentifiers({
          threadId,
          genericChannelId,
          agentDeliveryIdentifier,
          visitorDeliveryIdentifiers
        });
      }
      if (visitorDeliveryIdentifiers && visitorDeliveryIdentifiers.length > 1) {
        reportTooManyVisitorIdentifiers({
          threadId,
          genericChannelId,
          visitorDeliveryIdentifiers
        });
      }
    }
  });
  const senderDeliveryIdentifier = threadAgentDeliveryIdentifierData === null || threadAgentDeliveryIdentifierData === void 0 ? void 0 : threadAgentDeliveryIdentifierData.deliveryIdentifier;
  const recipientDeliveryIdentifiers = (threadVisitorDeliveryIdentifiersData === null || threadVisitorDeliveryIdentifiersData === void 0 ? void 0 : threadVisitorDeliveryIdentifiersData.map(data => data.deliveryIdentifier)) || [];
  const {
    sessionQuota,
    loading: loadingQuota,
    error
  } = useSessionQuota({
    genericChannelId,
    senderDeliveryIdentifier,
    recipientDeliveryIdentifiers,
    skip: deferred || loadingDeliveryIdentifiers || !!fetchDeliveryIdentifiersError
  });
  const messageLimitData = useMemo(() => sessionQuota && Object.assign({}, sessionQuota, {
    availableSendStatus: sessionQuota.sessionQuotaAllowedMessageType,
    limitStatus: sessionQuota.sessionQuotaMessageLimitStatus,
    sessionExpiresAt: sessionQuota.messageTypeExpiresAt,
    newestThreadId: sessionQuota !== null && sessionQuota !== void 0 && sessionQuota.threadId ? `${sessionQuota === null || sessionQuota === void 0 ? void 0 : sessionQuota.threadId}` : undefined
  }), [sessionQuota]);
  return {
    messageLimitData,
    loading: loadingQuota || loadingDeliveryIdentifiers,
    error
  };
};