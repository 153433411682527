'use es6';

import { Map as ImmutableMap } from 'immutable';
import { exchange, gmail, google_apps as googleApps, imap, outlook365, other, hubspotHosted } from 'InboxConnectUtils/constants/EmailClients';
import { EXCHANGE, GOOGLE_FREE, GOOGLE_APPS, OUTLOOK365, UNKNOWN, HUBSPOT_HOSTED, GOOGLE_APPS_API, GOOGLE_FREE_API } from '../constants/EmailProviderTypes';
const LANG_ROUTE = 'InboxConnectUtils.wizard.imapInbox.errors';
export const getServerValidationErrorMessage = errorCode => {
  const defaultErrorCode = 'error';
  const errorCodeWhitelist = {
    500: true,
    APP_PASSWORD_REQUIRED: true,
    CONNECTIONS_RATE_LIMITED: true,
    GENERIC_IMAP_DISABLED: true,
    GENERIC_MISSING_FOLDER: true,
    IMAP_AUTHENTICATION_ERROR: true,
    IMAP_SETTINGS_INCORRECT: true,
    IMAP_UNKNOWN_IO_ERROR: true,
    IMAP_VALIDATE_ERROR: true,
    SMTP_SETTINGS_INCORRECT: true,
    UNAUTHORIZED: true,
    UNTRUSTED_CERTIFICATES: true,
    MISSING_INBOX_FOLDER: true,
    MISSING_DRAFTS_FOLDER: true,
    GMAIL_IMAP_DISABLED: true,
    OFFICE_ERROR: true,
    OFFICE_ERROR_ORGANIZATION_ACCESS_BLOCKED: true,
    OFFICE_ERROR_REST_API_NOT_ENABLED_FOR_COMPONENT_SHARED_MAILBOX: true,
    OFFICE_ERROR_INVALID_LICENSE: true,
    TRANSIENT: true,
    IMAP_DISABLED_OR_LOGIN_FAILURE: true
  };
  const langKey = errorCode && errorCodeWhitelist[errorCode] ? errorCode : defaultErrorCode;
  return `${LANG_ROUTE}.${langKey}`;
};
export const getServerValidationErrorMessages = inboxErrors => inboxErrors.reduceRight((acc, code) => acc.set(code, getServerValidationErrorMessage(code)), ImmutableMap());
export const getCrmEmailClient = facsimileEmailClientType => {
  switch (facsimileEmailClientType) {
    case EXCHANGE:
      return exchange;
    case GOOGLE_FREE:
    case GOOGLE_FREE_API:
      return gmail;
    case GOOGLE_APPS:
    case GOOGLE_APPS_API:
      return googleApps;
    case OUTLOOK365:
      return outlook365;
    case HUBSPOT_HOSTED:
      return hubspotHosted;
    case UNKNOWN:
      return other;
    default:
      return imap;
  }
};
export const getErrorKeyName = errorCode => {
  const keyNames = ImmutableMap({
    PERMISSION_ERROR: 'PERMISSION_ERROR',
    other: 'other'
  });
  const keyName = keyNames.get(errorCode) || keyNames.get('other');
  return keyName;
};
export const getClientKey = providerName => {
  if (providerName === UNKNOWN) {
    return null;
  }
  return getCrmEmailClient(providerName).key;
};
export const getIsImapInbox = providerName => getCrmEmailClient(providerName).key === imap.key;