import { DEAL_TYPE_ID, INVOICE_TYPE_ID, QUOTE_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useMemo } from 'react';
import { usePortalCurrencies } from '../../../hooks/usePortalCurrencies';
import { COMMERCE_SUBSCRIPTION_WRITE_SCOPE, hasScope } from '../../../utils/auth';
import { handleError } from '../../../utils/errorHandling';
import { getLineItemConversionValidation } from '../getLineItemConversionValidation';
import { ObjectConversionError } from '../validationTypes';
import { useFetchConvertableObjectInfo } from './useFetchConvertableObjectInfo';
/**
 * Validates the object is supported for the billing wizard.
 * @param objectTypeId - Object Type from customer-data-objects/constants/ObjectTypeIds
 * @param objectId - The Id of the object to validate.
 * @returns {
 *   objectConversionErrors: Set<ObjectConversionError>,
 *   lineItemConversionErrors: Set<ObjectConversionError>
 * }
 */
export function useValidateObjectForConversion({
  objectTypeId,
  objectId
}) {
  const hasCreateAccess = hasScope(COMMERCE_SUBSCRIPTION_WRITE_SCOPE);
  const {
    data,
    error,
    loading,
    refetch
  } = useFetchConvertableObjectInfo(objectTypeId, objectId);
  const {
    supportedCurrencies,
    loading: currencyLoading
  } = usePortalCurrencies();
  return useMemo(() => {
    const result = {
      objectConversionErrors: new Set(),
      lineItemConversionErrors: new Set(),
      loading: loading || currencyLoading
    };
    if (!objectTypeId) {
      result.objectConversionErrors.add(ObjectConversionError.NO_OBJECT_TYPE);
      return result;
    }
    if (!objectId) {
      result.objectConversionErrors.add(ObjectConversionError.NO_OBJECT_ID);
      return result;
    }
    switch (objectTypeId) {
      case DEAL_TYPE_ID:
      case QUOTE_TYPE_ID:
      case INVOICE_TYPE_ID:
        break;
      default:
        result.objectConversionErrors.add(ObjectConversionError.UNSUPPORTED_OBJECT_TYPE);
        break;
    }
    if (loading || currencyLoading) {
      result.objectConversionErrors.add(ObjectConversionError.STILL_LOADING);
      return result;
    }
    if (error) {
      result.objectConversionErrors.add(ObjectConversionError.UNKNOWN_ERROR);
      return result;
    }
    if (!hasCreateAccess) {
      result.objectConversionErrors.add(ObjectConversionError.NO_CREATE_ACCESS);
      return result;
    }
    if (!data) {
      refetch().catch(handleError);
      result.objectConversionErrors.add(ObjectConversionError.OBJECT_NOT_FOUND);
      return result;
    }
    const supportedCurrencyCodes = new Set(supportedCurrencies.map(currency => currency.currencyCode));
    getLineItemConversionValidation(data, supportedCurrencyCodes).forEach(err => {
      result.lineItemConversionErrors.add(err);
    });
    return result;
  }, [currencyLoading, data, error, hasCreateAccess, loading, objectId, objectTypeId, refetch, supportedCurrencies]);
}