import { Record } from 'immutable';
export const MANUAL_BLOCK = 'MANUAL_BLOCK';
export const MANUAL_SPAM = 'MANUAL_SPAM';
export const SYSTEM_RULES = 'SYSTEM_RULES';
export const NOT_FILTERED = 'NOT_FILTERED';
export const MANUAL = 'MANUAL';
const ThreadListMember = Record({
  assignee: null,
  associatedTicketIds: [],
  channels: null,
  cvCrmObjectId: null,
  filteredSource: 'MANUAL',
  goalDetails: null,
  latestMessagePreview: null,
  latestMessageTimestamp: null,
  latestReceivedTimestamp: null,
  latestSentTimestamp: null,
  originalChannelInstanceId: -1,
  originalGenericChannelId: -1,
  previous: null,
  removal: null,
  seen: null,
  seenByAgentIds: null,
  sortTimestamp: null,
  status: null,
  threadId: null,
  update: null,
  vid: null,
  visitor: null
}, 'ThreadListMember');
export default ThreadListMember;