import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import { mapProperties } from '../utils/property';
import { INVOICE_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
export const deleteInvoice = objectId => {
  return http.delete(`inbounddb-objects/v1/crm-objects/INVOICE/${objectId}`);
};
export const sendInvoiceEmail = ({
  invoice,
  from,
  toContacts,
  ccContacts,
  informRecipientsOfUpdate,
  emailSubject
}) => http.post('invoices/v1/email/send', {
  data: Object.assign({
    portalId: PortalIdParser.get(),
    emailTemplateType: 'INVOICE_SEND',
    invoiceIds: [invoice.objectId],
    fromEmail: from.email,
    fromName: from.name,
    toContacts: toContacts.map(contact => ({
      objectId: contact.objectId,
      objectTypeId: contact.objectTypeId,
      portalId: contact.portalId,
      properties: mapProperties(contact.properties)
    })),
    ccContacts: ccContacts.map(contact => ({
      objectId: contact.objectId,
      objectTypeId: contact.objectTypeId,
      portalId: contact.portalId,
      properties: mapProperties(contact.properties)
    })),
    informRecipientsOfUpdate
  }, emailSubject && {
    emailContent: {
      subject: emailSubject
    }
  })
});
export const patchInvoice = (id, properties) => {
  return http.patch(`invoices/v1/invoices/${id}`, {
    data: {
      portalId: PortalIdParser.get(),
      objectTypeId: INVOICE_TYPE_ID,
      objectId: id,
      properties
    }
  });
};
export const updateEmailReminders = (id, upcomingReminderDatesToCancel) => {
  return http.patch(`invoices/v1/email-reminders/${id}`, {
    data: {
      portalId: PortalIdParser.get(),
      invoiceId: id,
      upcomingReminderDatesToCancel
    }
  });
};
export const downloadInvoice = objectId => {
  return http.get(`invoices/v1/downloads/${objectId}/download`);
};