import { useMessageLimit } from 'conversations-thread-data/message-limits/public/hooks';
import { useIsOptedOut } from './useIsOptedOut';
import { getDisabledReason } from '../operators/getDisabledReason';
import { useChannelInstanceById } from 'conversations-thread-data/channel-instances/public/hooks';
/*
  This hook should replace useMessageLimit for deriving whether or not the editor should be disabled and for what reason.
  As we add more disabled alerts, consider abstracting the shouldDisableEditor logic out of messageLimitHook.
*/
export const useDisableEditor = ({
  threadId,
  currentEditorKey,
  gates,
  commonMessage,
  contactVid,
  originalChannelInstanceId
}) => {
  const {
    channelInstance,
    isFailed
  } = useChannelInstanceById({
    channelInstanceId: originalChannelInstanceId,
    deferred: !originalChannelInstanceId
  });
  const isInstanceDeleted = !channelInstance && isFailed;
  const isInstanceDisabled = channelInstance && !channelInstance.isActive;
  const channelInstanceDisabledOrDeleted = isInstanceDeleted || isInstanceDisabled;
  const {
    isUngatedForWhatsApp
  } = gates;
  const {
    shouldDisableEditor: shouldDisableEditorPerMessageLimits,
    showTemplateSelector,
    messageLimitData,
    newestThreadId
  } = useMessageLimit({
    threadId,
    currentEditorKey,
    isUngatedForWhatsApp: Boolean(isUngatedForWhatsApp)
  });
  const hasDuplicateThread = Boolean(newestThreadId && newestThreadId !== threadId);
  const isOptedOutOfMessages = useIsOptedOut({
    commonMessage,
    contactVid,
    currentEditorKey
  });
  const reason = getDisabledReason({
    currentEditorKey,
    shouldDisableEditorPerMessageLimits,
    showTemplateSelector,
    hasDuplicateThread,
    isOptedOutOfMessages,
    channelInstanceDisabledOrDeleted: !!channelInstanceDisabledOrDeleted
  });
  return {
    shouldDisableEditor: Boolean(reason),
    reason,
    messageLimitData,
    newestThreadId
  };
};