import { GLOBAL_NAME } from '../constants/Globals';
import { isValidEventType } from '../utils/getIsValidEventType';
export default function handleTriggerCallingEvent({
  event,
  data
}) {
  const isValidEvent = isValidEventType(event);
  if (!isValidEvent || !window[GLOBAL_NAME].updateQueueByEventType || !window[GLOBAL_NAME].updateQueueByEventType[event]) {
    return;
  }
  window[GLOBAL_NAME].updateQueueByEventType[event].forEach(cb => cb(data));
}