export const CHANNEL_CHANGE_RECEIVED = 'CHANNEL_CHANGE_RECEIVED';
export const HISTORY_MESSAGE_RECEIVED = 'HISTORY_MESSAGE_RECEIVED';
export const MESSAGES_UPDATED = 'MESSAGES_UPDATED';
export const PLAYBACK_ACTIONS = 'PLAYBACK_ACTIONS';
export const READ_RECEIPT_RECEIVED = 'READ_RECEIPT_RECEIVED';
export const THREAD_STATUS_UPDATED = 'THREAD_STATUS_UPDATED';
export const TYPING_MESSAGE_EXPIRED = 'TYPING_MESSAGE_EXPIRED';
export const TYPING_MESSAGE_RECEIVED = 'TYPING_MESSAGE_RECEIVED';
export const MESSAGE_REFRESH = 'MESSAGE_REFRESH';
export const REFETCH_OVERSIZED_CONVERSATION_MESSAGE = 'REFETCH_OVERSIZED_CONVERSATION_MESSAGE';
export const DIRECT_REPLY_RECEIVED = 'DIRECT_REPLY_RECEIVED';
export const REPLY_RECOMMENDATION_RECEIVED = 'REPLY_RECOMMENDATION_RECEIVED';
export const REFETCH_CONVERSATION_MESSAGE = 'REFETCH_CONVERSATION_MESSAGE';
export const REPLACE_HISTORY_MESSAGE = 'REPLACE_HISTORY_MESSAGE';
export const REMOVE_THREAD_MESAGE_FROM_HISTORY = 'REMOVE_THREAD_MESAGE_FROM_HISTORY';