import apiClient from 'hub-http/clients/apiClient';
import { registerMutation, useMutation } from 'data-fetching-client';
const createOnFileCheckoutSession = ({
  invoiceId,
  paymentMethodOnFileId
}) => apiClient.post(`invoices/v1/hubspot-payment/on-file-checkout-session`, {
  data: {
    invoiceId,
    paymentMethodOnFileId
  }
});
const CREATE_ON_FILE_CHECKOUT_SESSION = registerMutation({
  fieldName: 'createOnFileCheckoutSession',
  args: ['invoiceId', 'paymentMethodOnFileId'],
  fetcher: createOnFileCheckoutSession
});
export const useCreateOnFileCheckoutSession = () => {
  return useMutation(CREATE_ON_FILE_CHECKOUT_SESSION);
};