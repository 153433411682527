import formatName from 'I18n/utils/formatName';
import { getFirstName, getLastName, getId, getEmail
// @ts-expect-error module not typed
} from 'conversations-internal-schema/sales-users/operators/salesUsersGetters';
export const mapUsersResponseToAtMentionUsers = (usersResponse, usersWithOOOHours) => {
  const {
    adminUsers = [],
    freeUsers = [],
    routableUsers = []
  } = usersResponse || {};
  const uniqueUsers = [...adminUsers, ...freeUsers, ...routableUsers].reduce((accumulator, user) => accumulator[getId(user)] ? accumulator : Object.assign({}, accumulator, {
    [getId(user)]: user
  }), {});
  return Object.keys(uniqueUsers).reduce((accumulator, userId) => {
    const user = uniqueUsers[userId];
    const outOfOfficeHours = usersWithOOOHours[userId];
    const userOption = Object.assign({
      value: getEmail(user),
      text: formatName({
        firstName: getFirstName(user),
        lastName: getLastName(user)
      }),
      id: getId(user)
    }, outOfOfficeHours ? {
      outOfOfficeHours
    } : {});
    return [/* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    ...accumulator, userOption];
  }, []);
};