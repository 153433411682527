import { CONVERSATION_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
export function appliedFilters({
  isNewView
}) {
  return {
    key: 'applied-filters',
    properties: {
      action: 'applied filters',
      'creation-type': isNewView ? 'new' : 'update'
    }
  };
}
export function addedViewDetails({
  isNewView
}) {
  return {
    key: 'added-view-details',
    properties: {
      action: 'added view details',
      'creation-type': isNewView ? 'new' : 'update'
    }
  };
}
export function clickCreateViewButton() {
  return {
    key: 'click-to-create-view',
    properties: {
      action: 'clicked create view'
    }
  };
}
export function clickEditViewButton() {
  return {
    key: 'click-to-edit-view',
    properties: {
      action: 'clicked edit view'
    }
  };
}
export function clickDeleteViewButton() {
  return {
    key: 'click-to-delete-view',
    properties: {
      action: 'clicked delete view'
    }
  };
}
export function viewCreationCompleted({
  isNewView,
  viewCreatedAt
}) {
  /**
   * Note: We know this will not provide an entirely accurate picture of how long
   * the view took to complete on the BE - this is meant to represent the creationTime
   * from the perspective of the FE and so Date.now() does a good enough job for that
   */
  const creationTime = Math.floor((Date.now() - viewCreatedAt) / 1000);
  return {
    key: 'view-creation-completed',
    properties: {
      action: 'view creation completed',
      'creation-time': isNewView ? creationTime : -1,
      'creation-type': isNewView ? 'new' : 'update'
    }
  };
}
function getFilterBranchObjectTypes(branch) {
  const objectTypeIds = branch.filterBranches && branch.filterBranches.length && branch.filterBranches.map(x => {
    return x.objectTypeId ? x.objectTypeId : CONVERSATION_TYPE_ID;
  });
  if (objectTypeIds) return objectTypeIds;
  return [branch.objectTypeId || CONVERSATION_TYPE_ID];
}
function getFilterObjectTypes(filters) {
  // flatMap filterBranches with getFilterBranchObjectTypes
  return filters.filterBranches.reduce((objectTypeIds, branch) => {
    /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    return objectTypeIds.concat(getFilterBranchObjectTypes(branch));
  }, []);
}
function getFilterProperties(filters) {
  return filters.filterBranches.reduce((properties, branch) => {
    const filterProperties = branch.filters.length ? branch.filters.map(filter => filter.property) : [];
    const branchProperties = branch.filterBranches.length ? getFilterProperties(branch) : [];
    /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    return properties.concat(filterProperties, branchProperties);
  }, []);
}
export function viewCreationStarted({
  isNewView,
  filters
}) {
  // this removes duplicate object types by casting to a set
  const uniqueObjectTypes = [...new Set(getFilterObjectTypes(filters))];
  const properties = [...new Set(getFilterProperties(filters))];
  return {
    key: 'view-creation-started',
    properties: {
      action: 'view creation started',
      'creation-type': isNewView ? 'new' : 'update',
      'filter-properties': properties.sort().join(', '),
      'objects-used': uniqueObjectTypes.sort().join(', '),
      'view-type': 'team view'
    }
  };
}
const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;
export function viewDeleted({
  viewCreatedAt
}) {
  const deletedAfterDays = viewCreatedAt && Math.floor((Date.now() - viewCreatedAt) / ONE_DAY_IN_MS);
  return {
    key: 'view-deleted',
    properties: {
      action: 'view deleted',
      'deleted-after-days': deletedAfterDays
    }
  };
}