import { GLOBAL_NAME } from '../constants/Globals';
import { isValidEventType } from '../utils/getIsValidEventType';
export default function handleRemoveEventListener({
  event,
  callback
}) {
  if (!isValidEventType(event)) {
    return;
  }
  if (!window[GLOBAL_NAME].updateQueueByEventType || !window[GLOBAL_NAME].updateQueueByEventType[event]) {
    return;
  }
  const indexToRemove = window[GLOBAL_NAME].updateQueueByEventType[event].indexOf(callback);
  if (indexToRemove >= 0) {
    window[GLOBAL_NAME].updateQueueByEventType[event].splice(indexToRemove, 1);
  }
}