import { OrderedMap } from 'immutable';
export const applyViewMemberUpdates = ({
  viewMemberUpdates,
  viewMembers
}) => {
  if (!viewMembers) return OrderedMap();
  return viewMemberUpdates.reduce((updatedMembers, viewMemberPatch, threadId) => {
    if (!updatedMembers.has(threadId)) return updatedMembers;
    return updatedMembers.update(threadId, viewMember => viewMember.merge(viewMemberPatch.threadListMember));
  }, viewMembers);
};