import { useQuery } from 'data-fetching-client';
import { GET_USER_CAPACITY_DATA_QUERY } from '../../../__generated__/chirp/com/hubspot/cv/assignments/rpc/UserCapacityLimitsV2Service.dfc';
export const useUserLevelCapacityLimits = agentId => {
  const {
    data,
    loading
  } = useQuery(GET_USER_CAPACITY_DATA_QUERY, {
    variables: {
      userId: agentId
    }
  });
  const capacityLimits = data === null || data === void 0 ? void 0 : data.getUserCapacityData.totalTicketUserCapacityData;
  return {
    capacityLimits,
    loading
  };
};