import { toOptionWithDepth } from '../../team/operators/toOptionWithDepth';
export const convertTeamsToOptions = (teams, depth = 1) => {
  const options = [];
  for (const team of teams) {
    options.push(toOptionWithDepth(team, depth));
    if (team.childTeams.length) {
      options.push(...convertTeamsToOptions(team.childTeams, depth + 1));
    }
  }
  return options;
};