import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
const CRM_SEARCH_URL = 'crm-search/search';
export const CONTACT_TYPE_ID = '0-1';
export const fetchEmailContacts = async input => {
  const {
    results
  } = await http.post(CRM_SEARCH_URL, {
    data: {
      count: 20,
      objectTypeId: CONTACT_TYPE_ID,
      offset: 0,
      portalId: PortalIdParser.get(),
      query: input,
      requestOptions: {
        properties: ['email', 'firstname', 'lastname']
      }
    }
  });
  return results.map(result => {
    var _result$properties$em, _result$properties$em2, _result$properties$fi, _result$properties$fi2, _result$properties$la, _result$properties$la2;
    return {
      objectId: result.objectId,
      portalId: result.portalId,
      properties: {
        email: {
          name: 'email',
          value: (_result$properties$em = (_result$properties$em2 = result.properties.email) === null || _result$properties$em2 === void 0 ? void 0 : _result$properties$em2.value) !== null && _result$properties$em !== void 0 ? _result$properties$em : ''
        },
        firstname: {
          name: 'firstname',
          value: (_result$properties$fi = (_result$properties$fi2 = result.properties.firstname) === null || _result$properties$fi2 === void 0 ? void 0 : _result$properties$fi2.value) !== null && _result$properties$fi !== void 0 ? _result$properties$fi : ''
        },
        lastname: {
          name: 'lastname',
          value: (_result$properties$la = (_result$properties$la2 = result.properties.lastname) === null || _result$properties$la2 === void 0 ? void 0 : _result$properties$la2.value) !== null && _result$properties$la !== void 0 ? _result$properties$la : ''
        }
      },
      objectTypeId: result.objectTypeId
    };
  });
};