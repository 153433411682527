import ChatFilterOptions from 'conversations-internal-schema/constants/ChatFilterOptions';
import http from 'conversations-http/clients/http';
const {
  ENDED
} = ChatFilterOptions;
export const closeThread = ({
  threadId
}) => {
  const url = `conversations-threads/v1/threads/${threadId}/status`;
  const data = {
    status: ENDED
  };
  return http.put(url, {
    data
  });
};