import { useCallback, useEffect, useMemo, useState } from 'react';
import http from 'conversations-http/clients/http';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
function fetchCustomViewSettings(workspaceId) {
  return http.get('conversations-custom-views/v1/settings/users', {
    query: {
      workspaceId
    }
  });
}
function postCustomViewSettings(req) {
  return http.post('conversations-custom-views/v1/settings/users', {
    data: req
  });
}
const mapToStrings = arr => arr ? arr.map(String) : [];
const defaultSettings = {
  viewGroupOrder: {},
  viewGroupOrderV2: {},
  viewQuickFilters: []
};
export default function useCustomViewUserSettings(workspaceId) {
  const [loading, updateLoading] = useState(true);
  const [settings, _updateSettings] = useState(defaultSettings);
  const {
    viewGroupOrder,
    viewGroupOrderV2,
    viewQuickFilters
  } = settings;
  const order = useMemo(
  // While migration is underway, map number ids to strings for compatibility with UI
  () => (viewGroupOrderV2 === null || viewGroupOrderV2 === void 0 ? void 0 : viewGroupOrderV2.PRIVATE) || mapToStrings(viewGroupOrder.PRIVATE), [viewGroupOrder, viewGroupOrderV2]);
  useEffect(() => {
    fetchCustomViewSettings(workspaceId).then(response => {
      if (response.settings) {
        _updateSettings(response.settings);
      }
    }).catch(error => reportError({
      error
    })).finally(() => updateLoading(false));
  }, [workspaceId]);
  const updateSettings = useCallback(updatedSettings => {
    postCustomViewSettings({
      settings: updatedSettings,
      workspaceId
    }).catch(error => {
      reportError({
        error
      });
    });
  }, [workspaceId]);
  const updateOrder = useCallback(updatedOrder => {
    _updateSettings(currentSettings => {
      const updatedSettings = Object.assign({}, currentSettings, {
        viewGroupOrderV2: {
          PRIVATE: updatedOrder
        },
        // clear out deprecated properties
        starredViews: undefined,
        viewGroupOrder: {}
      });
      updateSettings(updatedSettings);
      return updatedSettings;
    });
  }, [updateSettings]);
  const updateViewFilters = useCallback((viewFilterId, filters) => {
    _updateSettings(currentSettings => {
      var _currentSettings$view;
      const updatedViewFilters = (_currentSettings$view = currentSettings.viewQuickFilters) === null || _currentSettings$view === void 0 ? void 0 : _currentSettings$view.filter(({
        customViewId
      }) => Number(viewFilterId) !== customViewId);
      updatedViewFilters.push({
        customViewId: Number(viewFilterId),
        quickFilters: filters
      });
      const updatedSettings = {
        viewGroupOrder: currentSettings.viewGroupOrder,
        viewGroupOrderV2: currentSettings.viewGroupOrderV2,
        viewQuickFilters: updatedViewFilters
      };
      updateSettings(updatedSettings);
      return updatedSettings;
    });
  }, [updateSettings]);
  return {
    loading,
    order,
    updateOrder,
    viewQuickFilters,
    updateViewFilters
  };
}