/**
 * @NOTE This file should only contain global access wrappers.
 * Do not place any application logic or things that consume the wrappers in this file.
 */

export function getSearch() {
  return window.location.search;
}
export function getHref() {
  return window.location.href;
}
export function getHost() {
  return window.location.host;
}
export function assignLocation(url) {
  window.location.assign(url);
}