import Raven from 'raven-js';
import { useMemo } from 'react';
import { fetchAssignees } from '../api/clients/fetchAssignees';
import { fetchWorkspaceResponder } from '../api/fetchResponder';
import { DEFAULT_PAGINATION } from '../constants/reassignment-dropdown';
import { convertAssignableHumanToAssignee } from '../operators/assignees';
import { checkUserInAssignees } from '../operators/checkUserInAssignees';
import { buildAssigneeOptions, buildReassignmentOptions } from '../utils/build-options';
const getEmptyLoadOptions = (__filterValue, __callback, pagination = DEFAULT_PAGINATION) => Promise.resolve({
  options: [],
  pagination
});
const checkShouldFetchUser = (assignees, currentUserId, filterValue) => Boolean(!filterValue && currentUserId && assignees && !checkUserInAssignees(assignees, currentUserId));
const fetchCurrentUserAsAssignee = (response, currentUserId, workspaceId) => fetchWorkspaceResponder(currentUserId, workspaceId).then(responder => Object.assign({}, response, {
  assignees: response.assignees.concat(convertAssignableHumanToAssignee(responder))
})).catch(error => {
  Raven.captureException(error);
  return response;
});
const updateSections = ({
  currentUserId,
  filterValue,
  pagination,
  response,
  teams,
  isUngatedForHelpDeskManualAssignmentScopeFiltering
}) => {
  const {
    pagination: newPagination,
    assigneeOptions
  } = buildAssigneeOptions(response, pagination);
  const sections = buildReassignmentOptions({
    currentUserId,
    filterValue,
    hasMore: newPagination.hasMore,
    teams,
    users: assigneeOptions,
    isUngatedForHelpDeskManualAssignmentScopeFiltering
  });
  return {
    options: sections,
    pagination: newPagination
  };
};
const fetchAssigneeOptions = ({
  currentUserId,
  filterValue,
  objectTypeId,
  offset,
  workspaceId
}) => fetchAssignees({
  objectTypeId,
  offset,
  searchQuery: filterValue,
  workspaceId
}).then(response => {
  if (checkShouldFetchUser(response.assignees, currentUserId, filterValue)) {
    return fetchCurrentUserAsAssignee(response, currentUserId, workspaceId);
  }
  return response;
});
const getOptionsLoader = ({
  currentUserId,
  objectTypeId,
  teams,
  workspaceId,
  isUngatedForHelpDeskManualAssignmentScopeFiltering
}) => (filterValue, __callback, pagination = DEFAULT_PAGINATION) => fetchAssigneeOptions({
  currentUserId,
  filterValue,
  objectTypeId,
  offset: pagination.offset,
  workspaceId
}).then(response => updateSections({
  currentUserId,
  filterValue,
  pagination,
  response,
  teams,
  isUngatedForHelpDeskManualAssignmentScopeFiltering
})).catch(error => {
  Raven.captureException(error);
  return {
    options: [],
    pagination
  };
});
export const useLoadOptions = ({
  currentUserId,
  objectTypeId,
  teams,
  workspaceId,
  isUngatedForHelpDeskManualAssignmentScopeFiltering
}) => {
  return useMemo(() => {
    if (!workspaceId) {
      return getEmptyLoadOptions;
    }
    return getOptionsLoader({
      currentUserId,
      objectTypeId,
      teams,
      workspaceId,
      isUngatedForHelpDeskManualAssignmentScopeFiltering
    });
  }, [currentUserId, objectTypeId, teams, workspaceId, isUngatedForHelpDeskManualAssignmentScopeFiltering]);
};