'use es6';

import { Record } from 'immutable';
export const UnsavedChangesRecord = Record({
  changes: '',
  onCancel: undefined,
  onSave: undefined,
  loading: false,
  errors: '',
  warnings: ''
}, 'UnsavedChangesRecord');