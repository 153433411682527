import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { threadSeen } from 'find-and-filter-data/view-members-data/protected';
import { buildViewMembersPage, mergeIncomingViewMembersPage } from 'find-and-filter-data/view-members-data/public';
import { ViewMembersPage } from 'find-and-filter-data/view-members-schema/public';
import { AsyncStatus } from '../../common/public';
import { checkThreadMembership, fetchSearchAndFilterResults, clearFilterResults } from './filterResultsActions';
import { clearSearchAndFilterValues } from './filterValuesActions';
const initialState = {
  data: new ViewMembersPage(),
  loading: false,
  status: AsyncStatus.UNINITIALIZED
};
export const filterResultsSlice = createSlice({
  initialState,
  name: 'filterResults',
  reducers: {},
  extraReducers: builder => {
    builder.addCase(checkThreadMembership.rejected, (state, {
      meta
    }) => {
      const threadId = meta.arg.threadId;
      if (state.data.indexedMembers.has(`${threadId}`)) {
        state.data = state.data.update('indexedMembers', members => members.delete(`${threadId}`)).update('totalCount', count => count - 1);
      }
    }).addCase(threadSeen, (state, {
      payload
    }) => {
      state.data = state.data.updateIn(['indexedMembers', payload.threadId], tlm => {
        if (tlm) {
          return tlm.set('seen', payload.seen);
        }
        return tlm;
      });
    }).addCase(fetchSearchAndFilterResults.pending, (state, {
      meta
    }) => Object.assign({}, state, {
      latestRequestId: meta.requestId,
      loading: true,
      status: meta.arg.offsetId && meta.arg.offsetId > 0 ? AsyncStatus.PAGINATED_FETCH_LOADING : AsyncStatus.INITIAL_FETCH_LOADING
    })).addCase(fetchSearchAndFilterResults.rejected, (state, {
      meta
    }) => {
      //If this request is not the latest request, ignore it.
      if (state.latestRequestId !== meta.requestId) return state;
      return Object.assign({}, state, {
        loading: false,
        status: AsyncStatus.FAILED
      });
    }).addCase(fetchSearchAndFilterResults.fulfilled, (state, {
      meta,
      payload
    }) => {
      //If this request is not the latest request, ignore it.
      if (state.latestRequestId !== meta.requestId) return state;

      //This logic is almost identical to the logic in viewMembersSlice. Consider extracting duplicate
      //functionality once this has been moved to the f&f library.
      if (meta.arg.offsetId && meta.arg.offsetId > 0) {
        const oldPage = state.data;
        const newPage = buildViewMembersPage(payload).set('totalCount', oldPage.totalCount);
        const updatedPage = mergeIncomingViewMembersPage({
          oldPage,
          newPage,
          totalCount: payload.totalCount
        });
        state.data = updatedPage;
      } else {
        state.data = buildViewMembersPage(payload);
      }
      state.loading = false;
      state.status = AsyncStatus.SUCCEEDED;
    }).addMatcher(isAnyOf(clearFilterResults, clearSearchAndFilterValues), () => initialState);
  }
});
export default filterResultsSlice.reducer;