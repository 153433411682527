import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CONVERSATION_TO_TICKET } from 'customer-data-objects/associations/AssociationTypes';
import { getVid } from 'conversations-inbox-lib/contacts/public/operators';
import { getSidebarCardsProps } from '../render-helpers/getSidebarCardsProps';
import { hasTicketsWriteAccess as getHasTicketsWriteAccess } from '../../selectors/userSelectors/hasTicketsWriteAccess';
import { trackEvent } from '../../usage-tracking/clients/usageTracker';
import { getEditPropertySettingsLinkClicked } from '../constants/Events';
import { useCanEditCurrentContact } from './useCanEditCurrentContact';
import { useFetchAssociatedTickets } from 'conversations-inbox-lib/tickets/fetch/useFetchAssociatedTicket';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
/**
 * Creates the `cardProps` for the customer-data-sidebar to render
 */
export const useSidebarCardProps = ({
  contact,
  conversationId,
  openCreateTicketModal,
  subjectId,
  threadId
} = {}) => {
  const CONVERSATION_TO_TICKET_V2 = `${CONVERSATION_TO_TICKET}_V2`;
  const {
    associatedTicket,
    refetchTickets
  } = useFetchAssociatedTickets({
    conversationId
  });
  const onTicketDisassociationSuccess = useCallback(disassociationPayload => {
    if (disassociationPayload.associationType === CONVERSATION_TO_TICKET || disassociationPayload.associationType === CONVERSATION_TO_TICKET_V2) {
      refetchTickets().catch(error => {
        reportError({
          error
        });
      });
    }
  }, [CONVERSATION_TO_TICKET_V2, refetchTickets]);
  const canEditCurrentContact = useCanEditCurrentContact(subjectId);
  const hasTicketsWriteAccess = useSelector(getHasTicketsWriteAccess);
  const handleEditPropertySettingsClicked = useCallback(objectType => {
    trackEvent(getEditPropertySettingsLinkClicked({
      objectType
    }));
  }, []);
  const cardProps = useMemo(() => getSidebarCardsProps({
    associatedTicket,
    canEditCurrentContact,
    conversationId,
    handleEditPropertySettingsClicked,
    hasTicketsWriteAccess,
    onTicketDisassociationSuccess,
    openCreateTicketModal,
    subjectId,
    threadId,
    vid: getVid(contact)
  }), [associatedTicket, canEditCurrentContact, contact, conversationId, handleEditPropertySettingsClicked, hasTicketsWriteAccess, onTicketDisassociationSuccess, openCreateTicketModal, subjectId, threadId]);
  return cardProps;
};