import identity from 'transmute/identity';
import { OPENING_THREAD } from '../constants/asyncStatuses';
import { requestStateUpdate } from 'conversations-async-data/async-data/operators/requestStateUpdate';
/**
 * @description a custom status setter that sets the status
 * of a thread list membmer details object to 'OPENING_THREAD'
 * @param {AsyncData} asyncData AsyncData record to update
 * @returns {AsyncData}
 */
export const setOpeningThreadStatus = asyncData =>
// @ts-expect-error AsyncData does not allow this status
requestStateUpdate(OPENING_THREAD, identity, asyncData);