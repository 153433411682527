'use es6';

import difference from 'transmute/difference';
import omit from 'transmute/omit';
import pipe from 'transmute/pipe';
import isEmpty from 'transmute/isEmpty';
export const shouldDiscoveryBeUsed = (startValue, valueAfterDiscover) => {
  const comparer = pipe(difference(startValue), omit(['password']),
  // Password change would not be affected by discovery, omit from comparison
  isEmpty);
  return comparer(valueAfterDiscover);
};