import keyMirror from 'react-utils/keyMirror';
export const CALDAV_ERROR_TYPES = keyMirror({
  GENERIC: null,
  NOT_FOUND: null,
  READ_ONLY: null,
  UNAUTHORIZED: null
});
export const CALDAV_ERROR_MAP = {
  [CALDAV_ERROR_TYPES.UNAUTHORIZED]: {
    errorType: CALDAV_ERROR_TYPES.UNAUTHORIZED,
    errorFields: ['username', 'password']
  },
  [CALDAV_ERROR_TYPES.NOT_FOUND]: {
    errorType: CALDAV_ERROR_TYPES.NOT_FOUND,
    errorFields: ['hostname']
  },
  [CALDAV_ERROR_TYPES.GENERIC]: {
    errorType: CALDAV_ERROR_TYPES.GENERIC,
    errorFields: []
  },
  // Julius may support this before V1
  [CALDAV_ERROR_TYPES.READ_ONLY]: {
    errorType: CALDAV_ERROR_TYPES.READ_ONLY,
    errorFields: ['username']
  }
};