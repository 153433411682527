export const DETECT_PROVIDER_STEP = 'DETECT_PROVIDER_STEP';
export const CHOOSE_PROVIDER_STEP = 'CHOOSE_PROVIDER_STEP';
export const CONNECT_INBOX_STEP = 'CONNECT_INBOX_STEP';
export const IMAP_INBOX_STEP = 'IMAP_INBOX_STEP';
export const EXCHANGE_INBOX_STEP = 'EXCHANGE_INBOX_STEP';
export const TEST_MESSAGE_STEP = 'TEST_MESSAGE_STEP';
export const FAILURE_STEP = 'FAILURE_STEP';
export const FINISH_STEP = 'FINISH_STEP';
export const OPT_INTO_AUTOMATION_STEP = 'OPT_INTO_AUTOMATION_STEP';
export const POST_SETUP_STEP = 'POST_SETUP_STEP';
export const EMAIL_INPUT_STEP = 'EMAIL_INPUT_STEP';
export const CUSTOM_EMAIL_DETECT_STEP = 'CUSTOM_EMAIL_DETECT_STEP';
/* Special step states (not mapped to a particular component) */
export const EXIT_SUCCESS = 'EXIT_SUCCESS';
export const START_CONNECT = 'START_CONNECT';