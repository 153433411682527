import Raven from 'raven-js';
export function getRefundDisabledReasonsMessageKey(refundDisabledReason, paymentMethod) {
  const getPaymentProcessingTranslation = paymentMethodType => {
    switch (paymentMethodType) {
      case 'ach':
        return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.paymentProcessing.ach';
      case 'bacs':
        return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.paymentProcessing.bacs';
      case 'pads':
        return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.paymentProcessing.pads';
      case 'sepa':
        return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.paymentProcessing.sepa';
      default:
        return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.paymentProcessing.default';
    }
  };
  const getRefundDeadlineExceededTranslation = paymentMethodType => {
    switch (paymentMethodType) {
      case 'ach': // https://docs.stripe.com/payments/ach-direct-debit#refunds
      case 'bacs': // https://docs.stripe.com/payments/payment-methods/bacs-debit#refunds
      case 'pads': // https://docs.stripe.com/payments/acss-debit#refunds
      case 'sepa':
        // https://docs.stripe.com/payments/sepa-debit#refunds
        return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.refundDeadlineExceeded.180Days';
      default:
        return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.refundDeadlineExceeded.default';
    }
  };
  switch (refundDisabledReason) {
    case 'PAYMENT_PROCESSING':
      return getPaymentProcessingTranslation(paymentMethod);
    case 'REFUND_PROCESSING':
      return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.refundProcessing';
    case 'FULLY_REFUNDED':
      return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.fullyRefunded';
    case 'REFUND_DEADLINE_EXCEEDED':
      return getRefundDeadlineExceededTranslation(paymentMethod);
    case 'PAYMENT_FAILED':
      return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.paymentFailed';
    default:
      Raven.captureMessage('Refund disabled reason not listed.');
      return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.default';
  }
}