/* hs-eslint ignored failing-rules */
/* eslint-disable no-prototype-builtins */

'use es6';

/**
 * @internal
 */
export const filterFalseyValuesFromObject = obj => {
  const result = {};
  if (typeof obj === 'object') {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && !!obj[key]) {
        result[key] = obj[key];
      }
    }
  }
  return result;
};