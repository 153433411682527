'use es6';

import { Record } from 'immutable';
const FacsimileInboxRecord = Record({
  '@type': null,
  id: null,
  email_address: null,
  inbox_type: null,
  created: null,
  updated: null,
  user_id: null,
  error_message: null,
  portal_id: null,
  hub_spot_user_id: null,
  blacklisted_date: null,
  error_type: null,
  generic_inbox_settings: null,
  blacklisted: null,
  bucket: null,
  shared: null,
  must_get_hub_spot_user_id: null,
  oauth_scope_set: null,
  oauth_service_type: null
}, 'FacsimileInboxRecord');
export default FacsimileInboxRecord;