'use es6';

import Promptable from 'UIComponents/decorators/Promptable';
import GmailInboxConnectOptInFlowContainerModal from './InboxConnect/GmailInboxConnectOptInFlowContainerModal';
import ImapInboxConnectOptInFlowContainerModal from './InboxConnect/ImapInboxConnectOptInFlowContainerModal';
import OutlookInboxConnectOptInFlowContainerModal from './InboxConnect/OutlookInboxConnectOptInFlowContainerModal';
export const gmail = Promptable(GmailInboxConnectOptInFlowContainerModal);
export const imap = Promptable(ImapInboxConnectOptInFlowContainerModal);
export const outlook = Promptable(OutlookInboxConnectOptInFlowContainerModal);

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = module.exports;
}