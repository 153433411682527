import PropTypes from 'prop-types';
export const serviceShape = PropTypes.shape({
  getAppName: PropTypes.func,
  getTracker: PropTypes.func,
  getPortalId: PropTypes.func.isRequired,
  getGates: PropTypes.func,
  enableConnectedAccount: PropTypes.func,
  createTestEmail: PropTypes.func,
  queryTestEmail: PropTypes.func,
  getProviderNameByDomain: PropTypes.func,
  getFacsimileInboxByEmail: PropTypes.func,
  discoverInboxSettings: PropTypes.func,
  createImapInbox: PropTypes.func,
  createExchangeInbox: PropTypes.func,
  track: PropTypes.func,
  removeAllConnectionsForEmail: PropTypes.func,
  getUserInfo: PropTypes.func,
  setEnableAutomation: PropTypes.func,
  getEnableAutomation: PropTypes.func
});