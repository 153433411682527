import { GLOBAL_NAME } from '../constants/Globals';
import { createUpdateReadyStateMessage } from 'calling-global-api/lib/internalEventMessageCreators';
import shouldSendReadyState from '../utils/shouldSendReadyState';
import { postInternalMessage } from '../initialize';
import { triggerHostMessageSetSubjectForThirdParties } from '../triggerHostMessage/triggerHostMessageSetSubjectForThirdParties';
export default function updateReadyState({
  subjectId,
  objectTypeId,
  isQueueTask
}) {
  const toSubject = {
    objectId: Number(subjectId),
    objectTypeId
  };
  if (shouldSendReadyState(toSubject)) {
    window[GLOBAL_NAME].lastSentReadyState = toSubject;
    const message = createUpdateReadyStateMessage({
      toSubject,
      isQueueTask
    });

    // Sends to iframe
    postInternalMessage(message);

    // Sends to host
    triggerHostMessageSetSubjectForThirdParties(toSubject);
  }
}