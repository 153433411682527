import { useEffect, useMemo, useState } from 'react';
import { IFrameContextHandler, MessageTypes } from '../utils/IFrameContextHandler';
import usePrevious from './usePrevious';
export function useIframedApplication({
  isOpen,
  onClose,
  initializeArgs
}) {
  const [contextHandler, setContextHandler] = useState(null);
  const previouslyOpen = usePrevious(isOpen);
  const [gotOpenSignalBeforeContextExisted, setGotOpenSignalBeforeContextExisted] = useState(false);
  useEffect(() => {
    if (contextHandler && !isOpen) {
      setContextHandler(null);
    }
    if (contextHandler && gotOpenSignalBeforeContextExisted) {
      contextHandler.send(MessageTypes.INIT_MODAL, initializeArgs);
      setGotOpenSignalBeforeContextExisted(false);
    }
    if (!contextHandler) {
      // if isOpen == true and we don't yet have a contextHandler, we set the
      // gotOpenSignalBeforeContextExisted flag so we can handle it as soon as we get a context.
      if (isOpen) {
        setGotOpenSignalBeforeContextExisted(true);
      }
      return;
    }
    const justOpened = !previouslyOpen && isOpen;
    const justClosed = previouslyOpen && !isOpen;
    if (justOpened) {
      contextHandler.send(MessageTypes.INIT_MODAL, initializeArgs);
    } else if (justClosed) {
      contextHandler.send(MessageTypes.HIDE_MODAL, undefined);
    }
  }, [isOpen, previouslyOpen, initializeArgs, contextHandler, gotOpenSignalBeforeContextExisted]);
  return useMemo(() => {
    const handleClose = changes => {
      if (contextHandler) {
        contextHandler.send(MessageTypes.HIDE_MODAL, undefined);
      }
      onClose(changes);
    };
    return {
      isOpen,
      contextHandler,
      handleClose,
      handleMessage: message => {
        if (contextHandler) {
          contextHandler.receive(message);
        }
      },
      handleError: error => {
        console.error(error);
        handleClose({
          error
        });
      },
      handleOnReady: newContext => {
        const handler = new IFrameContextHandler(newContext).on(MessageTypes.MODAL_HIDDEN, changes => {
          onClose(changes);
        });
        setContextHandler(handler);
      }
    };
  }, [contextHandler, isOpen, onClose]);
}