import { PANTERA } from 'HubStyleTokens/colors';
import UIIconCircle from 'UIComponents/icon/UIIconCircle';
import UICheckbox from 'UIComponents/input/UICheckbox';
import styled, { css } from 'styled-components';
export const PreviewCardTimestampWrapper = styled.div.withConfig({
  displayName: "PreviewCardStyles__PreviewCardTimestampWrapper",
  componentId: "d591zn-0"
})(["color:", "!important;font-size:12px;font-weight:400;white-space:nowrap;margin-left:2px;"], props => props.color || PANTERA);
export const PreviewCardIconAvatar = styled(UIIconCircle).withConfig({
  displayName: "PreviewCardStyles__PreviewCardIconAvatar",
  componentId: "d591zn-1"
})(["", ""], ({
  checked
}) => checked && css(["display:none !important;"]));
export const PreviewCardCheckbox = styled(UICheckbox).withConfig({
  displayName: "PreviewCardStyles__PreviewCardCheckbox",
  componentId: "d591zn-2"
})(["margin:5px;", ""], ({
  checked
}) => checked && css(["&&&{display:block !important;}"]));