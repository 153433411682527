import { List } from 'immutable';
import QuoteRecord from 'customer-data-objects/quote/QuoteRecord';
import QuoteSignerRecord from 'customer-data-objects/quote/QuoteSignerRecord';
import { QUOTE_ASSOCIATIONS } from 'customer-data-objects/quote/constants/properties';
import LineItemRecord from 'customer-data-objects/lineItem/LineItemRecord';
import { QUOTE_SETTINGS } from 'quotes-ui-lib/constants/quoteSettings';
import keyBy from 'hs-lodash/keyBy';
function parseQuoteSettingValues(settings) {
  return (settings || []).reduce((acc, {
    key,
    value
  }) => {
    try {
      // string attributes come back as ""value"" - need to be de-stringified
      return Object.assign({}, acc, {
        [key]: JSON.parse(value)
      });
    } catch (e) {
      return Object.assign({}, acc, {
        [key]: value
      });
    }
  }, {});
}
export function parseQuoteHighlightQueryResult(data) {
  var _crmObject$defaultAss, _crmObject$defaultAss2, _viewer$hubSpotUser;
  const crmObject = data.crmObject;
  const viewer = data.viewer;
  const dealId = (_crmObject$defaultAss = crmObject.defaultAssociations) === null || _crmObject$defaultAss === void 0 || (_crmObject$defaultAss = _crmObject$defaultAss.toDeals) === null || _crmObject$defaultAss === void 0 ? void 0 : _crmObject$defaultAss.edges[0].node.id;
  const properties = keyBy(crmObject.allProperties, property => property.name);
  const lineItemEdges = ((_crmObject$defaultAss2 = crmObject.defaultAssociations) === null || _crmObject$defaultAss2 === void 0 || (_crmObject$defaultAss2 = _crmObject$defaultAss2.toLineItems) === null || _crmObject$defaultAss2 === void 0 ? void 0 : _crmObject$defaultAss2.edges) || [];
  const lineItems = List(lineItemEdges.map(edge => LineItemRecord.fromJS({
    objectId: edge.node.id,
    properties: edge.node.defaultProperties
  })));
  const quote = QuoteRecord.fromJS({
    quote: {
      objectId: crmObject.id,
      properties
    },
    associations: {
      [QUOTE_ASSOCIATIONS.QUOTE_TO_DEAL]: [dealId]
    },
    quoteAssociatedObjects: {
      lineItems
    }
  });
  const quoteEsign = crmObject.esignRequest ? {
    status: crmObject.esignRequest.status,
    signers: List(crmObject.esignRequest.signers.map(signer => new QuoteSignerRecord(signer)))
  } : null;
  const allowPayBeforeEsign = parseQuoteSettingValues(data.quoteSettingValues)[QUOTE_SETTINGS.ALLOW_PAY_BEFORE_ESIGN];
  return {
    quote,
    quoteEsign,
    userEmail: ((_viewer$hubSpotUser = viewer.hubSpotUser) === null || _viewer$hubSpotUser === void 0 ? void 0 : _viewer$hubSpotUser.email) || '',
    userOwnerId: viewer.ownerId,
    allowPayBeforeEsign
  };
}