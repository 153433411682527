import { createMetricsFactory } from 'metrics-js';
const Metrics = createMetricsFactory('ably-hubspot-js');

/**
 * Report specific errors to Grafana for customer Kepler alerting rules.
 */
export function keplerAlerting(error) {
  switch (error.code) {
    case 40160:
      Metrics.counter('permissions-error-40160').increment();
      break;
    default:
      return;
  }
}
const COUNTER_METRICS = ['attach-failure', 'attach-success', 'connect-failure', 'connect-success', 'message-playback-success', 'message-playback-failure', 'reauthorize-failure', 'reauthorize-success', 'subscribe-failure', 'subscribe-success', 'subscribe-presence-failure', 'subscribe-presence-success'];
const TIMER_METRICS = ['attach-max', 'attach-min', 'connect-max', 'connect-min', 'message-playback-max', 'message-playback-min', 'reauthorize-max', 'reauthorize-min', 'subscribe-max', 'subscribe-min', 'subscribe-presence-max', 'subscribe-presence-min'];
export function logAggregatedData(metric, value) {
  if (COUNTER_METRICS.includes(metric) && value) {
    Metrics.counter(metric).increment(value);
  }
  if (TIMER_METRICS.includes(metric) && value) {
    Metrics.timer(metric).update(value);
  }
}