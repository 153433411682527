// For V3 gates: https://product.hubteam.com/docs/gating/next-gen/usage.html#frontend-usage
import { userInfoSync } from 'hub-http/userInfo';
import Raven from 'raven-js';
import { useMemo } from 'react';
import memoize from 'transmute/memoize';
import { getLocalStorageValue } from './browserStorage';
import { isSome } from './common';
import { getURLParameter, isQA } from './urls';
import { getSearch } from './wrappers';
export function getAuth() {
  try {
    return userInfoSync();
  } catch (err) {
    Raven.captureException(err, {
      extra: {
        description: 'Failed to retrieve gates synchronously. This is not expected.'
      }
    });
    return {
      gates: [],
      user: null,
      portal: null
    };
  }
}
export function useAuth() {
  const auth = getAuth();
  return useMemo(() => auth,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [auth.gates.length, auth.user, auth.portal]);
}

//* @note v3 gates are prefixed with 'g8:'
export function getOverride(key) {
  if (!isQA()) {
    return null;
  }
  const urlParam = getURLParameter(key);
  if (isSome(urlParam)) {
    return urlParam === 'true';
  }
  const storedValue = getLocalStorageValue(key);
  if (typeof storedValue === 'boolean') {
    return storedValue;
  }
  return null;
}
const portalHasGate = memoize((gates, gate) => {
  return gates.includes(gate);
}, (gates, gate) => `gate: ${gate}, gatesLength: ${gates.length}`);
export const hasGate = memoize(gate => {
  const {
    gates
  } = getAuth();
  const override = getOverride(gate);
  if (isSome(override)) {
    // eslint-disable-next-line no-console
    console.log(`[DEV] Overriding gate "${gate}" with value ${override}`);
    return override;
  }
  return portalHasGate(gates, gate);
}, gate => `gate: ${gate} isQA: ${isQA()}, searchParams: ${getSearch()}`);
const userHasScope = memoize((scopes, scope) => {
  return scopes.includes(scope);
}, (scopes, scope) => `scope: ${scope}, scopesLength: ${scopes.length}`);
export const hasScope = memoize(scope => {
  const {
    user
  } = getAuth();
  const override = getOverride(scope);
  if (isSome(override)) {
    // eslint-disable-next-line no-console
    console.log(`[DEV] Overriding scope "${scope}" with value ${override}`);
    return override;
  }
  return userHasScope((user === null || user === void 0 ? void 0 : user.scopes) || [], scope);
}, scope => `scope: ${scope} isQA: ${isQA()}, searchParams: ${getSearch()}`);

// https://tools.hubteamqa.com/launch/scopes/2200
/** @deprecated use COMMERCE_SUBSCRIPTION_SCOPES instead */
export const COMMERCE_SUBSCRIPTION_WRITE_SCOPE = 'subscriptions-write';
export const COMMERCE_SUBSCRIPTION_SCOPES = Object.freeze({
  /** Write access to the SUBSCRIPTION CrmObject https://tools.hubteamqa.com/launch/scopes/2200/parcels */
  write: 'subscriptions-write',
  /** Read access to the SUBSCRIPTION CrmObject https://tools.hubteamqa.com/launch/scopes/2199/parcels */
  read: 'subscriptions-read',
  /** Access to the SUBSCRIPTION CrmObject https://tools.hubteamqa.com/launch/scopes/2201/parcels */
  access: 'subscriptions-access'
});