'use es6';

import { Record } from 'immutable';
const InboxSettingsRecord = Record({
  imapHost: '',
  imapPort: 993,
  smtpHost: '',
  smtpPort: 465,
  useStaticIps: false,
  acceptUntrustedCert: false,
  settingsSource: ''
}, 'InboxSettingsRecord');
InboxSettingsRecord.fromJS = json => {
  if (!json || typeof json !== 'object') {
    return json;
  }
  const inboxSettingsRecord = InboxSettingsRecord(json);
  return inboxSettingsRecord;
};
export default InboxSettingsRecord;