import { useState, useRef } from 'react';
import { useAsyncEffect } from 'react-utils/hooks/useAsyncEffect';
import Raven from 'raven-js';
export const useSubjectValue = (initialSubject = '') => {
  const [subjectValue, setSubjectValue] = useState('');
  const [isSubjectValueLoading, setIsSubjectValueLoading] = useState(true);
  const initialSubjectRef = useRef(initialSubject);
  useAsyncEffect(async () => {
    try {
      const loadedInitialSubject = typeof initialSubjectRef.current === 'string' ? initialSubjectRef.current : await initialSubjectRef.current();
      setSubjectValue(loadedInitialSubject);
    } catch (error) {
      Raven.captureException(error);
    } finally {
      setIsSubjectValueLoading(false);
    }
  }, []);
  return {
    subjectValue,
    setSubjectValue,
    isSubjectValueLoading
  };
};