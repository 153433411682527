import { getData } from 'conversations-async-data/async-data/operators/getters';
import { FEEDBACK_GENERIC_CHANNEL_ID } from 'conversations-thread-data/generic-channels/public/constants';
import { useCanReplyToChannels } from './useLatestMessageIdForChannel';
import { getGenericChannelsUsed, getUsedFirstMessageId, getUsedGenericChannelId } from 'conversations-thread-data/thread-details/public/operators';
import { getLatestChannelUsedForChannelIds } from '../../thread-histories/operators/getLatestChannelUsedForChannelIds';
import { useThreadDetails } from '../../thread-details-feature/public/hooks/useThreadDetails';

/**
 * @description Returns the firstMessageId from the most recent
 * genericChannelsUsed within ThreadDetails.
 */

export const useFirstMessageIdForChannel = ({
  canStartConversation,
  genericChannelId,
  threadId
}) => {
  const {
    asyncThreadDetails
  } = useThreadDetails({
    threadId
  });
  const threadDetails = getData(asyncThreadDetails);
  const canReplyToChannels = useCanReplyToChannels({
    canStartConversation,
    genericChannelId
  });
  const allowedChannelIds = genericChannelId ? canReplyToChannels.concat(genericChannelId) : canReplyToChannels;
  if (!genericChannelId) return null;
  let genericChannelsUsed = getGenericChannelsUsed(threadDetails);
  /*  @ts-expect-error Jun-5-2024, 19:34UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
  genericChannelsUsed = genericChannelsUsed.filter(channelUsed => getUsedGenericChannelId(channelUsed) !== FEEDBACK_GENERIC_CHANNEL_ID);
  const lastUsedGenericChannel = getLatestChannelUsedForChannelIds({
    /*  @ts-expect-error Jun-5-2024, 19:34UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
    genericChannelsUsed,
    channelIds: allowedChannelIds
  });
  return lastUsedGenericChannel && getUsedFirstMessageId(lastUsedGenericChannel) || null;
};