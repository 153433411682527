import http from 'hub-http/clients/apiClient';
import { mapProperties } from 'invoices-ui-lib/utils/property';
import { NON_STRIPE_PAYMENT_METHODS } from '../constants/payments';
export async function fetchAvailableManualPaymentMethods(currencyCode) {
  /**
   * TODO:
   * The commerce-payment-methods endpoint currently fails for currencies not supported by Stripe.
   * Until this endpoint is updated to handle non-Stripe currencies, we're returning a hard-coded list of payment methods for these cases.
   * Tracking BE issue: https://git.hubteam.com/HubSpot/Payments-Management-Team/issues/1206
   */
  try {
    await http.get(`payments/v1/limits/${currencyCode}/processor/BYO_STRIPE`);
  } catch (error) {
    // Checking if the currency is supported by Stripe. The endpoint will return 400 for unsupported currencies
    const responseJSON = error.responseJSON;
    if (responseJSON && responseJSON.category === 'VALIDATION_ERROR' && responseJSON.subCategory === 'PaymentsValidationError.INVALID_SESSION_CURRENCY_CODE') {
      return NON_STRIPE_PAYMENT_METHODS;
    }
    throw error;
  }
  return http.get(`/commerce-payment-methods/v1/${currencyCode}/all?validateProcessorSupportedCurrency=false`);
}
const createCommercePaymentServiceRequest = paymentRecord => Object.assign({}, paymentRecord, {
  associatedObjects: {
    COMMERCE_PAYMENT_TO_INVOICE: [Object.assign({}, paymentRecord.associatedObjects.COMMERCE_PAYMENT_TO_INVOICE[0], {
      properties: mapProperties(paymentRecord.associatedObjects.COMMERCE_PAYMENT_TO_INVOICE[0].properties)
    })],
    COMMERCE_PAYMENT_TO_CONTACT: [Object.assign({}, paymentRecord.associatedObjects.COMMERCE_PAYMENT_TO_CONTACT[0], {
      properties: mapProperties(paymentRecord.associatedObjects.COMMERCE_PAYMENT_TO_CONTACT[0].properties)
    })]
  },
  properties: mapProperties(paymentRecord.properties)
});
export function createCommercePaymentObject({
  commercePayment,
  lastEditDate,
  shouldSendReceipt
}) {
  const queryParams = new URLSearchParams();
  if (lastEditDate && shouldSendReceipt) {
    queryParams.set('lastEditDate', lastEditDate);
    queryParams.set('shouldSendReceipt', 'true');
  } else if (lastEditDate) {
    queryParams.set('lastEditDate', lastEditDate);
  } else if (shouldSendReceipt) {
    queryParams.set('shouldSendReceipt', 'true');
  }
  const queryParamsString = queryParams.toString().length ? `?${queryParams.toString()}` : '';
  return http.post(`invoices/v1/hubspot-payment/external-payment${queryParamsString}`, {
    data: createCommercePaymentServiceRequest(commercePayment)
  });
}
export function fetchCommercePaymentMethodTypeProperty() {
  return http.get('properties/v4/COMMERCE_PAYMENT/named/hs_payment_method_type');
}