export default async function retryUntilSuccess({
  promiseFn,
  successCondition,
  maxAttempts,
  delayMs
}) {
  let attempts = 0;
  while (attempts < maxAttempts) {
    try {
      const result = await promiseFn();
      if (successCondition(result)) {
        return result;
      }
    } catch (error) {
      console.warn(`Attempt ${attempts + 1} failed: ${error}`);
    }
    attempts++;
    if (attempts < maxAttempts) {
      await sleep(delayMs);
    }
  }
  throw new Error(`Failed to meet success condition or complete request after ${maxAttempts} attempts`);
}
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}