import styled from 'styled-components';
import { CALYPSO_LIGHT, HEFFALUMP, OLAF, PANTERA } from 'HubStyleTokens/colors';
import UIIconButton from 'UIComponents/button/UIIconButton';
export const ActionIconButton = styled(UIIconButton).withConfig({
  displayName: "ActionIconButton",
  componentId: "sc-5sa2gn-0"
})(["color:", " !important;background:", " !important;border:none !important;margin-left:0 !important;&:hover{background:", " !important;}"], ({
  selected
}) => selected ? OLAF : 'inherit', ({
  selected
}) => selected ? PANTERA : 'inherit', ({
  selected
}) => selected ? HEFFALUMP : CALYPSO_LIGHT);