'use es6';

import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
import { UnsavedChangesRecord } from './UnsavedChangesRecord';
import { register, update, unregister } from './EditableAreas';
import { universalEditableAreas } from './EditableAreas';
function UnsavedChanges({
  changes,
  children,
  errors,
  loading,
  onCancel,
  onSave,
  warnings,
  editableAreas = universalEditableAreas
}) {
  const editableAreaToken = useRef(null);
  useEffect(() => {
    editableAreaToken.current = register(editableAreas, UnsavedChangesRecord({
      changes,
      onCancel,
      onSave,
      loading,
      errors,
      warnings
    }));
    return () => {
      unregister(editableAreas, editableAreaToken.current);
    };
    // we only want this to run on changes  mount, thus the empty deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    update(editableAreas, editableAreaToken.current, UnsavedChangesRecord({
      changes,
      onCancel,
      onSave,
      loading,
      errors,
      warnings
    }));
    // we only want this to run when one of these fields is changed, running on change
    // to editableAreas is not intended and thus is excluded here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changes, errors, loading, onCancel, onSave, warnings]);
  return children;
}
UnsavedChanges.propTypes = {
  changes: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  loading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  warnings: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  editableAreas: PropTypes.object
};
export default UnsavedChanges;