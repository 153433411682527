import { OWNER } from '../constants/RoutingTypes';
import { setFallback } from '../utils/buildRoutingRules';
import RoutingRuleBase from './RoutingRuleBase';
import { FALLBACK } from '../constants/keyPaths';
import { getFallback } from '../operators/routingRulesGetters';
class OwnerRoutingRules extends RoutingRuleBase({
  '@type': OWNER,
  fallback: null
}, 'OwnerRoutingRules') {
  constructor(options) {
    super(options);
    return this.setIn(FALLBACK, getFallback(setFallback(this)));
  }
}
export const buildOwnerRoutingRules = options => new OwnerRoutingRules(options);
export default OwnerRoutingRules;