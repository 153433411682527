import { SMS_GENERIC_CHANNEL_ID, WHATSAPP_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
import { SHOW_TEMPLATE, UPGRADE_REQUIRED, DUPLICATE_THREAD, OPTED_OUT, CHANNEL_INSTANCE_DISABLED_OR_DELETED } from '../constants/disableEditorReason';
const getWADisabledReason = ({
  shouldDisableEditorPerMessageLimits,
  showTemplateSelector,
  hasDuplicateThread,
  channelInstanceDisabledOrDeleted
}) => {
  if (channelInstanceDisabledOrDeleted) {
    return CHANNEL_INSTANCE_DISABLED_OR_DELETED;
  } else if (shouldDisableEditorPerMessageLimits) {
    if (showTemplateSelector) {
      return SHOW_TEMPLATE;
    } else {
      return UPGRADE_REQUIRED;
    }
  } else if (hasDuplicateThread) {
    return DUPLICATE_THREAD;
  }
  return null;
};
const getSMSDisabledReason = ({
  isOptedOutOfMessages
}) => {
  if (isOptedOutOfMessages) {
    return OPTED_OUT;
  } else {
    return null;
  }
};
export const getDisabledReason = ({
  currentEditorKey,
  shouldDisableEditorPerMessageLimits,
  showTemplateSelector,
  hasDuplicateThread,
  isOptedOutOfMessages,
  channelInstanceDisabledOrDeleted
}) => {
  switch (currentEditorKey) {
    case WHATSAPP_GENERIC_CHANNEL_ID:
      return getWADisabledReason({
        shouldDisableEditorPerMessageLimits,
        showTemplateSelector,
        hasDuplicateThread,
        channelInstanceDisabledOrDeleted
      });
    case SMS_GENERIC_CHANNEL_ID:
      return getSMSDisabledReason({
        isOptedOutOfMessages
      });
    default:
      return null;
  }
};