import http from 'hub-http/clients/apiClient';
export const invoiceOnFileChargeValidationMessageSubCategories = {
  PARTIAL_PAYMENTS: 'PaymentsValidationError.PARTIAL_PAYMENTS_NOT_SUPPORTED_FOR_ON_FILE_CHECKOUT',
  NO_EMAIL_ADDRESS_FOR_CONTACT: 'PaymentsValidationError.NO_EMAIL_ADDRESS_FOR_CONTACT',
  NO_ACTIVE_PMOF_FOR_CONTACT: 'PaymentsValidationError.NO_ACTIVE_PMOF_FOR_CONTACT',
  PMOF_IS_NOT_CHARGEABLE: 'PaymentsValidationError.PMOF_IS_NOT_CHARGEABLE',
  INVALID_SESSION_CURRENCY_CODE: 'PaymentsValidationError.INVALID_SESSION_CURRENCY_CODE',
  MIN_TOTAL_NOT_REACHED: 'PaymentsValidationError.MIN_TOTAL_NOT_REACHED',
  MAX_TOTAL_EXCEEDED: 'PaymentsValidationError.MAX_TOTAL_EXCEEDED',
  INVALID_NUMBER_OF_ASSOCIATIONS: 'InvoiceAssociatedObjectValidationError.INVALID_NUMBER_OF_ASSOCIATION',
  ASSOCIATION_CHANGED: 'InvoiceAssociatedObjectValidationError.ASSOCIATION_CHANGED',
  CANNOT_CREATE_CHECKOUT_SESSION_WITH_COLLECTION_METHOD: 'InvoicePaymentValidationError.CANNOT_CREATE_CHECKOUT_SESSION_WITH_COLLECTION_METHOD'
};
export const getInvoiceOnFileChargeValidation = id => {
  return http.post(`invoices/v1/validation/${id}/on-file-charge`);
};