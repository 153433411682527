/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import { getInboxConnectPath } from '../utils/Links';
import { addQueryToUrl } from '../utils/UrlBuilders';
import defer from 'hs-promise-utils/defer';
import serviceTypes from '../constants/serviceTypes';
const OAUTHOR_NAMESPACE_CRM = 'crm.v2';
export const OAUTHOR_NAMESPACE_GMAIL = 'gmail';
export const OAUTHOR_NAMESPACE_GMAIL_WITH_CALENDAR = 'gmail-with-calendar';
let handleMessageCallback;
const _getMessageCallbackHandler = deferred => {
  return message => {
    const origin = message.origin || message.originalEvent.origin || '';
    if (origin.indexOf('getsidekick') === -1 && origin.indexOf('hubspot') === -1) {
      return;
    }
    const data = message.data || {};
    const type = data.type || '';
    if (type.indexOf('INBOX_CONNECT') === -1) {
      return;
    }
    if (data.error === true) {
      deferred.reject(data);
    } else {
      deferred.resolve(data);
    }
    window.removeEventListener('message', handleMessageCallback);
    handleMessageCallback = null;
  };
};
class ConnectInbox {
  constructor(connectInboxUrl, serviceType, legalPrompt) {
    this.connectInboxUrl = connectInboxUrl;
    this.serviceType = serviceType;
    this.legalPrompt = legalPrompt;
    if ('BroadcastChannel' in window) {
      this.channel = new BroadcastChannel('inbox_connect_channel');
    }
    if (this.connectInboxUrl === null) {
      console.error('"connectInboxUrl" is a required property on the "ConnectInbox" class.');
    }
    if (this.serviceType === null) {
      console.error('"serviceType" is a required property on the "ConnectInbox" class.');
    }
  }
  connect(portalId, requestGroup = 'CRM_INBOX_CONNECT') {
    return this.connectWithOptions({
      requestGroup,
      portalId,
      checkPrivy: false
    });
  }
  baseConnect(appNamespace, newWindow, portalId, isShared = false, urlOptions) {
    newWindow.location.href = this.getConnectInboxUrl(null, appNamespace, portalId, isShared, urlOptions);
    return this.waitForConnectCallback();
  }
  waitForConnectCallback() {
    const deferred = defer();
    handleMessageCallback = _getMessageCallbackHandler(deferred);
    window.addEventListener('message', handleMessageCallback, false);
    if (this.channel) {
      this.channel.onmessage = e => {
        handleMessageCallback(e);
        this.channel.close();
      };
    }
    return deferred.promise;
  }
  getOauthParams(options) {
    const inboxCreateContext = options.inboxCreateContext ? JSON.parse(options.inboxCreateContext) : {};
    if (this.serviceType === serviceTypes.gmail) {
      return {
        scopeNamespace: this.getGoogleScopeNamespace(inboxCreateContext)
      };
    }
    return {};
  }
  getGoogleScopeNamespace(inboxCreateContext) {
    if (inboxCreateContext && inboxCreateContext.scopeNamespace) {
      return inboxCreateContext.scopeNamespace;
    }
    if (this.shouldConnectCalendar(inboxCreateContext)) {
      return OAUTHOR_NAMESPACE_GMAIL_WITH_CALENDAR;
    }
    return OAUTHOR_NAMESPACE_GMAIL;
  }

  // TODO: this will eventually be removed and replaced by the scopeNamespace override
  shouldConnectCalendar(inboxCreateContext) {
    return inboxCreateContext && inboxCreateContext.connectCalendar === true;
  }
  getConnectInboxUrl(next, appNamespace, portalId, isShared = false, options = {}) {
    const oAuthorParams = this.getOauthParams(options);
    const inboxConnectParams = Object.assign({
      origin: window.location.origin,
      serviceType: this.serviceType,
      isShared
    }, options);
    if (portalId) {
      oAuthorParams.portalId = portalId;
      inboxConnectParams.portalId = portalId;
    }
    if (next) {
      inboxConnectParams.next = next;
    }
    const inboxConnectUrl = addQueryToUrl(getInboxConnectPath(portalId), inboxConnectParams);
    oAuthorParams.appendToFinal = inboxConnectUrl;
    return addQueryToUrl(`${this.connectInboxUrl}/${appNamespace || OAUTHOR_NAMESPACE_CRM}`, oAuthorParams);
  }
  connectWithOptions({
    requestGroup = 'CRM_INBOX_CONNECT',
    appNamespace,
    apiClient,
    portalId,
    isShared = false,
    useActionRedirect = false,
    reconnectEmail
  }) {
    return new Promise((resolve, reject) => {
      this.maybeShowPrivyPermissions(false, requestGroup, apiClient).then(newWindow => {
        this.baseConnect(appNamespace, newWindow, portalId, isShared, {
          useActionRedirect,
          reconnectEmail
        }).then(resolve, err => {
          if (newWindow.close) {
            newWindow.close();
          }
          reject(err);
        });
      }, reject);
    });
  }
  maybeShowPrivyPermissions(checkPrivy, requestGroup, apiClient) {
    return new Promise((resolve, reject) => {
      let newWindow = null;
      const promptProps = {
        allowRequestGroup: requestGroup,
        apiClient,
        onClick: () => {
          resolve(newWindow = window.open());
        }
      };
      const windowReject = error => {
        if (newWindow && newWindow.close) {
          newWindow.close();
        }
        reject(error);
      };
      this.legalPrompt(promptProps).then(resolve, windowReject);
    });
  }
}
export default ConnectInbox;