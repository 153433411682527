import Raven from 'raven-js';
import { MISSING_PERMISSION, INSUFFICIENT_JITA_PERMISSIONS } from 'conversations-inbox-lib/omnibus/public/constants';
import { fetchConnectedAccountsForInbox } from '../../connected-accounts/actions/fetchConnectedAccountsForInbox';
import { setLocation } from '../../routing/actions/setLocation';
import { getInboxes, getThreadId, getRequestErrorTypes, getCurrentInboxId as getOmnibusInboxId } from 'conversations-inbox-lib/omnibus/public/operators';
import { pollThreadLists } from '../../thread-list/actions/pollThreadLists';
import { areThreadIdsLooselyEqual } from '../../threads/operators/areThreadIdsLooselyEqual';
import { getRootUrl } from '../../urls/getRootUrl';
import { trackEvent } from '../../usage-tracking/clients/usageTracker';
import { getCurrentLocation } from '../../urls/getPath';
const trackPageView = () => {
  const inboxThreadIdRegex = /live-messages\/\d+\/inbox\/(\d+)/;
  const threadIdMatches = getCurrentLocation().match(inboxThreadIdRegex);
  if (threadIdMatches) {
    trackEvent({
      key: 'initial-threadid-pageview',
      properties: {}
    });
  } else {
    trackEvent({
      key: 'initial-pageview',
      properties: {}
    });
  }
};
export const setupWithOmnibus = action => dispatch => {
  const omnibusErrors = getRequestErrorTypes(action.payload.data) || [];
  if (omnibusErrors.includes(INSUFFICIENT_JITA_PERMISSIONS) || omnibusErrors.includes(MISSING_PERMISSION)) {
    return;
  }
  trackPageView();
  const inboxId = getOmnibusInboxId(action.payload.data);
  if (!inboxId) {
    return;
  }
  try {
    dispatch(fetchConnectedAccountsForInbox({
      inboxId
    }));
  } catch (e) {
    const omnibusInboxIds = getInboxes(action.payload.data).map(inbox => inbox.id);
    Raven.captureException(e, {
      extra: {
        omnibusInboxIds,
        currentInbox: getOmnibusInboxId(action.payload.data),
        requestArgs: action.payload.requestArgs
      }
    });
    throw e;
  }
  dispatch(pollThreadLists());
  const requestedThreadId = action.payload.requestArgs.threadId;
  const threadId = getThreadId(action.payload.data);
  if (threadId) {
    const url = `${getRootUrl()}/inbox/${threadId}`;
    dispatch(setLocation({
      location: url,
      preserveSearch: true,
      preserveHash: areThreadIdsLooselyEqual(requestedThreadId, threadId),
      replace: true
    }));
  }
};