'use es6';

import * as PromptableInboxConnectOptInFlowContainer from 'InboxConnectUtils/components/PromptableInboxConnectOptInFlowContainer';
import ConnectInbox from './lib/ConnectInbox';
import { OUTLOOK_365_OAUTHOR_BASE } from './utils/Links';
import serviceTypes from './constants/serviceTypes';
const _outlookLegalPrompt = PromptableInboxConnectOptInFlowContainer.outlook;
class ConnectOutlook365Inbox extends ConnectInbox {
  constructor() {
    super(OUTLOOK_365_OAUTHOR_BASE, serviceTypes.outlook, _outlookLegalPrompt);
  }
}
export default new ConnectOutlook365Inbox();