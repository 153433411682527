'use es6';

import text from 'I18n/utils/unescapedText';
import { getInboxErrorMessage } from './Validation';
const LANG_ROUTE = 'InboxConnectUtils.adminEmail';
const HTML_TAG_REGEX = /<[^>]+>/g;
export const generateAdminEmailLink = (status, extraErrorOptions) => {
  const error = getInboxErrorMessage(status);
  const lang = key => text(`${LANG_ROUTE}.${key}`);
  const errorMessage = text(error.key, Object.assign({}, error.options, extraErrorOptions)).replace(HTML_TAG_REGEX, '');
  const link = error.options.link || '';
  const messageBody = `${lang('greeting')}

${lang('preface')}

${status}

${errorMessage}
${link}

${lang('postface')}`;
  const subject = lang('subject');
  return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(messageBody)}`;
};