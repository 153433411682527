module.exports = {
  "invoiceInteraction": {
    "name": "invoice-interaction",
    "class": "interaction",
    "properties": {
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "Click copy link"
      ]
    },
    "namespace": "invoices-ui",
    "version": "1"
  },
  "invoiceUsage": {
    "name": "invoice-usage",
    "class": "usage",
    "properties": {
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "Click mark as paid",
        "Click void invoice",
        "Click delete invoice",
        "Click clone invoice",
        "Click view invoice",
        "Click view property history",
        "Click edit invoice",
        "Click send invoice",
        "Click download invoice",
        "Click charge payment method",
        "Update email reminders",
        "Add new payment",
        "Add existing payment"
      ],
      "salesTax": {
        "isOptional": true,
        "type": [
          "AUTO",
          "MANUAL",
          "NONE"
        ]
      }
    },
    "namespace": "invoices-ui",
    "version": "1"
  },
  "invoiceSendInteraction": {
    "name": "invoice-interaction",
    "class": "interaction",
    "properties": {
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "Set TO contact",
        "Add CC contact",
        "Remove CC contact",
        "Set From value"
      ]
    },
    "namespace": "invoices-ui",
    "version": "1"
  },
  "invoiceSendUsage": {
    "name": "invoice-usage",
    "class": "usage",
    "properties": {
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "Send invoice",
        "Write invoice manually"
      ],
      "numberOfToContacts": {
        "type": "number",
        "isOptional": true
      },
      "numberOfCcContacts": {
        "type": "number",
        "isOptional": true
      },
      "isDefault": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "invoices-ui",
    "version": "1"
  }
};