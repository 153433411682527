import { MAX_LINE_ITEM_QUANTITY } from '../../constants/CRM';
import { getDateDifference, getUTCDate } from '../../utils/dateTime';
import { LineItemConversionError } from './validationTypes';
export function getLineItemConversionValidation(object, supportedCurrencyCodes) {
  const validationErrors = new Set();
  const lineItemCurrencyCodes = new Set(object.lineItems.map(li => li.currencyCode));
  if (lineItemCurrencyCodes.size > 1) {
    validationErrors.add(LineItemConversionError.MULTIPLE_CURRENCIES);
  }

  // currency for line item is not supported or unset
  if ([...lineItemCurrencyCodes].some(code => !code || !supportedCurrencyCodes.has(code))) {
    validationErrors.add(LineItemConversionError.UNSUPPORTED_CURRENCY);
  }
  const today = getUTCDate();
  for (const li of object.lineItems) {
    if (li.startDate && getDateDifference(today, li.startDate) < 0) {
      validationErrors.add(LineItemConversionError.PAST_START_DATE);
    }
    // relative start date
    // https://tools.hubteam.com/puma/objects/LINE_ITEM/properties/hs_billing_start_delay_type/details/0
    if (li.startDateDelayType && (li.startDateDelayType === 'hs_billing_start_delay_days' || li.startDateDelayType === 'hs_billing_start_delay_months')) {
      validationErrors.add(LineItemConversionError.RELATIVE_START_DATE);
    }

    // future start date for one time Li (billingFrequency for one time is '' or null)
    if (!li.billingFrequency && li.startDate && getDateDifference(today, li.startDate) > 0) {
      validationErrors.add(LineItemConversionError.FUTURE_ONE_TIME_LINE_ITEM);
    }

    // fractional quantity
    if (li.quantity % 1 !== 0) {
      validationErrors.add(LineItemConversionError.FRACTIONAL_QUANTITY);
    }

    // large quantity
    if (li.quantity > MAX_LINE_ITEM_QUANTITY) {
      validationErrors.add(LineItemConversionError.LARGE_QUANTITY);
    }
  }
  return validationErrors;
}