'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["where_screen", "where_subscreen", "what_event_class"];
const TRACKING_NAMESPACE = 'inbox-connect-utils';
const transformHublyticsPayload = (hostApp, event, payload, metadata) => Object.assign({}, payload, {
  what_event: event,
  where_app_override: TRACKING_NAMESPACE,
  what_extra_json: Object.assign({}, metadata, {
    host: hostApp || ''
  })
});
const transformPayloadPropsUsageTracker = (hostApp, payload = {}, metadata = {}) => {
  const {
      where_screen,
      where_subscreen
    } = payload,
    rest = _objectWithoutPropertiesLoose(payload, _excluded); // eslint-disable-line no-unused-vars
  return Object.assign({
    host: hostApp,
    screen: where_screen,
    subscreen: where_subscreen
  }, rest, metadata);
};
export const trackingClientFromService = service => {
  const hublyticsWrapper = (event, payload = {}, metadata = {}) => service.track(transformHublyticsPayload(service.getAppName(), event, payload, metadata));
  let tracker = service.getTracker();
  if (tracker) {
    tracker = tracker.clone({
      properties: {
        namespace: TRACKING_NAMESPACE
      }
    });
  }
  const usageTrackerWrapper = (event, payload = {}, metadata = {}) => {
    tracker.track(event, transformPayloadPropsUsageTracker(service.getAppName(), payload, metadata));
  };
  const wrappedTracker = tracker ? usageTrackerWrapper : hublyticsWrapper;
  return {
    trackStepView: (stepKey, stepSubScreen = '', meta = '') => wrappedTracker('wizard-view', {
      where_screen: stepKey,
      where_subscreen: stepSubScreen,
      what_event_class: 'VIEW',
      what_value_str: meta
    }, {
      action: 'view'
    }),
    trackStepAction: (stepKey, action, {
      subAction = '',
      subScreen = '',
      value = ''
    } = {}) => wrappedTracker('wizard-interaction', {
      where_screen: stepKey,
      where_subscreen: subScreen,
      what_value_str: value,
      what_event_class: 'INTERACTION'
    }, {
      action,
      subAction
    }),
    trackInboxConnected: () => wrappedTracker('inbox-connected', {
      what_event_class: 'ACTIVATION'
    })
  };
};