module.exports = {
  "wizard-view": {
    "name": "wizard-view",
    "class": "view",
    "properties": {
      "action": "string",
      "what_value_str": {
        "type": "string",
        "isOptional": true
      },
      "wordpress_plugin": {
        "type": "string",
        "isOptional": true
      },
      "host": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "inbox-connect-utils"
  },
  "wizard-interaction": {
    "name": "wizard-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "what_value_str": {
        "type": "string",
        "isOptional": true
      },
      "wordpress_plugin": {
        "type": "string",
        "isOptional": true
      },
      "host": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "inbox-connect-utils"
  },
  "inbox-connected": {
    "name": "Inbox Connected",
    "class": "activation",
    "properties": {
      "host": {
        "type": "string"
      },
      "wordpress_plugin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "inbox-connect-utils"
  }
};