'use es6';

import { Record } from 'immutable';
const ExchangeInboxRecord = Record({
  acceptUntrustedCert: false,
  created: null,
  email: '',
  id: null,
  domain: '',
  password: '',
  exchangeUrl: '',
  updated: null,
  customUsername: ''
}, 'ExchangeInboxRecord');
ExchangeInboxRecord.fromJS = json => {
  if (!json || typeof json !== 'object') {
    return json;
  }
  const genericInbox = ExchangeInboxRecord(json);
  return genericInbox;
};
export default ExchangeInboxRecord;