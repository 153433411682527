'use es6';

import delay from 'hs-promise-utils/delay';
const LIMIT_EXCEEDED = 'LIMIT_EXCEEDED';
const FAILED = 'FAILED';
const OK = 'OK';
const CONNECTED = 'CONNECTED';
const FAKE_UI_DELAY = 2000;
const POLLING_PERIOD = 2000;
const POLLING_ATTEMPTS = 30;

// Not currently doing anything here by design
export const establishConnection = (_, emailAddress) => Promise.resolve(emailAddress).then(val => delay(val, FAKE_UI_DELAY));
export const sendTestEmail = (service, emailAddress) => Promise.resolve(service.createTestEmail(emailAddress)).then(val => delay(val, FAKE_UI_DELAY));
export const checkTestEmail = (service, testEmailPayload) => {
  if (testEmailPayload.latest_status === FAILED) {
    throw testEmailPayload.latest_error_type;
  }
  let trial = 0;
  const check = () => {
    if (trial++ === POLLING_ATTEMPTS) {
      throw LIMIT_EXCEEDED;
    }
    return delay(POLLING_PERIOD).then(() => service.queryTestEmail(testEmailPayload.email_guid)).then(resultPayload => {
      switch (resultPayload.latest_status) {
        case FAILED:
          throw resultPayload.latest_error_type;
        case OK:
          return CONNECTED;
        default:
          return check();
      }
    });
  };
  return check();
};