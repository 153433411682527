// EditNextPaymentDate will be removed once BillingWizard is ungated to all
export const SubscriptionActions = Object.freeze({
  Edit: 'edit',
  Cancel: 'cancel',
  UpdatePaymentMethod: 'update-payment-method',
  EditNextPaymentDate: 'edit-next-payment-date',
  EditNextBillingDate: 'edit-next-billing-date',
  Pause: 'pause',
  Delete: 'delete'
});
// https://tools.hubteam.com/puma/objects/SUBSCRIPTION/properties/hs_status/details
// click 'See more' under 'Property options' to see the possible values
export const SubscriptionStatus = Object.freeze({
  Active: 'active',
  PastDue: 'past_due',
  Unpaid: 'unpaid',
  Canceled: 'canceled',
  Expired: 'expired',
  Scheduled: 'scheduled',
  Paused: 'paused'
});

// https://tools.hubteam.com/puma/objects/COMMERCE_PAYMENT/properties/hs_latest_status/details/0
// click 'See more' under 'Property options' to see the possible values
export const PaymentStatus = Object.freeze({
  Processing: 'processing',
  Succeeded: 'succeeded',
  Failed: 'failed',
  PartiallyRefunded: 'partially_refunded',
  Refunded: 'refunded',
  ProcessingRefund: 'processing_refund'
});

// https://tools.hubteam.com/puma/objects/INVOICE/properties/hs_invoice_status/details/0
// click 'See more' under 'Property options' to see the possible values
export const InvoiceStatus = Object.freeze({
  Draft: 'draft',
  Open: 'open',
  Paid: 'paid',
  Voided: 'voided'
});

// https://tools.hubteam.com/puma/objects/SUBSCRIPTION/properties/hs_collection_process/details/0
// click 'See more' under 'Property options' to see the possible values
export const CollectionProcess = Object.freeze({
  AutomaticPayments: 'automatic_payments',
  ManualPayments: 'manual_payments'
});

// https://tools.hubteam.com/puma/objects/SUBSCRIPTION/properties/hs_invoice_creation/details/0
// click 'See more' under 'Property options' to see the possible values
export const InvoiceCreation = Object.freeze({
  On: 'on',
  Off: 'off'
});