import { useEffect, useState } from 'react';
import { useHasBillingReadScope } from '../../auth/hooks/useScopes';
import { useIsUngatedForUserAwareRoutingRules } from '../../auth/hooks/useGates';
import { getHasBillingRead } from '../operators/getHasBillingRead';
export const useShowFooter = user => {
  const [showFooter, setShowFooter] = useState(false);
  const isUngatedForUserAwareRoutingRules = useIsUngatedForUserAwareRoutingRules();
  const hasBillingReadScope = useHasBillingReadScope();
  const hasBillingRead = isUngatedForUserAwareRoutingRules ? hasBillingReadScope : getHasBillingRead(user);
  useEffect(() => {
    if (hasBillingRead) setShowFooter(hasBillingRead);
  }, [hasBillingRead]);
  const dismissFooter = () => setShowFooter(false);
  return {
    showFooter,
    dismissFooter
  };
};