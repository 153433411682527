'use es6';

import toJS from 'transmute/toJS';
import set from 'transmute/set';
import get from 'transmute/get';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
import { LogLevel } from 'conversations-error-reporting/error-reporting/constants/LogLevel';
import { ChannelChange, CHANNEL_CHANGE } from 'conversations-internal-pub-sub/channel-change/records/ChannelChange';
import { ASSIGNMENT_UPDATE } from 'conversations-message-history/assignment-update-message/constants/messageTypes';
import { COMMON_MESSAGE } from 'conversations-message-history/common-message-format/constants/messageTypes';
import { CONTACT_ASSOCIATION } from 'conversations-message-history/contact-association-message/constants/messageTypes';
import { CONTEXT_UPDATE } from 'conversations-message-history/context-update/constants/messageTypes';
import { CRM_OBJECT_LIFECYCLE_UPDATE } from 'conversations-message-history/crm-object-lifecycle-update/constants/messageTypes';
import { CRM_OBJECT_WORKFLOW_EMAIL_SENT } from 'conversations-message-history/crm-object-workflow-email-sent/constants/messageTypes';
import { CRM_SIDEBAR_UPDATE } from 'conversations-message-history/crm-sidebar-update-message/constants/messageTypes';
import { deserialize } from 'conversations-message-history/common-message/serializers/messageSerializer';
import { EMAIL_CAPTURE_PROMPT } from 'conversations-message-history/email-capture-prompt/constants/messageTypes';
import { EMAIL_CAPTURE_RESPONSE } from 'conversations-message-history/email-capture-response/constants/messageTypes';
import { FILTERED_CHANGE } from 'conversations-message-history/filtered-change-message/constants/messageTypes';
import { getType } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { INITIAL_MESSAGE } from 'conversations-message-history/initial-message/constants/messageType';
import { isAssignmentUpdateMessage } from 'conversations-message-history/assignment-update-message/operators/isAssignmentUpdateMessage';
import { isCommonMessageFormat } from 'conversations-message-history/common-message-format/operators/cmfComparators';
import { isContextUpdateMessage } from 'conversations-message-history/context-update/operators/isContextUpdateMessage';
import { isCrmObjectLifecycleUpdate } from 'conversations-message-history/crm-object-lifecycle-update/operators/isCrmObjectLifecycleUpdate';
import { isCrmSidebarUpdateMessage } from 'conversations-message-history/crm-sidebar-update-message/operators/isCrmSidebarUpdateMessage';
import { isEmailCapturePromptMessage } from 'conversations-message-history/email-capture-prompt/operators/isEmailCapturePromptMessage';
import { isEmailCaptureResponseMessage } from 'conversations-message-history/email-capture-response/operators/isEmailCaptureResponseMessage';
import { isFeedbackSurveyMessage } from 'conversations-message-history/feedback-survey-message/operators/isFeedbackSurveyMessage';
import { isFilteredChangeMessage } from 'conversations-message-history/filtered-change-message/operators/isFilteredChangeMessage';
import { isInboxUpdateMessage } from 'conversations-message-history/inbox-update-message/operators/isInboxUpdateMessage';
import { isInitialMessage } from 'conversations-message-history/initial-message/operators/isInitialMessage';
import { isMessagesUpdateMessage } from 'conversations-message-history/message-updates/operators/isMessagesUpdateMessage';
import { isOfficeHoursMessage } from 'conversations-message-history/office-hours-message/operators/isOfficeHoursMessage';
import { isReadThreadMessage } from 'conversations-message-history/read-thread-message/operators/isReadThreadMessage';
import { isThreadComment } from 'conversations-message-history/comment-message/operators/cmComparators';
import { isThreadStatusUpdateMessage } from 'conversations-message-history/thread-status-update/operators/isThreadStatusUpdateMessage';
import { isTypicalResponseTimeMessage } from 'conversations-message-history/typical-response-time/operators/isTypicalResponseTimeMessage';
import { isTypingMessage } from 'conversations-message-history/typing-indicator/operators/isTypingMessage';
import { isTranscriptSubmissionMessage } from 'conversations-message-history/transcript-submission/operators/isTranscriptSubmissionMessage';
import { isTicketCreationFailure } from 'conversations-message-history/ticket-creation-failure/operators/isTicketCreationFailure';
import { isThreadPausedOnGenericChannelMessage } from 'conversations-message-history/thread-paused-on-generic-channel-message/operators/isThreadPausedOnGenericChannelMessage';
import { MESSAGES_UPDATED } from 'conversations-message-history/message-updates/constants/messageTypes';
import { OFFICE_HOURS } from 'conversations-message-history/office-hours-message/constants/messageTypes';
import { READ_THREAD } from 'conversations-message-history/read-thread-message/constants/messageTypes';
import { THREAD_COMMENT } from 'conversations-message-history/comment-message/constants/messageTypes';
import { THREAD_INBOX_UPDATED } from 'conversations-message-history/inbox-update-message/constants/messageTypes';
import { THREAD_STATUS_UPDATE } from 'conversations-message-history/thread-status-update/constants/messageTypes';
import { TYPICAL_RESPONSE_TIME } from 'conversations-message-history/typical-response-time/constants/messageTypes';
import { FEEDBACK_SUBMISSION } from 'conversations-message-history/feedback-submission/constants/messageTypes';
import { FEEDBACK_SURVEY } from 'conversations-message-history/feedback-survey-message/constants/messageTypes';
import { TYPING } from 'conversations-message-history/typing-indicator/constants/messageTypes';
import { CHAT_TRANSCRIPT_SENT } from 'conversations-message-history/transcript-submission/constants/messageTypes';
import { REPLY_RECOMMENDATIONS_MESSAGE } from 'conversations-message-history/reply-recommendations/constants/messageTypes';
import { TICKET_CREATION_FAILURE } from 'conversations-message-history/ticket-creation-failure/constants/messageTypes';
import { WORKFLOW_GENERATED_USER_MESSAGE } from 'conversations-message-history/workflow-generated-user-message/constants/messageTypes';
import { THREAD_PAUSED_ON_GENERIC_CHANNEL } from 'conversations-message-history/thread-paused-on-generic-channel-message/constants/messageTypes';
import { MESSAGE_REFRESH, REFETCH_OVERSIZED_CONVERSATION_MESSAGE, REFETCH_CONVERSATION_MESSAGE } from '../constants/actionTypes';
import { validateMessageClientType } from '../../_internal/utils/validateMessageClientType';
import { messagesUpdated } from '../actions/messagesUpdated';
import { readReceiptReceived } from '../actions/readReceiptReceived';
import { historyMessageReceived } from '../actions/historyMessageReceived';
import { handleTypingMessage } from './handleTypingMessage';
import { threadStatusUpdated } from '../actions/threadStatusUpdated';
import { visitorsInvalidated } from '../../../realtime/public/actions';
import { channelChangeReceived } from '../actions/channelChangeReceived';
import { visitorEmailUpdated } from '../../../visitor/actions/visitorEmailUpdated';
import { handleMessageRefresh } from '../../../email-inline-images/public/actions/handleMessageRefresh';
import { getMessageIdToRefresh } from '../../../email-inline-images/public/operators/getMessageIdToRefresh';
import { getVisitorEmailfromThreadMessage } from '../../../thread-details/public/operators';
import { getMessageIdToRefetch } from '../../../email/public/operators/getMessageIdToRefetch';
import { fetchOversizedCommonMessage } from '../../../email/public/actions/fetchOversizedCommonMessage';
import { isCrmObjectWorkflowEmailSent } from 'conversations-message-history/crm-object-workflow-email-sent/operators/isCrmObjectWorkflowEmailSent';
import { replyRecommendationReceived } from '../actions/replyRecommendationReceived';
import { fetchCommonMessage } from '../actions/fetchCommonMessage';
import { setFriendlyNames } from '../../../friendly-names/public/actions';
const deserializeThreadMessage = (threadId, messageObj) => {
  if (getType(messageObj) === CHANNEL_CHANGE) return new ChannelChange(messageObj);
  let message = deserialize({
    json: messageObj
  });
  if (!get('threadId', message) && !isInitialMessage(message) && !isCommonMessageFormat(message) && !isCrmObjectLifecycleUpdate(message) && !isCrmObjectWorkflowEmailSent(message) && !isCrmSidebarUpdateMessage(message) && !isMessagesUpdateMessage(message) && !isThreadComment(message) && !isContextUpdateMessage(message) && !isTypingMessage(message) && !isReadThreadMessage(message) && !isInboxUpdateMessage(message) && !isEmailCaptureResponseMessage(message) && !isEmailCapturePromptMessage(message) && !isOfficeHoursMessage(message) && !isThreadStatusUpdateMessage(message) && !isFilteredChangeMessage(message) && !isTypicalResponseTimeMessage(message) && !isAssignmentUpdateMessage(message) && !isTranscriptSubmissionMessage(message) && !isFeedbackSurveyMessage(message) && !isTicketCreationFailure(message) && !isThreadPausedOnGenericChannelMessage(message)) {
    message = set('threadId', threadId, message);
  }
  return message;
};
export const handleThreadMessage = ({
  message: {
    data: messageObj,
    clientId
  },
  threadId,
  publishContext = {},
  refetchTickets = () => {},
  refetchThreadHistory = () => {},
  vid
}) => dispatch => {
  const message = deserializeThreadMessage(threadId, messageObj);
  validateMessageClientType({
    message,
    clientId
  });
  const type = getType(message);
  switch (type) {
    // Persisted thread history messages
    case ASSIGNMENT_UPDATE:
      {
        dispatch(historyMessageReceived({
          message,
          threadId
        }));
        break;
      }
    case COMMON_MESSAGE:
    case CONTEXT_UPDATE:
    case EMAIL_CAPTURE_PROMPT:
    case FEEDBACK_SUBMISSION:
    case FEEDBACK_SURVEY:
    case INITIAL_MESSAGE:
    case OFFICE_HOURS:
    case THREAD_COMMENT:
    case THREAD_INBOX_UPDATED:
    case CHAT_TRANSCRIPT_SENT:
    case TICKET_CREATION_FAILURE:
    case WORKFLOW_GENERATED_USER_MESSAGE:
    case CRM_OBJECT_WORKFLOW_EMAIL_SENT:
    case TYPICAL_RESPONSE_TIME:
    case THREAD_PAUSED_ON_GENERIC_CHANNEL:
      {
        dispatch(historyMessageReceived({
          message,
          threadId
        }));
        break;
      }
    case CRM_OBJECT_LIFECYCLE_UPDATE:
      {
        dispatch(historyMessageReceived({
          message,
          threadId
        }));
        refetchTickets();
        break;
      }
    case CRM_SIDEBAR_UPDATE:
      {
        refetchTickets();
        break;
      }
    case MESSAGE_REFRESH:
      {
        dispatch(handleMessageRefresh({
          messageId: getMessageIdToRefresh(message),
          threadId
        }));
        break;
      }
    case EMAIL_CAPTURE_RESPONSE:
      {
        dispatch(visitorEmailUpdated({
          threadId,
          visitorEmail: getVisitorEmailfromThreadMessage(message)
        }));
        dispatch(historyMessageReceived({
          message,
          threadId
        }));
        if (!publishContext.playback) {
          dispatch(visitorsInvalidated({
            threadId,
            vid
          }));
        }
        break;
      }

    // Application behavioral messages + data syncing
    case THREAD_STATUS_UPDATE:
      {
        dispatch(threadStatusUpdated(message, threadId));
        break;
      }
    case CHANNEL_CHANGE:
      {
        dispatch(channelChangeReceived({
          threadId,
          channelChange: message
        }));
        break;
      }
    case READ_THREAD:
      {
        dispatch(readReceiptReceived({
          message,
          threadId
        }));
        break;
      }
    case TYPING:
      {
        if (publishContext.playback) break;
        dispatch(handleTypingMessage({
          message
        }));
        break;
      }
    case MESSAGES_UPDATED:
      {
        dispatch(messagesUpdated({
          message,
          threadId
        }));
        break;
      }
    case FILTERED_CHANGE:
      {
        // noop
        break;
      }
    case REFETCH_OVERSIZED_CONVERSATION_MESSAGE:
      {
        dispatch(fetchOversizedCommonMessage({
          messageId: getMessageIdToRefetch(message),
          threadId
        }));
        break;
      }
    case CONTACT_ASSOCIATION:
      {
        if (!publishContext.playback) {
          const {
            newVid
          } = message;
          dispatch(visitorsInvalidated({
            threadId,
            vid: newVid
          }));
          dispatch(setFriendlyNames({
            threadId,
            friendlyNames: []
          }));
          refetchThreadHistory();
        }
        break;
      }
    case REPLY_RECOMMENDATIONS_MESSAGE:
      {
        dispatch(replyRecommendationReceived({
          message,
          threadId
        }));
        break;
      }
    case REFETCH_CONVERSATION_MESSAGE:
      {
        dispatch(fetchCommonMessage({
          messageId: message.conversationMessageId,
          threadId
        }));
        break;
      }
    default:
      {
        reportError({
          error: new Error(`UNHANDLED_PUBNUB_MESSAGE: ${getType(message)}`),
          extra: {
            message: toJS(message)
          },
          level: LogLevel.info
        });
        break;
      }
  }
};