import { Set as ImmutableSet } from 'immutable';
import { createSelector } from 'reselect';
import { CONVERSATION_SUMMARY, EMAIL_FORWARD_HISTORY, ESCALATION_PANEL, GRAPHQL_ASSIGNEES, INBOX_LONG_TASK_REPORT, POP_OUT_COMPOSER, REPLY_RECOMMENDATIONS, USER_DEFAULT_FONTS, WHATSAPP, CONTACT_PROMOTION, CALLING_NEXT_BEST_ACTIONS, HELP_DESK_ROUTING_SCOPE_FILTERING, AI_ASSISTANT, REPLY_TO_ALL, SYSTEM_MESSAGE_REDESIGN, CHATSPOT_WIDGET_BETA, AI_SETTINGS, NOTE_POWERED_COLLABORATION, IMPROVED_RECIPIENT_DISPLAY } from '../constants/GatesConstants';
import { gate as gateV3 } from 'hub-http/gates';
export const getGates = state => state.auth.gates;
export const getGatesSet = createSelector([getGates], gates => ImmutableSet(gates));
export const createIsUngatedForGate = gate => createSelector(getGates, gates => gates.includes(gate));
export const getIsUngatedForLongTaskReporting = createSelector([getGates], gates => gates.includes(INBOX_LONG_TASK_REPORT));
export const isUngatedForEscalations = createSelector([getGates], gates => gates.includes(ESCALATION_PANEL));
export const getIsUngatedForGraphQLAssignees = createIsUngatedForGate(GRAPHQL_ASSIGNEES);
export const getIsUngatedForEmailForwardHistory = createSelector(getGates, gates => gates.includes(EMAIL_FORWARD_HISTORY));
export const getIsUngatedForUserDefaultFonts = createSelector(getGates, gates => gates.includes(USER_DEFAULT_FONTS));
export const getIsWhatsApp = createSelector(getGates, gates => gates.includes(WHATSAPP));
export const getIsUngatedForConversationSummary = createSelector([getGates], gates => gates.includes(CONVERSATION_SUMMARY));
export const getIsUngatedForAiAssistant = createSelector([getGates], gates => gates.includes(AI_ASSISTANT));
export const getIsUngatedForPopOutComposer = createSelector([getGates], gates => gates.includes(POP_OUT_COMPOSER));
export const getIsUngatedForContactPromotions = createSelector([getGates], gates => gates.includes(CONTACT_PROMOTION));
export const getIsUngatedForReplyRecs = createSelector([getGates], gates => gates.includes(REPLY_RECOMMENDATIONS));
export const getIsUngatedForNextBestActions = createSelector([getGates], gates => gates.includes(CALLING_NEXT_BEST_ACTIONS));
export const getIsUngatedForHelpDeskRoutingScopeFiltering = createSelector([getGates], gates => gates.includes(HELP_DESK_ROUTING_SCOPE_FILTERING));
export const getIsUngatedForReplyToAllMessages = createSelector([getGates], gates => gates.includes(REPLY_TO_ALL));
export const getIsUngatedForSystemMessageRedesign = createSelector([getGates], gates => gates.includes(SYSTEM_MESSAGE_REDESIGN));
export const getIsUngatedForCopilotPreviews = createSelector([getGates], gates => gates.includes(gateV3('Calling:Rep:CopilotPreviewSummaries')));
export const getIsUngatedForChatspot = createSelector([getGates], gates => gates.includes(CHATSPOT_WIDGET_BETA));
export const getIsUngatedForBreezeRebranding = createSelector([getGates], gates => gates.includes(gateV3('BreezeAI:Rebranding')));
export const getIsUngatedForAiSettings = createSelector([getGates], gates => gates.includes(AI_SETTINGS));
export const getIsUngatedForNotePoweredCollaboration = createSelector([getGates], gates => gates.includes(NOTE_POWERED_COLLABORATION));
export const getIsUngatedForImprovedRecipients = createSelector([getGates], gates => gates.includes(IMPROVED_RECIPIENT_DISPLAY));