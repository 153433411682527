import { parseViewId } from '../../../views-schema/public';
import { useViewMembers } from '../thread-list-member/useViewMembers';
import { useSearchAndFilterViewMembers } from './useSearchAndFilterViewMembers';
const emptyFilterGroups = [];
export const useInboxViewMembers = ({
  filterGroups = emptyFilterGroups,
  inboxId,
  limit,
  searchQuery = '',
  skip = false,
  sortDirection = 'DESC',
  threadStatus,
  viewId
}) => {
  const {
    threadListId
  } = viewId ? parseViewId(viewId) : {
    threadListId: null
  };
  const isThreadList = !!threadListId;
  const viewMembersFromThreadList = useViewMembers({
    inboxId,
    limit,
    skip: skip || !isThreadList,
    sortDirection,
    threadStatus,
    viewId: viewId || ''
  });
  const viewMembersFromCustomView = useSearchAndFilterViewMembers({
    customViewId: viewId,
    filterGroups,
    inboxId: inboxId,
    searchQuery,
    skip: skip || isThreadList,
    sortDirection
  });
  return isThreadList ? viewMembersFromThreadList : viewMembersFromCustomView;
};