import text from 'I18n/utils/unescapedText';
import { GOOGLE_FREE, GOOGLE_APPS, OUTLOOK, OUTLOOK365, EXCHANGE, UNKNOWN_IMAP, HUBSPOT_HOSTED, UNKNOWN } from './EmailProviderTypes';
const createGetTranslatedName = facsimileType => () => text(`InboxConnectUtils.providers.name.${facsimileType}`);
export const GMAIL_LOGO_NAME = 'google-gmail';
export const OUTLOOK_LOGO_NAME = 'outlook';
export const EXCHANGE_LOGO_NAME = 'exchange';
export const gmail = {
  key: 'gmail',
  name: 'Gmail',
  getTranslatedName: createGetTranslatedName(GOOGLE_FREE),
  logo: GMAIL_LOGO_NAME,
  serviceType: 'google',
  iconName: 'google'
};
export const google_apps = {
  key: 'gmail',
  name: 'G Suite',
  getTranslatedName: createGetTranslatedName(GOOGLE_APPS),
  logo: GMAIL_LOGO_NAME,
  serviceType: 'google',
  iconName: 'google'
};
export const outlook = {
  key: 'outlook',
  name: 'Outlook',
  getTranslatedName: createGetTranslatedName(OUTLOOK),
  logo: OUTLOOK_LOGO_NAME,
  download: 'https://app.getsidekick.com/outlook/download'
};
export const outlook365 = {
  key: 'outlook365',
  name: 'Outlook 365',
  getTranslatedName: createGetTranslatedName(OUTLOOK365),
  logo: OUTLOOK_LOGO_NAME,
  serviceType: 'office365',
  iconName: 'office-365'
};
export const exchange = {
  key: 'exchange',
  name: 'Exchange',
  getTranslatedName: createGetTranslatedName(EXCHANGE),
  logo: EXCHANGE_LOGO_NAME,
  serviceType: 'exchange'
};
export const outlook_com = {
  key: 'outlook_com',
  name: 'Outlook.com',
  getTranslatedName: createGetTranslatedName(OUTLOOK),
  logo: OUTLOOK_LOGO_NAME
};
export const imap = {
  key: 'imap',
  name: 'IMAP+SMTP',
  getTranslatedName: createGetTranslatedName(UNKNOWN_IMAP)
};
export const hubspotHosted = {
  key: 'hubspotHosted',
  name: 'Hosted Email',
  getTranslatedName: createGetTranslatedName(HUBSPOT_HOSTED)
};
export const other = {
  key: 'other',
  name: 'other',
  getTranslatedName: createGetTranslatedName(UNKNOWN)
};