import Raven from 'raven-js';
import { useCallback, useEffect, useRef } from 'react';
import { Metrics } from '../../../metrics/protected/Metrics';
export function useStateReactionTime() {
  const editorRef = useRef(null);
  const lastTypeTime = useRef(null);
  useEffect(() => {
    var _editorRef$current;
    const editorContentElement = editorRef === null || editorRef === void 0 || (_editorRef$current = editorRef.current) === null || _editorRef$current === void 0 ? void 0 : _editorRef$current.querySelector('.ProseMirror');
    if (editorContentElement) {
      const setLastTypeTime = evt => {
        try {
          const latinCharsAndWhiteSpace = /^[-@./#&+\w\s\u00C0-\u00FF]*$/;
          const inputText = evt.data;
          if (lastTypeTime.current === null && inputText !== null && inputText !== void 0 && inputText.match(latinCharsAndWhiteSpace)) {
            lastTypeTime.current = performance.now();
          }
        } catch (e) {
          Raven.captureException(new Error('Error setting last type time in useStateReactionTime hook'), {
            extra: {
              error: e
            }
          });
        }
      };
      editorContentElement.addEventListener('textInput', setLastTypeTime);
      const deactivateIME = () => lastTypeTime.current = null;
      editorContentElement.addEventListener('compositionstart', deactivateIME);
      editorContentElement.addEventListener('compositionupdate', deactivateIME);
      editorContentElement.addEventListener('compositionend', deactivateIME);
      return function cleanupListeners() {
        try {
          editorContentElement.removeEventListener('textInput', setLastTypeTime);
          editorContentElement.removeEventListener('compositionstart', deactivateIME);
          editorContentElement.removeEventListener('compositionupdate', deactivateIME);
          editorContentElement.removeEventListener('compositionend', deactivateIME);
        } catch (e) {
          // this is just an attempt to cleanup, if it fails there's nothing to do
        }
      };
    }
  }, []);
  const trackReactionTimeOnChange = useCallback(() => {
    if (lastTypeTime.current !== null) {
      Metrics.timer('prosemirror-reaction-time').update(performance.now() - lastTypeTime.current);
      lastTypeTime.current = null;
    }
  }, []);
  return {
    editorRef,
    trackReactionTimeOnChange
  };
}