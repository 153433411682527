import { Record } from 'immutable';
export const Offset = Record({
  offsetId: 0,
  offsetTimestamp: null
}, 'Offset');
export const SortableOffset = Record({
  crmOffset: null,
  offsetId: 0,
  offsetTimestamp: null,
  primaryOffsetValue: null,
  secondaryOffsetValue: null
}, 'SortableOffset');