// For V3 gates: https://product.hubteam.com/docs/gating/next-gen/usage.html#frontend-usage
import { gate as v3Gate } from 'hub-http/gates';
import { usePermissionsContext } from '../context/PermissionsContext';

/**
 * Returns true if the current authed user has the provided gate
 */
export const useHasGate = gate => {
  const {
    gates
  } = usePermissionsContext();
  return gates.has(gate);
};
export const useHasDeleteSubscriptionM1Gate = () => useHasGate(v3Gate('Subscriptions:DeleteSubscriptionM1'));
export const useHasDeleteSubscriptionM2Gate = () => useHasGate(v3Gate('Subscriptions:DeleteSubscriptionM2'));