import { postInternalMessage } from '../initialize';
import { ADD_PHONE_NUMBER_HOST_MSG } from '../methods/callingSharedWorkerMessageTypes';
export function createAddPhoneNumberMessage(addPhoneNumberArguments) {
  return {
    data: addPhoneNumberArguments,
    type: ADD_PHONE_NUMBER_HOST_MSG
  };
}
export default function addPhoneNumberHandler(addPhoneNumberArguments) {
  const addPhoneNumberMessage = createAddPhoneNumberMessage(addPhoneNumberArguments);
  postInternalMessage(addPhoneNumberMessage);
}