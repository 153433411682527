'use es6';

import { stringify as stringifyQuery, parse as parseQuery } from 'hub-http/helpers/params';
import { parseUrl, buildUrl } from 'hub-http/helpers/url';
import { isValidHost, isExchangeAddress } from './Validation';
const baseEmailSettingsUrl = portalId => `/crm-settings-email/${portalId}/email`;
export const inboxConnectSettingsRedirect = (portalId, provider) => `${baseEmailSettingsUrl(portalId)}?inboxconnected=true&provider=${provider}`;
export const addQueryToUrl = (url, queryObj) => {
  const parsed = parseUrl(url);
  parsed.query = stringifyQuery(queryObj);
  return buildUrl(parsed);
};
export const deepParse = url => {
  const parsed = parseUrl(url);
  if (parsed.query) {
    parsed.query = parseQuery(parsed.query);
  }
  return parsed;
};
const SCHEME_PREFIX = /^https?:\/\//;
export const buildSuggestedExchangeUrl = baseUrl => {
  if (typeof baseUrl !== 'string' || isExchangeAddress(baseUrl)) {
    return null;
  }
  if (baseUrl && !SCHEME_PREFIX.test(baseUrl) && isValidHost(baseUrl)) {
    baseUrl = `https://${baseUrl}`;
  }
  const parsed = parseUrl(baseUrl);
  return parsed && parsed.hostname ? `https://${parsed.hostname}/EWS/Exchange.asmx` : null;
};