import memoize from 'transmute/memoize';
// @ts-expect-error module not typed
import EmailAddressPattern from 'PatternValidationJS/patterns/EmailAddress';
import { getInvoiceAssociatedObjectProperty } from './property';
export const isValidEmail = email => !!email && EmailAddressPattern.test(email);
export const isProcessorSupportedCurrency = currencyOption => currencyOption.isProcessorSupportedCurrency;
export const hasValidCurrencyInPortal = currencies => !!currencies.filter(isProcessorSupportedCurrency).length;
const MAXIMUM_SELECTED_CONTACTS = 10;
export const getValidationErrors = memoize((fromValues, isLoadingFromValues, selectedCcContacts, selectedToContacts, selectedFromValue, defaultEmail) => ({
  tooManyContacts: selectedCcContacts.length + selectedToContacts.length > MAXIMUM_SELECTED_CONTACTS,
  to: {
    invalidEmails: !selectedToContacts.every(contact => isValidEmail(getInvoiceAssociatedObjectProperty(contact, 'email'))),
    empty: !selectedToContacts.length
  },
  cc: {
    invalidEmails: !selectedCcContacts.every(contact => isValidEmail(getInvoiceAssociatedObjectProperty(contact, 'email')))
  },
  from: {
    defaultEmailDisabled: !!selectedFromValue && selectedFromValue.disabled && selectedFromValue.accountId === defaultEmail,
    empty: !isLoadingFromValues && fromValues.length === 0,
    userEmailDisabled: !!selectedFromValue && selectedFromValue.disabled && !selectedFromValue.shared,
    teamEmailDisabled: !!selectedFromValue && selectedFromValue.disabled && selectedFromValue.shared
  }
}));