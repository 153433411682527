import http from 'conversations-http/clients/http';
export const fetchSpamViewMembersService = ({
  limit = 100,
  offsetTimestamp,
  offsetId,
  sortDirection = 'DESC'
}) => {
  return http.put('threadlists/v1/members/page/spam-view-members-for-helpdesk', {
    data: {
      limit,
      offsetId,
      offsetTimestamp,
      sortDirection
    }
  });
};