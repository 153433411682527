import { THREAD_COMMENT } from 'conversations-message-history/comment-message/constants/messageTypes';
import { isGenericChannelId } from 'conversations-thread-data/channel-configuration/public/types';
import Raven from 'raven-js';
import { useContext } from 'react';
import { EditorCapabilitiesContext } from '../context/EditorCapabilitiesContext';
import { ALLOWED_FILE_TYPES } from 'conversations-internal-schema/file-metadata/constants/allowedFileTypes';
import { DEFAULT_MAX_FILE_ATTACHMENTS_SIZE_IN_BYTES, DEFAULT_MAX_FILE_SIZE_IN_BYTES } from 'conversations-thread-data/channel-configuration/public/constants';

// TODO: validate

const nonChannelEditorCapabilities = {
  UNSET: {
    allowInlineImages: false,
    allowMultipleRecipients: false,
    outgoingAttachmentTypes: [],
    richText: [],
    allowedFileAttachmentMimeTypes: [],
    maxFileAttachmentCount: 0,
    maxFileAttachmentSizeBytes: 0,
    maxTotalFileAttachmentSizeBytes: 0
  },
  [THREAD_COMMENT]: {
    allowInlineImages: true,
    allowMultipleRecipients: false,
    // TODO: validate
    outgoingAttachmentTypes: ['FILE'],
    richText: ['BLOCKQUOTE', 'BOLD', 'FONT_SIZE', 'FONT_STYLE', 'HYPERLINK', 'ITALIC', 'LISTS', 'TEXT_ALIGNMENT', 'TEXT_COLOR', 'TEXT_HIGHLIGHT_COLOR', 'UNDERLINE'],
    allowedFileAttachmentMimeTypes: ALLOWED_FILE_TYPES,
    maxFileAttachmentCount: 10,
    maxFileAttachmentSizeBytes: DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    maxTotalFileAttachmentSizeBytes: DEFAULT_MAX_FILE_ATTACHMENTS_SIZE_IN_BYTES
  }
};
export function useEditorCapability(key) {
  const {
    editorConfigKey,
    getChannelData
  } = useContext(EditorCapabilitiesContext);
  if (!isGenericChannelId(editorConfigKey)) {
    return nonChannelEditorCapabilities[editorConfigKey][key];
  }
  const channelData = getChannelData();
  const channelCapabilities = channelData.capabilities;
  if (!channelCapabilities) {
    Raven.capturePageEvent(`Channel capabilities missing for '${editorConfigKey}'`, {
      extra: {
        channelData
      }
    });
    return nonChannelEditorCapabilities.UNSET[key];
  }
  return channelCapabilities[key];
}