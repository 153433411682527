import PortalIdParser from 'PortalIdParser';
import { ALL_OPEN, ALL_CLOSED, ASSIGNED_TO_ME, UNASSIGNED, SENT, FILTERED, TRASH, EMAIL, MESSENGER, CHAT, ASSIGNED_TO_BOT, FORMS, UNASSIGNED_TICKETS, ASSIGNED_TO_ME_TICKETS, MY_FOLLOWED_TICKETS, MY_TEAM_OPEN_TICKETS, MY_MENTIONS_TICKETS, SENT_TICKETS, ALL_OPEN_TICKETS, ALL_CLOSED_TICKETS, CALLS, MY_TEAM_UNASSIGNED_TICKETS } from 'find-and-filter-data/views-schema/public';
/**
 * @description These are the top level views to render. This array lists out
 * which views to render as standard views and in what order.
 */
export const STANDARD_VIEWS = [UNASSIGNED, UNASSIGNED_TICKETS, ASSIGNED_TO_ME, ASSIGNED_TO_ME_TICKETS, MY_TEAM_UNASSIGNED_TICKETS, MY_TEAM_OPEN_TICKETS, ALL_OPEN, ALL_OPEN_TICKETS, MY_FOLLOWED_TICKETS, MY_MENTIONS_TICKETS, ALL_CLOSED_TICKETS, CHAT];
const isPortal53 = () => PortalIdParser.get() === 53;
const COLLAPSED_VIEWS_WITHOUT_BOTS = [EMAIL, MESSENGER, FORMS, CALLS, ALL_CLOSED, SENT, SENT_TICKETS, FILTERED, TRASH];

/**
 * @description These are the collapsed views to render. This array lists out
 * which views to render under the More dropdown.
 */
export const COLLAPSED_VIEWS = isPortal53() ? COLLAPSED_VIEWS_WITHOUT_BOTS : [ASSIGNED_TO_BOT, ...COLLAPSED_VIEWS_WITHOUT_BOTS];