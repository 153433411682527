import { List as ImmutableList } from 'immutable';
import get from 'transmute/get';
import { buildEmailMetadataForRecipients } from './buildEmailMetadataForRecipients';
import { buildInitialEmailRecipients } from 'conversations-message-history/common-message-format/operators/buildCommonMessageRecipients';
// @ts-expect-error module not typed

import Raven from 'raven-js';
function reportInconsistentRecipientsSandbox(recipientEmailMetadata, {
  asyncCommonMessage,
  threadDetails,
  visitorEmail,
  connectedAccounts
}) {
  try {
    var _window;
    // eslint-disable-next-line hs-react-native/no-web-globals
    if ((_window = window) !== null && _window !== void 0 && _window.location.pathname.includes('composer-ui-sandbox')) {
      if (recipientEmailMetadata) {
        var _get, _get2, _get3;
        const to = (_get = get('to', recipientEmailMetadata)) === null || _get === void 0 ? void 0 : _get.toJS();
        const cc = (_get2 = get('cc', recipientEmailMetadata)) === null || _get2 === void 0 ? void 0 : _get2.toJS();
        const bcc = (_get3 = get('bcc', recipientEmailMetadata)) === null || _get3 === void 0 ? void 0 : _get3.toJS();
        const hasCvTest = to.concat(cc).concat(bcc).some(email => email === null || email === void 0 ? void 0 : email.address.includes('cv-test'));
        if (hasCvTest) {
          Raven.captureException(new Error('Inconsistent email recipients in composer-ui-sandbox'), {
            extra: {
              recipientEmailMetadata: {
                to,
                cc,
                bcc
              },
              asyncCommonMessage: asyncCommonMessage === null || asyncCommonMessage === void 0 ? void 0 : asyncCommonMessage.toJS(),
              threadDetails: threadDetails === null || threadDetails === void 0 ? void 0 : threadDetails.toJS(),
              visitorEmail,
              connectedAccounts: connectedAccounts === null || connectedAccounts === void 0 ? void 0 : connectedAccounts.toJS()
            }
          });
        }
      }
    }
  } catch (e) {
    // don't care, this is just for tracking
  }
}
export const getInitialRecipientsForEmail = ({
  asyncCommonMessage,
  channelInstances,
  connectedAccounts,
  threadDetails,
  replyAll,
  visitorEmail,
  isForward
}) => {
  const recipientEmailMetadata = buildEmailMetadataForRecipients({
    channelInstances,
    connectedAccounts,
    asyncCommonMessage,
    threadDetails,
    replyAll,
    visitorEmail,
    isForward
  });
  reportInconsistentRecipientsSandbox(recipientEmailMetadata, {
    asyncCommonMessage,
    threadDetails,
    visitorEmail,
    connectedAccounts
  });
  return buildInitialEmailRecipients({
    to: recipientEmailMetadata && get('to', recipientEmailMetadata) || ImmutableList(),
    cc: recipientEmailMetadata && get('cc', recipientEmailMetadata) || ImmutableList(),
    bcc: recipientEmailMetadata && get('bcc', recipientEmailMetadata) || ImmutableList()
  }).toList();
};