import { useEffect, useState } from 'react';
import { createClient } from 'laboratory-lib';
import { TREATMENT_TOOL0011 } from 'invoices-ui-lib/constants/treatments';
import PortalIdParser from 'PortalIdParser';
import experiments from '../../experiments.yaml';
export const laboratoryClient = createClient({
  name: 'invoices-highlight-card-laboratory',
  experiments: experiments,
  identifiers: {
    portalId: PortalIdParser.get()
  }
});
export const useExperimentTreatment = (experimentKey, parameterKey) => {
  const [treatment, setTreatment] = useState();
  useEffect(() => {
    laboratoryClient.resolve(treatments => {
      const parameterOption = treatments[experimentKey]['parameters'][parameterKey];
      setTreatment(parameterOption);
    });
  }, [experimentKey, parameterKey]);
  const isLoading = typeof treatment !== 'string';
  return [treatment, isLoading];
};
export const useTool0011Treatment = () => {
  const [treatment, isLoading] = useExperimentTreatment(TREATMENT_TOOL0011, 'group');
  const isVariation = treatment === 'variation';
  return {
    isVariation,
    isLoading
  };
};