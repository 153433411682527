import { useEffect, useState } from 'react';
import { checkNetwork } from 'ably-hubspot-js/network/clients/checkNetwork';
import enviro from 'enviro';
export const useCheckNetwork = () => {
  const [isOnline, setIsOnline] = useState(false);
  const isDeployed = enviro.deployed();
  useEffect(() => {
    if (isDeployed) {
      checkNetwork().then(({
        online,
        error
      }) => {
        if (error && error.status === 200 && error.message.includes('CORS')) {
          setIsOnline(true);
        } else {
          setIsOnline(online);
        }
      }).catch(e => {
        void e;
      });
    }
  });
  return isOnline;
};