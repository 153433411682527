// adapted from: https://git.hubteam.com/HubSpot/conversations-internal-schema/blob/24c061ac8fe4f3aee9b0a5f69a2d760a19563fd5/static/js/routing-rules/operators/routingRulesGetters.js
import { List as ImmutableList } from 'immutable';
import getIn from 'transmute/getIn';
import { KEEP_ASSIGNEE, ROUTING_RULE_TYPES } from '../constants/RoutingTypes';
import { BOT_ID, DISTRIBUTION, FALLBACK, TEAM_IDS, TYPE, USER_IDS } from '../constants/keyPaths';
export const getBotId = getIn(BOT_ID);
export const getDistribution = getIn(DISTRIBUTION);
export const getFallback = getIn(FALLBACK);
export const getRotationMethod = getDistribution;
export const getTeamIds = getIn(TEAM_IDS);
export const getType = getIn(TYPE);
export const getUserIds = getIn(USER_IDS);
export function checkIsRoutingRulesType(routingRules, type) {
  return getType(routingRules) === type;
}
export const checkIsRoutingRuleConfig = routingRules => typeof routingRules !== 'string' && !!getType(routingRules);

// this type guard is more explicit for KEEP_ASSIGNEE than getIsRoutingRuleConfig
export const checkIsKeepAssignee = routingRules => routingRules === KEEP_ASSIGNEE;
export const checkCanHaveFallback = routingRules => {
  return checkIsRoutingRuleConfig(routingRules) && (checkIsRoutingRulesType(routingRules, ROUTING_RULE_TYPES.AI_CHATBOT) || checkIsRoutingRulesType(routingRules, ROUTING_RULE_TYPES.OWNER));
};
export const getFallbackType = routingRules => getType(getFallback(routingRules));
export const checkHasUsersAndTeams = routingRules => {
  if (checkCanHaveFallback(routingRules)) {
    return getFallbackType(routingRules) === ROUTING_RULE_TYPES.USERS_AND_TEAMS;
  }
  return checkIsRoutingRulesType(routingRules, ROUTING_RULE_TYPES.USERS_AND_TEAMS);
};
export const getUsersAndTeams = routingRules => {
  const fallback = getFallback(routingRules);
  let rules = routingRules;
  if (checkIsRoutingRuleConfig(fallback) && checkIsRoutingRulesType(fallback, ROUTING_RULE_TYPES.USERS_AND_TEAMS)) {
    rules = fallback;
  }
  return {
    teamIds: getTeamIds(rules) || ImmutableList(),
    userIds: getUserIds(rules) || ImmutableList()
  };
};