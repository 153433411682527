import { DETECT_PROVIDER_STEP, CHOOSE_PROVIDER_STEP, FAILURE_STEP, TEST_MESSAGE_STEP, FINISH_STEP, EXIT_SUCCESS, CONNECT_INBOX_STEP, OPT_INTO_AUTOMATION_STEP } from '../constants/InboxConnectStepKeys';
export const getDefaultStartKey = (allowAutoDetectFlow, skipChoose, showInboxAutomationStep, serviceHasAIAutomation) => {
  if (skipChoose) {
    if (showInboxAutomationStep && serviceHasAIAutomation) {
      return OPT_INTO_AUTOMATION_STEP;
    } else {
      return CONNECT_INBOX_STEP;
    }
  }
  return allowAutoDetectFlow ? DETECT_PROVIDER_STEP : CHOOSE_PROVIDER_STEP;
};
export const connectActionToState = connectAction => {
  const action = JSON.parse(atob(connectAction));
  const {
    error,
    payload,
    passthrough
  } = action;
  if (error) {
    const {
      message,
      emailAddress
    } = payload || {};
    return {
      connectError: message || '0',
      shownStep: FAILURE_STEP,
      emailAddress,
      selectedAutomation: passthrough.selectedAutomation
    };
  }
  const {
    emailAddress,
    serviceType
  } = payload;
  return {
    emailAddress,
    connectedAccount: payload,
    shownStep: TEST_MESSAGE_STEP,
    selectedClient: serviceType,
    selectedAutomation: passthrough.selectedAutomation
  };
};
export const calcNextStep = (requestedStep, {
  allowAutoDetectFlow,
  provider,
  showFinishStep,
  showInboxAutomationStep,
  showCustomInboxAutomationStep,
  serviceHasAIAutomation
}) => {
  let nextStepKey = requestedStep;
  if (nextStepKey === DETECT_PROVIDER_STEP) {
    nextStepKey = getDefaultStartKey(allowAutoDetectFlow, !!provider, showInboxAutomationStep, showCustomInboxAutomationStep);
  }
  if (nextStepKey === OPT_INTO_AUTOMATION_STEP) {
    if (!showInboxAutomationStep || !serviceHasAIAutomation) {
      nextStepKey = CONNECT_INBOX_STEP;
    }
  }
  if (nextStepKey === CHOOSE_PROVIDER_STEP && !!provider) {
    if (showInboxAutomationStep && serviceHasAIAutomation) {
      nextStepKey = OPT_INTO_AUTOMATION_STEP;
    } else {
      nextStepKey = CONNECT_INBOX_STEP;
    }
  }
  if (nextStepKey === FINISH_STEP && !showFinishStep) {
    nextStepKey = EXIT_SUCCESS;
  }
  return nextStepKey;
};