import { useMemo } from 'react';
import emptyFunction from 'react-utils/emptyFunction';
import { useAuth } from '../hooks/useAuth';
import { getTracker } from '../tracking/subscriptionTracker';
const tracker = getTracker('crm-sidebar');
const uninitializedAuthFallback = {
  trackUsage: __action => emptyFunction(),
  trackInteraction: __action => emptyFunction(),
  trackBillingDateChange: __event => emptyFunction(),
  trackPauseChange: __event => emptyFunction(),
  trackDeletion: __event => emptyFunction()
};
export function useTracker() {
  const auth = useAuth();
  return useMemo(() => {
    if (!auth.user || !auth.paymentsEligibility) {
      return uninitializedAuthFallback;
    }
    const {
      paymentsEligibility: {
        processorType
      }
    } = auth;
    return {
      trackUsage: action => trackUsage(action, processorType),
      trackInteraction: action => trackInteraction(action, processorType),
      trackBillingDateChange: event => tracker('subscriptionChange', Object.assign({}, event, {
        type: 'merchant-usage',
        paymentType: processorType
      })),
      trackPauseChange: event => tracker('subscriptionChange', Object.assign({}, event, {
        type: 'merchant-usage',
        paymentType: processorType
      })),
      trackDeletion: event => tracker('subscriptionDeleted', Object.assign({}, event, {
        type: 'merchant-usage',
        subType: 'delete'
      }))
    };
  }, [auth]);
}
function trackUsage(action, paymentType) {
  tracker('usageEvent', {
    type: 'merchant-usage',
    subType: 'highlight-card',
    action,
    paymentType
  });
}
function trackInteraction(action, paymentType) {
  tracker('interactionEvent', {
    type: 'merchant-interaction',
    subType: 'highlight-card',
    action,
    paymentType
  });
}