import { createSelectedCallProviderSettingMessage } from 'calling-global-api/lib/internalEventMessageCreators';
import { postInternalMessage } from '../initialize';
import { triggerHostMessageSetCallingProviderSettings } from '../triggerHostMessage/triggerHostMessageSetCallingProviderSettings';
export default function setCallingProvidersSetting(callingProviderSettings) {
  const message = createSelectedCallProviderSettingMessage({
    callingProvider: callingProviderSettings.callingProvider && callingProviderSettings.callingProvider.toJS(),
    fromNumber: callingProviderSettings.fromNumber && callingProviderSettings.fromNumber.toJS()
  });
  // to widget via iframe
  postInternalMessage(message);

  // to event listener on host
  triggerHostMessageSetCallingProviderSettings(callingProviderSettings);
}