import indexBy from 'transmute/indexBy';
import get from 'transmute/get';
import { useCallback, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isSucceeded } from 'conversations-async-data/async-data/operators/statusComparators';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { usePubSubClient } from 'conversations-internal-pub-sub/redux/hooks/usePubSubClient';
import { isConnected } from 'conversations-internal-pub-sub/redux/operators/pubSubStatusComparators';
// TODO: this is deprecated, but the suggested alternative is in `conversations-inbox-lib` which is not a dep for `conversations-thread-view`
import { useAssociatedTickets } from 'svh-tickets-lib/tickets/public/hooks';
import { channelCapabilityValidator } from 'conversations-thread-data/pubsub/public/utils';
import { getAgentToAgentChannelName, getAgentToVisitorChannelName, getCrmConversationId } from 'conversations-thread-data/thread-details/public/operators';
// @ts-expect-error module not typed

// @ts-expect-error module not typed
import { handleThreadMessage } from 'conversations-thread-data/thread-view-realtime/public/operators/handleThreadMessage';
import { presenceUpdated } from 'conversations-thread-data/presence/public/actions';
import { getIsUngatedForGraphQLAssignees, getIsUngatedForNotePoweredCollaboration } from 'conversations-thread-data/auth/public/selectors';
import { VendorResolverContext } from 'conversations-thread-data/pubsub/public/context';
// @ts-expect-error module not typed
import { fetchMessageHistory } from '../../../thread-histories/actions/fetchMessageHistory';
import { handleTicketMessage } from 'conversations-thread-data/thread-view-realtime/public/operators/handleTicketMessage';
import { buildTicketPubsubChannel } from '../utils/buildTicketPubsubChannel';
const clientOptions = {
  channelCapabilityValidator
};
export const useThreadViewRealtime = ({
  inboxId: currentInboxId,
  asyncThreadDetails,
  threadChannels,
  threadId,
  vid,
  enableThreadPresence = false
}) => {
  // create a referentially stable promise that resolves when
  // the vendor is defined. This allows the app to call this hook
  // before the vendor has been defined.

  const {
    resolveVendor
  } = useContext(VendorResolverContext);

  // request the pubsub client
  const {
    asyncPubSubClient,
    updateSubscriptions
  } = usePubSubClient({
    resolveVendor,
    clientOptions
  });

  // update subscriptions when asyncPubSubClient, or asyncThreadDetails change.
  // The other deps here are (relatively speaking) stable.
  const dispatch = useDispatch();
  const threadDetails = isSucceeded(asyncThreadDetails) && getData(asyncThreadDetails);
  const crmConversationId = getCrmConversationId(threadDetails);
  const isUngatedForGraphQLAssignees = useSelector(getIsUngatedForGraphQLAssignees);
  const isUngatedForNoteCommenting = useSelector(getIsUngatedForNotePoweredCollaboration);
  const {
    refetchTickets: requestTicketsFn,
    associatedTicket
  } = useAssociatedTickets({
    conversationId: crmConversationId || 0,
    deferred: !crmConversationId
  });
  const ticketId = associatedTicket ? associatedTicket.id : 0;
  const ticketChannel = isUngatedForNoteCommenting ? buildTicketPubsubChannel({
    ticketId
  }) : '';
  const refetchTickets = useCallback(requestTicketsFn, [crmConversationId, requestTicketsFn]);
  const refetchThreadHistory = useCallback(() => dispatch(fetchMessageHistory({
    threadId
  })), [dispatch, threadId]);
  useEffect(() => {
    if (isConnected(asyncPubSubClient) && (threadChannels || threadDetails)) {
      const channelSource = threadChannels && threadChannels.length ?
      // @ts-expect-error indexBy is not typed well
      {
        channels: indexBy(get('type'), threadChannels)
      } : threadDetails;
      const publicThreadChannel = getAgentToVisitorChannelName(channelSource);
      const privateThreadChannel = getAgentToAgentChannelName(channelSource);

      //Pubsub 40160 errors are thrown when a pubsub channel is attempting to
      //attach to a different inbox than the current one.
      //This can happen when someone moves a thread that they are focused on
      //to another inbox.
      //So only update subscriptions if the desired inbox is the current inbox.
      const publicChannelInboxId = publicThreadChannel ? Number(publicThreadChannel.split(':')[5]) : null;
      const privateChannelInboxId = privateThreadChannel ? Number(privateThreadChannel.split(':')[5]) : null;
      if (currentInboxId && (publicChannelInboxId !== currentInboxId || privateChannelInboxId !== currentInboxId)) {
        return;
      }
      const subscriptions = {
        /*  @ts-expect-error Jun-5-2024, 19:34UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
        [publicThreadChannel]: {
          onMessage: (message, publishContext) => dispatch(handleThreadMessage({
            threadId,
            message,
            publishContext,
            refetchTickets,
            refetchThreadHistory,
            vid
          })),
          onPresence: enableThreadPresence ? message => dispatch(presenceUpdated({
            threadId,
            message
          })) : undefined
        },
        /*  @ts-expect-error Jun-5-2024, 19:34UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
        [privateThreadChannel]: {
          onMessage: (message, publishContext) => dispatch(handleThreadMessage({
            threadId,
            message,
            publishContext,
            refetchTickets,
            refetchThreadHistory,
            vid
          }))
        }
      };
      if (ticketChannel) {
        Object.assign(subscriptions, {
          [ticketChannel]: {
            onMessage: message => dispatch(handleTicketMessage({
              message,
              threadId
            }))
          }
        });
      }
      updateSubscriptions(subscriptions);
    }
  }, [asyncPubSubClient, asyncThreadDetails, currentInboxId, dispatch, enableThreadPresence, isUngatedForGraphQLAssignees, refetchTickets, refetchThreadHistory, threadChannels, threadDetails, threadId, updateSubscriptions, vid, ticketChannel]);
};