import { TwilioDeviceStatus } from '../constants/TwilioDeviceStatus';
export const getCallStatus = widgetDetails => widgetDetails.callStatus;
export const getActiveCallDetails = widgetDetails => widgetDetails.activeCallDetails;
export const getDeviceError = widgetDetails => widgetDetails.deviceErrorCode;
export const getStartCallError = widgetDetails => widgetDetails.startCallError;
export const getCallSettings = widgetDetails => {
  return {
    connectFromNumbers: widgetDetails.connectFromNumbers,
    externalFromNumbers: widgetDetails.externalFromNumbers,
    portalFromNumbers: widgetDetails.portalFromNumbers,
    hasCallingAccess: widgetDetails.hasCallingAccess,
    isHubSpotCallingEnabled: widgetDetails.isHubSpotCallingEnabled,
    isRecordingEnabled: widgetDetails.isRecordingEnabled
  };
};
export const getStandaloneConnectionId = widgetDetails => widgetDetails.standaloneConnectionId;
export const getCriticalFailureReason = widgetDetails => widgetDetails.criticalFailureReason;
export const getCallsV1SettingsUsage = widgetDetails => widgetDetails.callsV1SettingsUsage;
export const getTransferInformation = widgetDetails => widgetDetails && widgetDetails.transferInformation;
export const getTwilioDeviceStatus = widgetDetails => widgetDetails && widgetDetails.twilioDeviceStatus;
export const isTwilioDeviceRegistering = widgetDetails => Boolean(widgetDetails && getTwilioDeviceStatus(widgetDetails) === TwilioDeviceStatus.REGISTERING);
export const isTwilioDeviceUnregistered = widgetDetails => Boolean(widgetDetails && getTwilioDeviceStatus(widgetDetails) === TwilioDeviceStatus.UNREGISTERED);