import Raven from 'raven-js';
import { AggregateDataReporter } from '../AggregateDataReporter';
import { logAggregatedData } from '../../error-reporting/keplerAlerting';
export function buildAggregateReporter(Reporter = AggregateDataReporter) {
  return new Reporter(data => {
    try {
      Raven.capturePageEvent('realtime-stats', {
        extra: data
      });
      Object.entries(data).forEach(([metric, value]) => logAggregatedData(metric, value));
    } catch (e) {
      // do nothing - this tracking is non-essential
    }
  });
}