import { SET_BROWSER_HISTORY } from '../constants/EventListeners';
export default function handleSetBrowserHistory(history) {
  // Send back to host page

  if (window.hsCalling && window.hsCalling.triggerEvent) window.hsCalling.triggerEvent({
    event: SET_BROWSER_HISTORY,
    data: {
      history
    }
  });
}