import { useDataFetchingClient } from 'data-fetching-client';
import { useAblyChannel } from '../../pubsub/hooks/useAblyChannel';
import { getCurrentPortalChannel } from '../../pubsub/utils';
import { AWAY_MODE_CHANGE } from '../constants';
import { updateAgentStatusFromRealtimeMessage } from '../operators/updateAgentStatusFromRealtimeMessage';
import { GET_AGENT_STATUS } from '../queries/agentStatusQueries';
import Raven from 'raven-js';
import { useCallback, useRef } from 'react';

// This hook is meant to be used alongside useAgentStatus, or with fetch calls to the agent-status endpoint
export const useRealtimeAgentStatus = (agentId, workspace) => {
  const latestUpdateTimestampRef = useRef(0);
  const portalChannel = getCurrentPortalChannel();
  const dfcClient = useDataFetchingClient();
  const onMessage = useCallback(message => {
    const lastUpdated = latestUpdateTimestampRef.current;
    const isLatestMessage = message.timestamp > lastUpdated;
    const isCurrentAgent = agentId === message.agentId;
    if (isCurrentAgent && isLatestMessage) {
      latestUpdateTimestampRef.current = message.timestamp;
      dfcClient.refetchQueries({
        include: [GET_AGENT_STATUS]
      }).catch(e => {
        Raven.captureException(e);
        updateAgentStatusFromRealtimeMessage({
          client: dfcClient,
          message,
          workspace
        }); // Fallback to updating the cache directly
      });
    }
  }, [dfcClient, agentId, workspace]);
  const onReconnect = useCallback(() => {
    dfcClient.refetchQueries({
      include: [GET_AGENT_STATUS]
    }).catch(e => Raven.captureException(e));
  }, [dfcClient]);
  const onPlayback = useCallback(messages => {
    messages.forEach(onMessage);
  }, [onMessage]);
  useAblyChannel({
    channel: portalChannel,
    type: AWAY_MODE_CHANGE,
    onMessage,
    onPlayback,
    onReconnect
  });
};