import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["objectTypeId", "objectId"],
  _excluded2 = ["fromObjectId", "fromObjectTypeId", "toObjectTypeId"];
import { isRawEngagmentCreationMessage } from './engagmentAsyncMessageIdentifiers';
import { ENGAGEMENT_DETAILS_MESSAGE } from '../constants/engagementDetailsMessageType';
import EngagementDetailsRecord from '../records/EngagementDetailsRecord';
import { fromJS } from 'immutable';
import { engagementTypeIdToTypeMap } from '../constants/engagementTypes';
const convertRawUpdateMessage = message => {
  const {
      objectTypeId,
      objectId
    } = message,
    rest = _objectWithoutPropertiesLoose(message, _excluded);
  return Object.assign({}, rest, {
    engagement: {
      type: engagementTypeIdToTypeMap[objectTypeId],
      id: objectId
    },
    ['@type']: ENGAGEMENT_DETAILS_MESSAGE,
    isAsync: true,
    id: objectId.toString()
  });
};
const convertRawCreationMessage = message => {
  const {
      fromObjectId,
      fromObjectTypeId
    } = message,
    rest = _objectWithoutPropertiesLoose(message, _excluded2);
  return Object.assign({}, rest, {
    engagement: {
      type: engagementTypeIdToTypeMap[fromObjectTypeId],
      id: fromObjectId
    },
    ['@type']: ENGAGEMENT_DETAILS_MESSAGE,
    isAsync: true,
    id: fromObjectId.toString()
  });
};
export const buildEngagementDetailsMessageFromAsync = attrs => {
  const detailsMessage = isRawEngagmentCreationMessage(attrs) ? convertRawCreationMessage(attrs) : convertRawUpdateMessage(attrs);
  return EngagementDetailsRecord(fromJS(detailsMessage));
};