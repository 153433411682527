import { useEffect, useState } from 'react';
import Raven from 'raven-js';
import { getInvoiceOnFileChargeValidation } from '../api/validationsAPI';
function useGetOnFileChargeValidation({
  invoiceId,
  skip
}) {
  const [loading, setLoading] = useState(false);
  const [onFileChargeValidationData, setOnFileChargeValidationData] = useState();
  const resetOnFileChargeValidationData = () => setOnFileChargeValidationData(undefined);
  useEffect(() => {
    if (invoiceId && !skip && onFileChargeValidationData === undefined) {
      setLoading(true);
      getInvoiceOnFileChargeValidation(invoiceId).then(invoiceOnFileValidationMessages => {
        if (invoiceOnFileValidationMessages.length === 0) {
          setOnFileChargeValidationData(null);
        } else {
          setOnFileChargeValidationData(invoiceOnFileValidationMessages[0]);
        }
      }).catch(error => {
        Raven.captureException(error);
      }).finally(() => setLoading(false));
    }
  }, [invoiceId, skip, onFileChargeValidationData]);
  return {
    loading,
    onFileChargeValidationData,
    resetOnFileChargeValidationData
  };
}
export default useGetOnFileChargeValidation;