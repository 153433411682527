/**
 * Hardcoded provider names only
 * - Aircall does not use our SDK so we have define their name here.
 * - Mock UI can be loaded automatically when a localStorage value is set.
 * - Zoom Phone needs the iframe url to be customized, we use the name as the provider identifier
 */

export const AIRCALL = 'Aircall';
export const DEMO_WIDGET_JS = 'Demo Widget JS';
export const DEMO_WIDGET_REACT = 'Demo Widget React';
export const DEMO_WIDGET_LOCAL = 'Demo Widget Local';
export const DEMO_WIDGET_REMOTE = 'Demo Widget Remote';
export const MOCK_UI = 'Mock UI';
export const ZOOM_PHONE = 'Zoom Phone';