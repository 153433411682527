import { useCallback, useState } from 'react';
import { sendInvoiceEmail } from '../api/InvoicesAPI';
import { getInvoiceEmailCommunicatorUrl, getFirstInvoiceAssociationWithRecordPage } from '../utils/invoice';
const useOnSubmitHandler = ({
  invoice,
  from,
  toContacts,
  ccContacts,
  informRecipientsOfUpdate,
  onSuccess,
  onError,
  emailSubject
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const onSubmitSendRequest = useCallback(() => {
    if (!from) {
      return;
    }
    setIsLoading(true);
    sendInvoiceEmail({
      invoice,
      from,
      toContacts,
      ccContacts,
      informRecipientsOfUpdate,
      emailSubject
    }).then(onSuccess).catch(error => onError({
      error
    })).finally(() => setIsLoading(false));
  }, [invoice, from, toContacts, ccContacts, informRecipientsOfUpdate, onSuccess, onError, emailSubject]);
  const onSubmitWriteEmailRequest = useCallback(() => {
    const associatedObject = getFirstInvoiceAssociationWithRecordPage(invoice);
    if (associatedObject) {
      const url = getInvoiceEmailCommunicatorUrl(invoice, associatedObject);
      window.parent.location.assign(url);
    }
  }, [invoice]);
  return {
    isLoading,
    onSubmitSendRequest,
    onSubmitWriteEmailRequest
  };
};
export default useOnSubmitHandler;