import { TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { bulkFetchValidatedViewMembers } from 'find-and-filter-data/view-members-data/public';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCustomView, fetchUpdatedViews } from '../../../views-data/protected';
import { bulkUpdateTickets } from '../bulk-view-update/bulkUpdateTickets';
export const REALTIME_BUFFER_INTERVAL = 5000;
const defaultMessageBuffer = {
  added: new Set(),
  removed: new Set(),
  updated: new Set(),
  viewCountUpdated: new Set(),
  viewStatusChanged: new Set()
};

/**
 * Buffers incoming realtime messages to be flushed at a regular interval
 */
export function useRealtimeBuffer({
  sortState,
  viewId,
  quickFilters,
  updateBoardViewCache
}) {
  const dispatch = useDispatch();
  const intervalId = useRef(0);
  const messageBuffer = useRef(defaultMessageBuffer);

  // Reset realtime buffer when changing views, as we only store one view's members at a time.
  useEffect(() => {
    messageBuffer.current = defaultMessageBuffer;
  }, [viewId]);
  const flushMessages = useCallback(async () => {
    const {
      added,
      removed,
      updated,
      viewCountUpdated,
      viewStatusChanged
    } = messageBuffer.current;
    if (updated.size || added.size) {
      const objectIds = new Set([...added, ...updated]);

      // If there are duplicate ids across `added`/`updated` and `removed`,
      // delete them from `removed` and rely on the bulk fetch api to tell us
      // whether to remove them from the view. This is necessary because we
      // don't know the order in which these messages arrived (they may have
      // even come out of order) and we must use backend as the source of truth.
      for (const id of objectIds) {
        removed.delete(id);
      }

      // Use new bulk fetch endpoint to validate view members
      const results = await bulkFetchValidatedViewMembers({
        objectIds: [...objectIds.values()],
        objectTypeId: TICKET_TYPE_ID,
        viewId: +viewId,
        quickFilters
      });
      const addedTickets = [];
      const updatedTickets = [];
      for (const ticket of results.viewMembers) {
        if (added.has(ticket.objectKey.objectId)) {
          addedTickets.push(ticket);
          objectIds.delete(ticket.objectKey.objectId);
        } else {
          updatedTickets.push(ticket);
          objectIds.delete(ticket.objectKey.objectId);
        }
      }

      // Any added/updated objectIds that were missing in the bulk fetch
      // response presumably failed the view membership check, and should be
      // removed from state.
      for (const id of objectIds) {
        removed.add(id);
      }
      dispatch(bulkUpdateTickets({
        added: addedTickets,
        updated: updatedTickets,
        removed: [...removed.values()],
        customViewId: +viewId,
        sortState
      }));
      updateBoardViewCache === null || updateBoardViewCache === void 0 || updateBoardViewCache.handleBoardRealtimeUpdate(results.viewMembers);
      updateBoardViewCache === null || updateBoardViewCache === void 0 || updateBoardViewCache.handleBoardRealtimeRemoval([...removed.values()]);
      messageBuffer.current.added = new Set();
      messageBuffer.current.removed = new Set();
      messageBuffer.current.updated = new Set();
    } else if (removed.size) {
      dispatch(bulkUpdateTickets({
        added: [],
        updated: [],
        removed: [...removed.values()],
        customViewId: +viewId,
        sortState
      }));
      updateBoardViewCache === null || updateBoardViewCache === void 0 || updateBoardViewCache.handleBoardRealtimeRemoval([...removed.values()]);
      messageBuffer.current.removed = new Set();
    }
    if (viewCountUpdated.size) {
      dispatch(fetchUpdatedViews({
        viewIds: Array.from(viewCountUpdated)
      })).catch(() => {
        //ignore errors
      });
      messageBuffer.current.viewCountUpdated = new Set();
    }
    if (viewStatusChanged.size) {
      const viewIds = Array.from(viewStatusChanged);
      for (const changedViewId of viewIds) {
        dispatch(fetchCustomView({
          customViewId: changedViewId
        })).catch(() => {
          //ignore errors
        });
      }
      messageBuffer.current.viewStatusChanged = new Set();
    }
  }, [dispatch, sortState, quickFilters, viewId, updateBoardViewCache]);
  useEffect(() => {
    intervalId.current = setInterval(flushMessages, REALTIME_BUFFER_INTERVAL);
    return () => clearInterval(intervalId.current);
  }, [flushMessages]);
  return useMemo(() => ({
    add(...added) {
      for (const id of added) {
        messageBuffer.current.added.add(id);
      }
    },
    remove(...removed) {
      for (const id of removed) {
        messageBuffer.current.removed.add(id);
      }
    },
    update(...updated) {
      for (const id of updated) {
        messageBuffer.current.updated.add(id);
      }
    },
    viewCountUpdate(...viewCountsUpdated) {
      for (const id of viewCountsUpdated) {
        messageBuffer.current.viewCountUpdated.add(id);
      }
    },
    viewStatusChanged(...viewIds) {
      for (const id of viewIds) {
        messageBuffer.current.viewStatusChanged.add(id);
      }
    }
  }), []);
}