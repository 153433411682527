import { useState, useCallback } from 'react';
import retryUntilSuccess from '../components/utils/retryUntilSuccess';
import { setHref } from '../components/utils/location';
import Raven from 'raven-js';
const useDownloadPdf = getRequest => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadFailed, setIsDownloadFailed] = useState(false);
  const [isDownloadSuccess, setIsDownloadSuccess] = useState(false);
  const onDownload = useCallback(async () => {
    if (!getRequest) {
      return;
    }
    setIsDownloadFailed(false);
    setIsDownloadSuccess(false);
    setIsDownloading(true);
    try {
      const pdfLink = await retryUntilSuccess({
        promiseFn: getRequest,
        successCondition: link => !!link,
        maxAttempts: 5,
        delayMs: 2000
      });
      setHref(pdfLink);
      setIsDownloadSuccess(true);
    } catch (error) {
      Raven.captureException(error);
      setIsDownloadFailed(true);
    } finally {
      setIsDownloading(false);
    }
  }, [getRequest, setIsDownloadFailed, setIsDownloadSuccess, setIsDownloading]);
  return {
    isPdfDownloading: isDownloading,
    isPdfDownloadFailed: isDownloadFailed,
    isPdfDownloadSuccess: isDownloadSuccess,
    onPdfDownload: () => {
      onDownload().catch(Raven.captureException);
    }
  };
};
export default useDownloadPdf;