import { useQuery } from '@apollo/client';
import { fetchTicketName } from '../queries/fetchTicketName';
export const useTicketName = ticketId => {
  var _data$crmObject$defau;
  const {
    data,
    loading,
    error
  } = useQuery(fetchTicketName, {
    fetchPolicy: 'no-cache',
    variables: {
      ticketId
    },
    skip: !ticketId
  });
  return {
    ticketName: data ? (_data$crmObject$defau = data.crmObject.defaultProperties.subject) === null || _data$crmObject$defau === void 0 ? void 0 : _data$crmObject$defau.value : null,
    loading,
    error
  };
};