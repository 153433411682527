'use es6';

import { Record } from 'immutable';
export const TestMessageCreateResponse = Record({
  latest_status: null,
  latest_error_type: null,
  email_guid: null
}, 'TestMessageCreateResponse');
export const TestMessageQueryResponse = Record({
  latest_status: null,
  latest_error_type: null
}, 'TestMessageQueryResponse');