import http from 'conversations-http/clients/http';
import { CHANNEL_MIGRATION_STATES } from '../constants/channel-migration';
const MIGRATION_PREFIX = 'helpdesk-migration/v1/enrollment';
const MIGRATION_V2_PREFIX = 'helpdesk-migration/v2/migration';
const MIGRATION_V4_PREFIX = 'helpdesk-migration/v4/migration';
export const fetchMigrationStatus = async ({
  channelInstanceId
}) => {
  try {
    const status = await http.get(`${MIGRATION_V4_PREFIX}/${channelInstanceId}`, {
      query: {
        channelInstanceId
      }
    });
    return status;
  } catch (error) {
    if (error.status === 404) {
      return {
        channelInstanceId,
        conversationsMoved: 0,
        migrationStatus: CHANNEL_MIGRATION_STATES.NOT_STARTED,
        moveConversations: false,
        migrationPending: false
      };
    }
    throw error;
  }
};
const checkHasPipelineInfo = pipeline => !!(pipeline && pipeline.pipelineId && pipeline.closedStageId && pipeline.openStageId);
export const startMigration = ({
  channelInstanceId,
  moveConversations,
  pipeline
}) => {
  const data = {
    moveConversations
  };
  if (checkHasPipelineInfo(pipeline)) {
    data.pipelineIdAndOpenCloseStages = {
      closedPipelineStageId: pipeline.closedStageId,
      openPipelineStageId: pipeline.openStageId,
      pipelineId: pipeline.pipelineId
    };
  }
  if (moveConversations && !data.pipelineIdAndOpenCloseStages) {
    return Promise.reject(
    // This error is not intended to be shown to the user
    Error('A migration was started without pipeline information'));
  }
  return http.post(`${MIGRATION_V4_PREFIX}/${channelInstanceId}`, {
    data
  });
};
export const startMigrationToAnInbox = ({
  channelInstanceId,
  migrationOptionsRequest
}) => {
  const data = {
    moveConversations: true,
    // for Help Desk to Inbox migration, we are going to move all conversations by default for now.
    pipelineIdAndOpenCloseStages: null,
    migrationOptionsRequest
  };
  return http.post(`${MIGRATION_V4_PREFIX}/${channelInstanceId}`, {
    data
  });
};
// fetchInboxActiveMigrationStatus takes an inboxId and returns an array of HelpDeskMigration objects
// with the migrationStatus and moveConversations properties set.
// If there's no migration record for a given inboxId, returns an empty array.
export const fetchInboxActiveMigrationStatus = async ({
  inboxId
}) => {
  try {
    const migrationRecords = await http.get(`${MIGRATION_PREFIX}/migration-status/inbox/${inboxId}`);
    return migrationRecords;
  } catch (error) {
    if (error.status === 404) {
      return [];
    }
    throw error;
  }
};

// fetchChannelsActiveMigrationStatus takes an array of channelInstanceIds and returns an array of HelpDeskMigration objects
// with the migrationStatus and moveConversations properties set.
// If there's no migration record for a given channelInstanceId, returns an empty array.
export const fetchChannelsActiveMigrationStatus = async ({
  channelInstanceIds
}) => {
  try {
    const migrationRecords = await http.post(`${MIGRATION_PREFIX}/migration-status/channels`, {
      data: channelInstanceIds
    });
    return migrationRecords;
  } catch (error) {
    if (error.status === 404) {
      return [];
    }
    throw error;
  }
};
export const fetchAllMigrationRecords = async () => await http.get(`${MIGRATION_PREFIX}/migration-status/portal`);
export const fetchMigrationResults = async ({
  channelInstanceId
}) => await http.get(`${MIGRATION_V2_PREFIX}/results`, {
  query: {
    channelInstanceId
  }
});