import { registerQuery, useQuery } from 'data-fetching-client';
import apiClient from 'hub-http/clients/apiClient';
import { createChirpRpcClientV2 } from 'rpc-client-utils';
import { getActivePaymentMethodOnFileListForContactRpc } from '../../../chirp/payments/payment/method/management/rpc/PaymentMethodOnFileReadService';
const chirpClient = createChirpRpcClientV2({
  hubHttpClient: apiClient
});
export const GET_STORED_PAYMENT_METHOD_FOR_CONTACT = registerQuery({
  fieldName: 'getActivePaymentMethodOnFileListForContact',
  args: ['contactId'],
  fetcher(input) {
    return chirpClient.call(getActivePaymentMethodOnFileListForContactRpc, {
      request: input
    });
  }
});
export function useGetStoredPaymentMethodForContact(contactId) {
  return useQuery(GET_STORED_PAYMENT_METHOD_FOR_CONTACT, {
    variables: {
      contactId
    },
    fetchPolicy: 'cache-and-network'
  });
}