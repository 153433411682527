import enviro from 'enviro';
function _logToConsole(error, extra = {}, type = 'error') {
  /* eslint-disable no-console */

  if (enviro.debug('calling-global-debug') && typeof console.group === 'function') {
    console.groupCollapsed(`%c ${error.name || 'UnknownError'} ${type.toUpperCase()} %c`, `background-color:#${type === 'error' ? 'c51212' : '516f90'};color:white;padding:.15em .25em`, `color:#33475b`, error.message);
    console.log(`Message: ${error.message}`);
    console.log(extra);
    console.error(error);
    console.groupEnd();
    /* eslint-enable no-console */
  }
}
const DEFAULTS = {
  level: 'error',
  tags: {},
  extra: {},
  fingerprint: []
};
export const logError = (error, attributes = {}) => {
  const options = Object.assign({}, DEFAULTS, attributes);
  if (window.Raven && window.Raven.captureException) window.Raven.captureException(error, options);
  _logToConsole(error, options.extra, options.level);
};

// https://docs.sentry.io/clients/javascript/usage/#raven-js-additional-context
export const logMessageToSentry = (error, options) => {
  if (window.Raven && window.Raven.captureMessage) window.Raven.captureMessage(error, options);
};
export function logPageAction({
  name,
  attributes
}) {
  if (window.Raven && window.Raven.capturePageEvent) {
    window.Raven.capturePageEvent(name, {
      extra: attributes
    });
  }
}