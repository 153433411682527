import { COLLAPSED_VIEWS, STANDARD_VIEWS } from '../constants/ViewPlacement';
import { getViewInternalType } from 'find-and-filter-data/views-schema/public';
/**
 * @description This operators picks entries from viewCollection that have a
 * viewType in pickViewTypes
 */
export const _pickViewsByType = pickViewTypes => viewCollection => viewCollection.filter(view => pickViewTypes.includes(getViewInternalType(view))).sort((a, b) => pickViewTypes.indexOf(getViewInternalType(a)) - pickViewTypes.indexOf(getViewInternalType(b)));

/**
 * @description pickStandardViews will take a collection of Views and will pick
 * the sorted standard views from it based on the STANDARD_VIEWS array
 */
export const pickStandardViews = _pickViewsByType(STANDARD_VIEWS);

/**
 * @description pickCollapsedViews will take a collection of Views and will pick
 * the sorted collapsed views from it based on the COLLAPSED_VIEWS array
 */
export const pickCollapsedViews = _pickViewsByType(COLLAPSED_VIEWS);