import { getUsageTrackingChannel } from 'conversations-thread-data/usage-tracking/public/trackerChannelIdMapping';
export const getSentMessage = ({
  genericChannelId,
  communicatorType,
  hasAssociatedTicket,
  replyType,
  timeToReply,
  messageChannel,
  numberOfTicketsAssociated,
  source,
  editorType
}) => {
  return {
    key: 'sent-message',
    properties: {
      action: 'sent message',
      hasAssociatedTicket,
      timeToReply,
      channel: getUsageTrackingChannel(genericChannelId),
      messageChannel,
      'communicator-type': communicatorType,
      'reply-type': replyType,
      source,
      numberOfTicketsAssociated,
      editorType
    }
  };
};
export const getSentMessageChatActivationPortal = ({
  communicatorType,
  portalId
}) => ({
  key: `sent-message-chat-activation-portal`,
  properties: {
    action: 'sent message',
    hubId: portalId,
    'communicator-type': communicatorType
  }
});
export const getSentMessageFBMActivationPortal = ({
  communicatorType,
  portalId
}) => ({
  key: `sent-message-fbm-activation-portal`,
  properties: {
    action: 'sent message',
    hubId: portalId,
    'communicator-type': communicatorType
  }
});
export const getSentMessageWhatsAppActivationPortal = ({
  communicatorType,
  portalId
}) => ({
  key: `sent-message-whatsapp-activation-portal`,
  properties: {
    action: 'sent message',
    hubId: portalId,
    'communicator-type': communicatorType
  }
});