import { createSlice } from '@reduxjs/toolkit';
import { omnibusFulfilled } from 'find-and-filter-data/omnibus/public';
import { fetchViewMembers } from 'find-and-filter-data/view-members-data/protected';
import get from 'transmute/get';
export const latestOmnibusTimestampSlice = createSlice({
  name: 'latestOmnibusTimestamp',
  initialState: 0,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
    // TODO - this can be deleted when THREAD_LIST_MEMBERS_FETCH async action
    // is deleted and we only need fetchViewMembers.fulfilled
    'THREAD_LIST_MEMBERS_FETCH_SUCCEEDED', (state, {
      payload: {
        data
      }
    }) => {
      return Math.max(state, get('latestAppliedPlaybackMessageTimestamp', data));
    }).addCase(fetchViewMembers.fulfilled, (state, {
      payload
    }) => {
      const latestPlaybackMessageTimestamp = payload.latestPlaybackMessageTimestamp || 0;
      return Math.max(state, latestPlaybackMessageTimestamp);
    }).addCase(omnibusFulfilled, (state, {
      payload: {
        omnibus: {
          latestPlaybackMessageTimestamp
        }
      }
    }) => {
      return Math.max(state, latestPlaybackMessageTimestamp);
    });
  }
});