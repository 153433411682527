import { isNonEmptyString } from '../utils/StringValidation';

// Backend errors:
export const CONNECTIONS_RATE_LIMITED = 'CONNECTIONS_RATE_LIMITED';
export const APP_PASSWORD_REQUIRED = 'APP_PASSWORD_REQUIRED';
export const GENERIC_IMAP_DISABLED = 'GENERIC_IMAP_DISABLED';
export const GENERIC_MISSING_FOLDER = 'GENERIC_MISSING_FOLDER';
export const IMAP_AUTHENTICATION_ERROR = 'IMAP_AUTHENTICATION_ERROR';
export const IMAP_VALIDATE_ERROR = 'IMAP_VALIDATE_ERROR';
export const IMAP_SETTINGS_INCORRECT = 'IMAP_SETTINGS_INCORRECT';
export const IMAP_UNKNOWN_IO_ERROR = 'IMAP_UNKNOWN_IO_ERROR';
export const SMTP_SETTINGS_INCORRECT = 'SMTP_SETTINGS_INCORRECT';
export const SMTP_MESSAGING = 'SMTP_MESSAGING';
export const OFFICE_SEND_FAILED = 'OFFICE_SEND_FAILED';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const UNTRUSTED_CERTIFICATES = 'UNTRUSTED_CERTIFICATES';
export const MISSING_INBOX_FOLDER = 'MISSING_INBOX_FOLDER';
export const MISSING_DRAFTS_FOLDER = 'MISSING_DRAFTS_FOLDER';
export const GMAIL_IMAP_DISABLED = 'GMAIL_IMAP_DISABLED';
export const OFFICE_ERROR = 'OFFICE_ERROR';
export const OFFICE_ERROR_ORGANIZATION_ACCESS_BLOCKED = 'OFFICE_ERROR_ORGANIZATION_ACCESS_BLOCKED';
export const OFFICE_ERROR_REST_API_NOT_ENABLED_FOR_COMPONENT_SHARED_MAILBOX = 'OFFICE_ERROR_REST_API_NOT_ENABLED_FOR_COMPONENT_SHARED_MAILBOX';
export const OFFICE_ERROR_INVALID_LICENSE = 'OFFICE_ERROR_INVALID_LICENSE';
export const TRANSIENT = 'TRANSIENT';
export const IMAP_DISABLED_OR_LOGIN_FAILURE = 'IMAP_DISABLED_OR_LOGIN_FAILURE';
export const INBOX_VALIDATE_ERROR = 'INBOX_VALIDATE_ERROR';
export const AUTODISCOVERY_FAILURE = 'AUTODISCOVERY_FAILURE';
export const RECONNECT_EMAIL_MISMATCH = 'RECONNECT_EMAIL_MISMATCH';
export const SHARED_INBOX_INVALID_DOWNGRADE = 'SHARED_INBOX_INVALID_DOWNGRADE';
export const CONVERSATIONS_CREATE_CONFLICT = 'CONVERSATIONS_CREATE_CONFLICT';
export const EXCHANGE_URL_INVALID = 'EXCHANGE_URL_INVALID';
export const EXCHANGE_VERSION_INVALID = 'EXCHANGE_VERSION_INVALID';
export const EXCHANGE_VALIDATE_ERROR = 'EXCHANGE_VALIDATE_ERROR';
// Frontend errors:
export const USER_DECLINED = 'USER_DECLINED';
const ERROR_STEP_ROUTE = 'InboxConnectUtils.wizard.failure.errorResponse';
const RECONNECT_HELP_URL = 'https://knowledge.hubspot.com/articles/kcs_article/email-notifications/how-can-i-resolve-an-unavailable-or-unauthorized-inbox-connection-error?hs_preview=hrvZeOCN-5437059821';
const GMAIL_IMAP_DISABLED_URL = 'https://knowledge.hubspot.com/articles/kcs_article/email-notifications/how-can-i-resolve-an-unavailable-or-unauthorized-inbox-connection-error#unavailable-imap-disabled-in-gmail';
const SHARED_INBOX_DISABLED_URL = 'https://knowledge.hubspot.com/getting-started-with-the-hubspot-crm-free/how-to-disconnect-an-authorized-account-from-the-crm';
const getLangKey = key => `${ERROR_STEP_ROUTE}.${key}`;
const getDescription = (key, options = {}) => ({
  description: getLangKey(key),
  options
});
export const parseSharedInboxAlreadyConnected = message => {
  if (!isNonEmptyString(message)) return null;

  // expected message:
  //    Could not create inbox: support@hubspotters.com is a shared inbox across this portal
  // context:
  //    User A creates shared inbox support@hubspotters.com,
  //    then User B tries to connect support@hubspotters.com as a personal inbox
  const sharedInboxAlreadyConnectedPattern = /^Could not create inbox: (.*?) is a shared inbox across this portal/;
  const sharedInboxAlreadyConnectedMatches = message.match(sharedInboxAlreadyConnectedPattern);
  if (sharedInboxAlreadyConnectedMatches) {
    return {
      emailAddress: sharedInboxAlreadyConnectedMatches[1]
    };
  }
  return null;
};
export const parseSharedInboxAlreadyConnectedPersonally = message => {
  if (!isNonEmptyString(message)) return null;

  // expected message:
  //    Could not create inbox: Users [userA@hubspotters.com] must remove
  //    inbox with email support@hubspotters.com before continuing
  // context:
  //    User A creates a personal inbox support@hubspotters.com,
  //    then User B tries to connect support@hubspotters.com as a shared inbox
  const sharedInboxMustRemovePattern = /^Could not create inbox: Users \[(.*?)\] must remove inbox with email (.*?) before continuing/;
  const sharedInboxMustRemoveMatches = message.match(sharedInboxMustRemovePattern);
  if (sharedInboxMustRemoveMatches) {
    const usersEmailsCSV = sharedInboxMustRemoveMatches[1];
    const emailAddress = sharedInboxMustRemoveMatches[2];
    const usersEmails = isNonEmptyString(usersEmailsCSV) && usersEmailsCSV.split(', ');
    return {
      usersEmails,
      usersEmailsCSV,
      emailAddress
    };
  }
  return null;
};
export const parseNoConnectedEmailInboxAccessScope = message => {
  if (!isNonEmptyString(message)) return null;

  // expected message:
  //    This app-cookie does not have proper permissions! (Require all of [connected-email-inbox-access])
  // context:
  //    User does not have connected-email-inbox-access scope
  const connectedEmailInboxAccessPattern = /.*\[.*connected-email-inbox-access.*\]/;
  const connectedEmailInboxAccessMatches = message.match(connectedEmailInboxAccessPattern);
  return !!connectedEmailInboxAccessMatches;
};
export const parseSharedInboxErrorMessage = message => {
  const sharedInboxAlreadyConnected = parseSharedInboxAlreadyConnected(message);
  if (sharedInboxAlreadyConnected) {
    return getDescription('sharedInbox.alreadyConnected', sharedInboxAlreadyConnected);
  }
  const sharedInboxMustRemove = parseSharedInboxAlreadyConnectedPersonally(message);
  if (sharedInboxMustRemove) {
    const {
      emailAddress,
      usersEmailsCSV
    } = sharedInboxMustRemove;
    return getDescription('sharedInbox.alreadyConnectedAsPersonal', {
      emailAddress,
      usersEmailsCSV,
      link: SHARED_INBOX_DISABLED_URL
    });
  }
  const noConnectedEmailInboxAccessScope = parseNoConnectedEmailInboxAccessScope(message);
  if (noConnectedEmailInboxAccessScope) {
    return getDescription('noInboxAccess');
  }
  return null;
};
const reconnect = getDescription('reconnect');
const updateYourInbox = getDescription('updateYourInbox', {
  link: RECONNECT_HELP_URL
});
const reachOutToOfficeAdmin = getDescription('reachOutToOfficeAdmin');
const invalidLicense = getDescription('invalidLicense');
const enableImap = getDescription('enableImap', {
  link: GMAIL_IMAP_DISABLED_URL
});
const testEmailFailed = getDescription('testEmailFailed');
const invalidDowngrade = getDescription('sharedInbox.invalidDowngrade');
const conversationsConflict = getDescription('sharedInbox.conversationsConflict');
export const INBOX_ERROR_MESSAGES = {
  [UNAUTHORIZED]: reconnect,
  [INBOX_VALIDATE_ERROR]: reconnect,
  [MISSING_INBOX_FOLDER]: updateYourInbox,
  [MISSING_DRAFTS_FOLDER]: updateYourInbox,
  [OFFICE_ERROR]: reconnect,
  [OFFICE_ERROR_ORGANIZATION_ACCESS_BLOCKED]: reachOutToOfficeAdmin,
  [OFFICE_ERROR_REST_API_NOT_ENABLED_FOR_COMPONENT_SHARED_MAILBOX]: updateYourInbox,
  [OFFICE_ERROR_INVALID_LICENSE]: invalidLicense,
  [TRANSIENT]: reconnect,
  [GMAIL_IMAP_DISABLED]: enableImap,
  [SMTP_MESSAGING]: testEmailFailed,
  [OFFICE_SEND_FAILED]: testEmailFailed,
  [SHARED_INBOX_INVALID_DOWNGRADE]: invalidDowngrade,
  [CONVERSATIONS_CREATE_CONFLICT]: conversationsConflict,
  error: reconnect
};