'use es6';

import { ENABLED, ERROR, UNAVAILABLE } from '../constants/FeatureStates';
import ConnectedAccountRecord from './ConnectedAccountRecord';
import GenericInboxRecord from './GenericInboxRecord';
import ExchangeInboxRecord from './ExchangeInboxRecord';
import ProviderTypes from '../constants/ProviderTypes';
export const transformConnectedAccountsResponse = response => {
  return response.map(inbox => {
    return new ConnectedAccountRecord({
      emailAddress: inbox.get('accountId'),
      hasConnectionError: inbox.get('state') === ERROR || inbox.get('state') === UNAVAILABLE,
      isEnabled: inbox.get('state') === ENABLED,
      inboxType: inbox.get('emailProviderType')
    });
  });
};
export const transformExchangeToGeneric = exchangeRecord => GenericInboxRecord({
  acceptUntrustedCert: exchangeRecord.acceptUntrustedCert,
  created: exchangeRecord.created,
  customUsername: exchangeRecord.customUsername ? exchangeRecord.customUsername : null,
  email: exchangeRecord.email,
  id: exchangeRecord.id,
  imapHost: exchangeRecord.domain,
  imapPort: 0,
  password: exchangeRecord.password,
  smtpHost: exchangeRecord.exchangeUrl,
  smtpPort: 0,
  updated: exchangeRecord.updated
});
export const transformGenericToExchange = generic => ExchangeInboxRecord({
  acceptUntrustedCert: generic.acceptUntrustedCert,
  created: generic.created,
  customUsername: generic.customUsername,
  email: generic.email,
  id: generic.id,
  domain: generic.imapHost,
  password: generic.password,
  exchangeUrl: generic.smtpHost,
  updated: generic.updated
});
export const transformFacsimileResponseToGeneric = facsimileResponse => {
  const {
    id,
    email_address,
    created,
    updated,
    custom_username,
    generic_inbox_settings: {
      imap_host_name,
      imap_port,
      smtp_host_name,
      smtp_port,
      smtp_username,
      user_accepts_untrusted_certificates
    }
  } = facsimileResponse.toJS();
  return GenericInboxRecord({
    acceptUntrustedCert: user_accepts_untrusted_certificates,
    created,
    customUsername: custom_username,
    email: email_address,
    id,
    imapHost: imap_host_name,
    imapPort: imap_port,
    password: '',
    smtpHost: smtp_host_name,
    smtpPort: smtp_port,
    updated,
    smtpUsername: smtp_username
  });
};

/**
 * IMAP doesn't really have a serviceType like the oauth
 * providers. Adding one to make the account we return
 * `onDone` to be consistent
 *
 * @param {GenericInboxRecord} generic
 */
export const transformGenericToReturnableAccount = generic => ({
  emailAddress: generic.email,
  id: generic.id,
  serviceType: ProviderTypes.imap
});
export const transformGenericToReturnableExchangeAccount = generic => ({
  emailAddress: generic.email,
  id: generic.id,
  serviceType: ProviderTypes.exchange,
  exchangeUrl: generic.exchangeUrl,
  username: generic.customUsername
});