import { UserAttributesClient } from 'frontend-preferences-client';

// Add new user settings as follows here:
// https://product.hubteam.com/docs/appsystems/frontend-preferences/frontend-preferences.html#i-want-to-store-data-in-a-new-key
export const USER_SETTINGS = {
  HIDE_MANUAL_EMAIL_BUTTON_POPOVER: 'hide-manual-email-button-popover'
};
export const userAttributesClient = UserAttributesClient.forCaller('commerce-tools-ui-lib');
export const saveUserSetting = (key, value) => {
  userAttributesClient.write(key, value).catch(() => {});
};