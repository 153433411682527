'use es6';

import { fromJS } from 'immutable';
const baseUrl = 'snippets/v1/render';
import { CONTACT } from 'customer-data-objects/constants/ObjectTypes';
const sortSnippetResults = ({
  response,
  query
}) => {
  const snippets = response.results;
  try {
    snippets.sort((a, b) => {
      const ashort = a.shortcut.trim().toLowerCase();
      const bshort = b.shortcut.trim().toLowerCase();
      const tLower = query.trim().toLowerCase();
      if (ashort.startsWith(tLower) && bshort.startsWith(tLower)) {
        return ashort.length - bshort.length;
      } else if (ashort.startsWith(tLower)) {
        return -1;
      } else if (bshort.startsWith(tLower)) {
        return 1;
      }
      return 0;
    });
    response.results = snippets;
  } catch (e) {
    console.error(e);
  }
  return response;
};
export default (apiClient => ({
  fetchRenderedContent({
    id,
    contactEmail,
    contactVid,
    supplementalObjects
  }) {
    if (!contactVid && supplementalObjects && supplementalObjects[CONTACT]) {
      contactVid = supplementalObjects[CONTACT];
    }
    return apiClient.post(baseUrl, {
      data: {
        snippetId: id,
        toEmail: contactEmail,
        contactId: contactVid,
        supplementalObjects
      }
    });
  },
  fetchUnrenderedContent({
    id
  }) {
    return apiClient.get(`snippets/v1/snippets/${id}`);
  },
  searchSnippets({
    count,
    offset,
    query
  }) {
    return apiClient.post('salescontentsearch/v2/search', {
      data: {
        contentTypesToHydrate: ['SNIPPET'],
        filters: [{
          field: 'content_type',
          values: ['SNIPPET']
        }],
        limit: count,
        offset,
        query
      }
    }).then(response => fromJS(sortSnippetResults({
      response,
      query
    })));
  }
}));