import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER } from './constants';
import { doesThreadMatchFilters } from './doesThreadMatchFiltersService';
import { fetchFilteredThreadListMembers } from './fetchFilteredThreadListMembersService';
import { fetchFilteredViewMembersService } from './fetchFilteredViewMembersService';
export const checkThreadMembership = createAsyncThunk('filterResults/checkThreadMembership', ({
  filterValues,
  inboxId,
  threadId
}) => doesThreadMatchFilters({
  filterValues,
  inboxId,
  threadId
}));
export const fetchSearchAndFilterResults = createAsyncThunk('filterResults/fetchFilterResults', ({
  filterKey,
  offsetTimestamp = null,
  offsetId = null,
  inboxId
}) => fetchFilteredThreadListMembers({
  filterKey,
  offsetTimestamp,
  offsetId,
  inboxId
}));
export const fetchSearchAndFilterViewMembers = createAsyncThunk('filterResults/fetchSearchAndFilterViewMembers', ({
  filterGroups,
  inboxId,
  limit,
  offsetTimestamp,
  offsetId,
  searchQuery,
  sortDirection,
  viewId
}) => fetchFilteredViewMembersService(Object.assign({}, viewId !== DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER && {
  customViewId: +viewId
}, {
  filterGroups,
  inboxId,
  limit,
  offsetTimestamp,
  offsetId,
  searchQuery,
  sortDirection
})));
export const clearFilterResults = createAction('filterResults/clearFilterResults');