import { GLOBAL_NAME } from '../constants/Globals';
import { isValidEventType } from '../utils/getIsValidEventType';
export default function handleAddCallingEventListener({
  event,
  callback
}) {
  if (!isValidEventType(event)) {
    return;
  }
  if (!window[GLOBAL_NAME].updateQueueByEventType) {
    window[GLOBAL_NAME].updateQueueByEventType = {};
  }
  if (!window[GLOBAL_NAME].updateQueueByEventType[event]) {
    window[GLOBAL_NAME].updateQueueByEventType[event] = [];
  }
  window[GLOBAL_NAME].updateQueueByEventType[event].push(callback);
}