import { useEffect, useState } from 'react';
import userInfo, { userInfoSync } from 'hub-http/userInfo';
import memoizeOne from 'react-utils/memoizeOne';
import enviro from 'enviro';
export const memoizedGatesSet = memoizeOne(user => new Set(user === null || user === void 0 ? void 0 : user.gates));
export const memoizedScopesSet = memoizeOne(user => new Set(user === null || user === void 0 ? void 0 : user.user.scopes));
export const useUserInfo = () => {
  const [userInfoResponse, setUserInfoResponse] = useState(() => {
    // initialize with userInfoSync in case userInfo has already been loaded.
    // this avoids an initial render without gates/scopes, if possible.
    // userInfoSync throws an Error if userInfo has not finished loading yet;
    // in that case fall back to waiting for async userInfo
    try {
      return userInfoSync();
    } catch (_unused) {
      return undefined;
    }
  });
  useEffect(() => {
    if (userInfoResponse) {
      return;
    }
    // If we're in a unit test, we don't need to make a call - the default empty array is fine unless mocked otherwise
    if (enviro.deployed()) {
      void userInfo({
        ignoreRedirect: true
      }).then(response => {
        setUserInfoResponse(response);
      });
    }
  }, [userInfoResponse]);
  return userInfoResponse;
};