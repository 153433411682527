import { getFromSenderEmail } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getAllRecipientsEmails } from './getAllRecipientsEmails';
import getEmailAddressFromString from './getEmailAddressFromString';
export const getTotalRecipientsCount = ({
  message
}) => {
  let totalRecipients = getAllRecipientsEmails({
    message
  });
  if (totalRecipients.length) {
    const fromAddress = getFromSenderEmail(message);
    const fromAddressEmail = fromAddress && getEmailAddressFromString(fromAddress);
    // Remove fromEmail from recipients
    totalRecipients = totalRecipients.filter(email => {
      return email !== fromAddressEmail;
    });
  }
  return totalRecipients.length;
};