import { getTORecipients, getCCRecipients, getBCCRecipients, getFromSenderEmail, getOriginalFromSenderEmail } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getConnectedAccountAddress } from 'conversations-message-history/common-message-format/operators/emailMetadataGetters';
import { List } from 'immutable';
// @ts-expect-error module not typed
import { getEmail } from 'conversations-internal-schema/visitor/operators/visitorGetters';
import { getEmailAddressRecordFromString } from 'conversations-thread-data/common-reply-metadata/public/operators/getEmailAddressRecordFromString';
import { getVisitor } from 'conversations-thread-data/thread-details/public/operators';
import { areEmailAddressesEqual } from 'conversations-thread-data/connected-accounts/public';
// @ts-expect-error module not typed

import get from 'transmute/get';
import { areEmailAddressRecordsEqual } from './areEmailAddressRecordsEqual';
const filterOutAddresses = (toOmitList, addressesList) => addressesList && addressesList.filter(addressRecord => !toOmitList.some(toOmit => areEmailAddressesEqual(get('address', addressRecord), toOmit) || areEmailAddressesEqual(get('sendAsAddress', addressRecord), toOmit))).toList();

/**
 * @description generate the recipients of a reply email given the thread
 * message and the address of the conected account sending the reply
 */
export const getEmailReplyRecipients = (from, message, filterOutEmails, threadDetails = null, replyAll = true) => {
  const messageConnectedAccount = getConnectedAccountAddress(message);
  const visitor = getVisitor(threadDetails);
  const originalSender = getOriginalFromSenderEmail(message);
  const TOAddresses = getTORecipients(message);
  if (!TOAddresses || !TOAddresses.size) {
    const visitorEmail = getEmail(visitor);
    const to = visitorEmail ? List([getEmailAddressRecordFromString(visitorEmail, visitor)]) : List();
    return {
      bcc: List(),
      cc: List(),
      to
    };
  }
  const CCAddresses = getCCRecipients(message);
  const BCCAddresses = getBCCRecipients(message);
  const toAddresses = originalSender ? List([originalSender]) : TOAddresses;
  const lastRecipients = toAddresses.map(recipientAddressString => getEmailAddressRecordFromString(recipientAddressString, visitor)).toList();
  const lastCc = CCAddresses && CCAddresses.map(recipientAddressString => getEmailAddressRecordFromString(recipientAddressString, visitor)).toList();
  const lastBcc = BCCAddresses && BCCAddresses.map(recipientAddressString => getEmailAddressRecordFromString(recipientAddressString, visitor)).toList();
  const fromAddress = getFromSenderEmail(message);
  const lastSenderAddress = fromAddress && getEmailAddressRecordFromString(fromAddress, visitor);
  if (originalSender) {
    const cc = replyAll ? lastCc : List();
    return {
      bcc: List(),
      cc,
      to: lastRecipients
    };
  }
  const replyingToOwnMessage = areEmailAddressRecordsEqual(lastSenderAddress, from);
  if (replyingToOwnMessage) {
    return replyAll ? {
      bcc: lastBcc,
      cc: lastCc,
      to: lastRecipients
    } : {
      bcc: List(),
      cc: List(),
      to: lastRecipients
    };
  }
  if (!replyAll) {
    const to = List([lastSenderAddress]);
    return {
      bcc: List(),
      cc: List(),
      to
    };
  }

  // filter out any previous connected accounts from the recipients
  // list (they can be present when a thread was moved to a new inbox)
  const accountsToFilter = filterOutEmails.concat(messageConnectedAccount).concat(get('address', from)).concat(get('sendAsAddress', from)).toList();
  let to = filterOutAddresses(accountsToFilter, lastRecipients.toList());
  if (lastSenderAddress) {
    to = to && to.unshift(lastSenderAddress);
  }
  const cc = filterOutAddresses(accountsToFilter, lastCc);
  return {
    bcc: List([]),
    cc,
    to
  };
};