/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { fetchViewMembers } from '../../view-members-data/protected';
import { pollViews } from '../../views-data/public';

/**
 * @description This Redux action should be dispatched after a bulk update is
 * successfully received to trigger a refresh of the view members and view counts.
 */
export const bulkUpdateReceived = ({
  inboxId,
  sortDirection,
  threadStatus,
  viewId
}) => dispatch => {
  dispatch(pollViews({
    inboxId
  }));
  dispatch(fetchViewMembers({
    inboxId,
    sortDirection,
    threadStatus,
    viewId,
    options: {
      disabledLoad: true
    }
  }));
};