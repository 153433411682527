import { HELP_DESK_ROUTING_SCOPE_FILTERING, USER_AWARE_ROUTING_RULES } from '../constants/gates';
import { memoizedGatesSet, useUserInfo } from './useUserInfo';
export const useGates = () => memoizedGatesSet(useUserInfo());
export const useGate = gate => useGates().has(gate);
export const createUngatedHook = gate => () => useGate(gate);

/*
  Put individual gate-check hooks below this comment
  For single gate checks, just do `const useHasMyGate = createUngatedHook(MY_GATE)`
  Since hooks should not be called conditionally, for multi-gate checks, do
    const useIsUngatedForMultiple = () => {
      const gates = useGates();

      return gates.has(MY_GATE) &&
        gates.has(OTHER_GATE) &&
          gates.has(THIRD_GATE);
    };
*/
export const useIsUngatedForHelpDeskRoutingScopeFiltering = createUngatedHook(HELP_DESK_ROUTING_SCOPE_FILTERING);
export const useIsUngatedForUserAwareRoutingRules = createUngatedHook(USER_AWARE_ROUTING_RULES);