import apiClient from 'hub-http/clients/apiClient';
import { registerQuery, useQuery } from 'data-fetching-client';
const fetchConnectedInboxes = () => apiClient.get('connectedaccounts/v1/accounts/connected-inboxes').then(({
  connectedInboxes
}) => connectedInboxes);
const CONNECTED_INBOXES_QUERY = registerQuery({
  fieldName: 'connectedInboxes',
  fetcher: fetchConnectedInboxes
});
export const useConnectedInboxes = (skip = false) => useQuery(CONNECTED_INBOXES_QUERY, {
  skip
});