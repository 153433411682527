import { useEffect } from 'react';
import { isQA } from './urls';
/** intentionally private, use createEventBus to create instances */
function defineEventBus() {
  const subscribers = {};
  const subscribe = (eventType, handler) => {
    if (!subscribers[eventType]) {
      subscribers[eventType] = new Set();
    }
    subscribers[eventType].add(handler);
    return () => {
      subscribers[eventType].delete(handler);
    };
  };
  const broadcast = (eventType, payload) => {
    if (!subscribers[eventType]) {
      if (isQA()) {
        // eslint-disable-next-line no-console
        console.info(`EventBus: No listeners exist for ${eventType}, skipping broadcast.`);
      }
      return;
    }
    subscribers[eventType].forEach(cb => cb(payload));
  };
  return {
    subscribe,
    broadcast
  };
}
export function createEventBus() {
  const eventBus = defineEventBus();
  const broadcast = (type, payload) => eventBus.broadcast(type, payload);
  const subscribe = (type, handler) => eventBus.subscribe(type, handler);
  const useBroadcastEvent = type => payload => broadcast(type, payload);
  const useReceiveEvent = (type, handler) => useEffect(() => subscribe(type, handler), [type, handler]);
  return {
    broadcast,
    subscribe,
    useBroadcastEvent,
    useReceiveEvent
  };
}