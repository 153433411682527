import CallingProvider from 'calling-internal-common/call-provider/records/CallingProvider';
import { DEMO_WIDGET_REACT, DEMO_WIDGET_JS, DEMO_WIDGET_LOCAL, DEMO_WIDGET_REMOTE } from '../constants/StaticExternalProviderNames';
import { getSetting } from '../../local-settings/localSettings';
import once from '../../utils/once';
import { isExtensionsRemoteEnabled } from 'calling-internal-common/utils/isExtensionsRemoteEnabled';
const DEMO_WIDGET_KEY = 'installDemoWidget';
const DEMO_WIDGET_URL = 'https://github.hubspot.com/calling-extensions-sdk';
export let DEMO_WIDGET_VALUES;
(function (DEMO_WIDGET_VALUES) {
  DEMO_WIDGET_VALUES["APP_MINIMAL_JS"] = "app:js";
  DEMO_WIDGET_VALUES["APP_REACT_TS"] = "app";
  DEMO_WIDGET_VALUES["LOCAL"] = "local";
  DEMO_WIDGET_VALUES["REMOTE"] = "remote";
})(DEMO_WIDGET_VALUES || (DEMO_WIDGET_VALUES = {}));
const DEMO_WIDGET_VALUES_MAP = new Map([[DEMO_WIDGET_VALUES.APP_MINIMAL_JS, {
  name: DEMO_WIDGET_JS,
  url: `${DEMO_WIDGET_URL}/demo-minimal-js.html`,
  supportsInboundCalling: true,
  supportsChannelConnection: true
}], [DEMO_WIDGET_VALUES.APP_REACT_TS, {
  name: DEMO_WIDGET_REACT,
  url: `${DEMO_WIDGET_URL}/demo-react-ts.html`,
  supportsInboundCalling: true,
  supportsChannelConnection: true
}], [DEMO_WIDGET_VALUES.LOCAL, {
  name: DEMO_WIDGET_LOCAL,
  url: 'https://localhost:9025/',
  supportsCustomObjects: true,
  supportsInboundCalling: true,
  supportsChannelConnection: true
}], [DEMO_WIDGET_VALUES.REMOTE, {
  name: DEMO_WIDGET_REMOTE,
  url: 'https://github.hubspot.com/calling-extensions-sdk/pr-preview/pr-241/demo-react-ts.html',
  supportsCustomObjects: true,
  supportsInboundCalling: true,
  supportsChannelConnection: true
}]]);
export const getDemoWidgetCallingProvider = once(({
  name,
  url,
  supportsCustomObjects,
  supportsInboundCalling,
  supportsChannelConnection
}) => {
  return new CallingProvider({
    name,
    url,
    supportsCustomObjects: Boolean(supportsCustomObjects),
    supportsInboundCalling: Boolean(supportsInboundCalling),
    supportsChannelConnection: Boolean(supportsChannelConnection)
  });
});

/**
 * Retrieves and validates the Demo Widget value from local storage.
 * @returns a Demo Widget value if valid, undefined otherwise
 */
export const getDemoWidgetValue = () => {
  const setting = getSetting(DEMO_WIDGET_KEY);
  if (isExtensionsRemoteEnabled() && setting === DEMO_WIDGET_VALUES.REMOTE) {
    return DEMO_WIDGET_VALUES_MAP.get(DEMO_WIDGET_VALUES.REMOTE);
  }
  return DEMO_WIDGET_VALUES_MAP.get(setting);
};