export const replyRecEdited = inResponseToMessageId => ({
  key: 'editReplyRecommendation',
  properties: {
    action: 'Clicks to edit reply recommendation',
    inResponseToMessageId,
    platform: 'Helpdesk'
  }
});
export const replyRecSeen = inResponseToMessageId => ({
  key: 'renderReplyRecommendation',
  properties: {
    action: 'Systems displays reply recommendation',
    inResponseToMessageId,
    platform: 'Helpdesk'
  }
});
export const replyRecVisibility = type => ({
  key: 'toggleReplyRecommendationVisibility',
  properties: {
    action: 'Toggle reply recommendation visibility',
    platform: 'Helpdesk',
    type
  }
});