//@ts-expect-error file not typed
import { getTopLevelType } from '../../common-message-format/operators/commonMessageFormatGetters';
import { ENGAGEMENT_DETAILS_MESSAGE } from '../constants/engagementDetailsMessageType';
import { OBJECT_ASSOCIATIONS_CHANGED, OBJECT_PROPERTY_CHANGED } from '../constants/engagementRealtimeMessageTypes';
import { ENGAGEMENT_TYPE_IDS as supportedEngagementTypeIds } from '../constants/engagementTypes';
const hasField = (obj, fieldname) => {
  return typeof obj === 'object' && obj !== null && Object.hasOwn(obj, fieldname);
};
export const isEngagementAsyncMessage = message => Boolean(getTopLevelType(message) === ENGAGEMENT_DETAILS_MESSAGE && hasField(message, 'isAsync') && message.isAsync === true);
export const isRawEngagmentUpdateMessage = message => {
  return Boolean(getTopLevelType(message) === OBJECT_PROPERTY_CHANGED && hasField(message, 'objectTypeId') && supportedEngagementTypeIds.includes(message.objectTypeId));
};
export const isRawEngagmentCreationMessage = message => Boolean(getTopLevelType(message) === OBJECT_ASSOCIATIONS_CHANGED && hasField(message, 'fromObjectTypeId') && supportedEngagementTypeIds.includes(message.fromObjectTypeId));
export const isRawEngagementAsyncMessage = message => isRawEngagmentCreationMessage(message) || isRawEngagmentUpdateMessage(message);