'use es6';

import { Set as ImmutableSet } from 'immutable';
import { createSelector } from 'reselect';
import { buildSenderLocator } from 'conversations-message-history/senders/operators/buildSenderLocator';
import { AGENT } from 'conversations-message-history/common-message-format/constants/legacySenderTypes';
import { getUserId } from 'conversations-thread-data/auth/public/selectors';
import { getThreadPresence } from 'conversations-thread-data/presence/public/selectors';
const getThreadIdFromProps = (state, {
  threadId
}) => threadId;
const AGENT_CLIENT_ID_REGEX = new RegExp(`^(${AGENT})-(\\d+)`);
export const getPresentAgentLocators = createSelector([getThreadIdFromProps, getThreadPresence, getUserId], (threadId, entries, userId) => {
  if (!threadId || !entries) return ImmutableSet();
  const presenceEntries = entries[threadId] || {};
  return ImmutableSet(Object.keys(presenceEntries).reduce((accumulator, clientId) => {
    const match = (clientId || '').match(AGENT_CLIENT_ID_REGEX);
    if (!match) return accumulator;
    const [, senderType, senderId] = match;
    if (!senderType || !senderId || `${userId}` === `${senderId}`) return accumulator;
    return [/* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    ...accumulator, buildSenderLocator(senderType, senderId)];
  }, []));
});