import { useState, useCallback } from 'react';
import { addSuccess, addDanger
// @ts-expect-error untyped-module
} from 'customer-data-ui-utilities/alerts/Alerts';
import { deleteInvoice } from '../api/invoiceAPI';
import { trackUsage } from '../utils/tracking';
import { useSendCrmMessageTopic } from 'crm-message-bus/useSendCrmMessageTopic';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { INVOICE_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
const useDeleteInvoice = (invoice, updateInvoiceSidebarCard, closeSidebar) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const sendInvoiceDeletedMessage = useSendCrmMessageTopic(TOPIC_NAMES.OBJECT_DELETED);
  const onConfirmDeleteInvoice = useCallback(() => {
    trackUsage({
      action: 'Click delete invoice'
    });
    setIsLoading(true);
    deleteInvoice(invoice.objectId).then(() => {
      return new Promise(resolve => setTimeout(() => {
        updateInvoiceSidebarCard();
        // The order of the next 2 lines cannot change
        // The index page only subscribes to the object deleted message when the preview sidebar is open
        // so we need to send that first
        sendInvoiceDeletedMessage({
          objectTypeId: INVOICE_TYPE_ID,
          objectId: String(invoice.objectId)
        });
        closeSidebar();
        addSuccess('invoicesHighlightCard.alerts.delete.success', {}, {
          'data-test-id': 'delete-invoice-success'
        });
        resolve();
      }, 3000));
    }).catch(() => {
      addDanger('invoicesHighlightCard.alerts.delete.danger');
    }).finally(() => {
      setIsLoading(false);
      setIsModalOpen(false);
    });
  }, [invoice.objectId, updateInvoiceSidebarCard, sendInvoiceDeletedMessage, closeSidebar]);
  return {
    isDeleteModalOpen: isModalOpen,
    setDeleteModalOpen: setIsModalOpen,
    deleteLoading: isLoading,
    onConfirmDeleteInvoice
  };
};
export default useDeleteInvoice;