import { createSlice } from '@reduxjs/toolkit';
import { inboxUpdated } from 'find-and-filter-data/realtime-updates/public';
import { fetchCustomViews, fetchViews, pollViews } from 'find-and-filter-data/views-data/public';
import { generateViewId } from 'find-and-filter-data/views-schema/public';
import { Map as ImmutableMap } from 'immutable';
import { omnibusFulfilled } from '../../../omnibus/public';
import { applyOperationsToCountOffset } from '../updates/applyOperationsToCountOffset';
import { buildUpdatesFromInboxUpdate } from '../updates/buildUpdatesFromInboxUpdate';
import { buildUpdatesFromThreadListsUpdates } from '../updates/buildUpdatesFromThreadListsUpdates';
import { threadListsUpdated } from './viewCountsActions';
const initialState = {
  /**
   * The BE provides us exact counts for views whenever available as part of the
   * INBOX_UPDATE message - however this is only possible for global views. Views
   * that are agent specific(like Assigned to Me) cannot be provided via realtime
   * updates, and for those updates we must still use the calculated offset
   */
  countByViewId: ImmutableMap(),
  /**
   * Realtime updates are converted to an "offset" value which can be applied to
   * the count of a view to calculate the "current" or realtime count value.
   */
  countOffsetsByViewId: ImmutableMap()
};
export const viewCountsSlice = createSlice({
  name: 'viewCounts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(threadListsUpdated, (state, action) => {
      const {
        threadListsUpdate
      } = action.payload;

      // if we go back to threadListsUpdated, clear declarative counts in the
      // system
      state.countByViewId = initialState.countByViewId;
      const {
        operations
      } = buildUpdatesFromThreadListsUpdates(threadListsUpdate.threadListType, threadListsUpdate.updates);
      state.countOffsetsByViewId = applyOperationsToCountOffset(state.countOffsetsByViewId, operations);
    }).addCase(inboxUpdated, (state, action) => {
      const {
        message
      } = action.payload;
      state.countByViewId = message.viewCounts.reduce((acc, {
        viewKey,
        count
      }) => {
        const viewId = generateViewId(viewKey);
        return acc.set(viewId, count);
      }, state.countByViewId);
      const {
        operations
      } = buildUpdatesFromInboxUpdate(message.inboxMemberUpdates);
      state.countOffsetsByViewId = applyOperationsToCountOffset(state.countOffsetsByViewId, operations);
    }).addCase(fetchViews.fulfilled, () => {
      return initialState;
    }).addCase(fetchCustomViews.fulfilled, () => {
      return initialState;
    }).addCase(pollViews.fulfilled, () => {
      return initialState;
    }).addCase(omnibusFulfilled, () => {
      return initialState;
    });
  }
});