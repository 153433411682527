import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
import { useEffect } from 'react';
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/*
# Profilers Docs:

## Setup:
- Get React DevTools installed in your browser
- Open dev tool settings (a little gear icon on the React DevTools tab), and
  make sure you have "Highlight updates when component renders" enabled.
  -  You should see the highlight boxes when something in the inbox changes
     (thread switching for example)
- I recommend removing the `InboxMainViewSidebar` component from `InboxMainView`,
  since it can create excess noise that can make validating changes difficult.
- Set the local storage key to enabled the profiler:
   `window.localStorage.setItem('INBOX_RENDER_PROFILING', true)`

## Overview
This profiler will track total number of renders that occur during a given time.
Although this is not a perfect assessment of render performance (some renders are
very cheap while others can render only once or twice and be expensive),
I think it's good start for us as we try to be more render-performance aware.

Overall, the total variation I saw when running a test for thread switching
10 times was +/- 10 renders per iteration. Take this into account when testing
changes.  Anything that you're trying to reduce re-renders for by less than that
(saw for a single costly component), you're probably better off manually validating.

I would recommend using this tool as a supplement to manual flamechart validation
of render performance changes.  In isolation, render counting is not extremely useful
for most cases.

Always be aware of your setup conditions.  This tool is most powerful A-B
testing changes, and the threads involved play a crucial role in the number of renders you see.
For a more regressive scenario, I would recommend setting up an extremely static
inbox where nobody modifies anything so you can compare results week to week (or
RAD meeting to RAD meeting).

## Usage examples:

### Thread Switching:
A common use case that users site as spiking CPU performance is switching between threads.
To test this, make sure the global `inboxPerf` object is avaiable, and run:
`inboxPerf.runThreadSwitching(iterations = 4)`
Sit tight, and within about 30 seconds, the script will spit out a result in the form
of updates/iteration.

### Looping a custom case:
To use the module to run a custom case (say, clicking on a thread list between Email and Chat), you
can use the `recordForFn` method.
Ex.:
`inboxPerf.recordForFn(
  () => document.querySelector('.chatThreadListSelector').click(),
  iterations = 4
)`

### Manually running a reproduction case:
To Manually run a case, you can access the lower-level API directly.  This could be
useful for measuring renders during exterior events (think an incoming message).

To do this, one would:
- Reset the count:
  `inboxPerf.resetUpdateCount()`
- Start recording:
  `inboxPerf.startRecording()`
- Perform any reproduction case, like iteracting with the visitor widget

- Stop the recording:
  `inboxPerf.stopRecording()`

- Get the count:
  `inboxPerf.getUpdateCount()`

Again, in the scenario, it's very important that if you're attempting to validate
changes, you keep a very similar dom state.  Even adding a new message to a thread
between recordings could change your updateCount numbers significantly, sometimes
enough to be too noise for confirmation of improvements.

API:
startRecording: () => undefined, begins recording session
endRecording: () => undefined, stops recording session
resetUpdateCount: () => undefined, resets updateCount to 0
getUpdateCount: () => number, returns the update count
sleep: (delay: number) => Promise, returns a promise that will resolve after delay (in milliseconds)
loopRepro: (fn, iterations) => Promise, loops over a reproduction case for a number of iterations.
  Returns a promise.  If your reproduction fn does not return a promise, it will
  automatically sleep 5 seconds between calls of the reproduction case.
recordForFn: (fn, iterations) => undefined, This resets the count, starts recording,
  and runs your repro case with loopRepro, and console logs your update update count per iteration.
clickFirstUnselected: () => Promise, clicks the first unselected thread list member and returns a 5s sleep Promise
runThreadSwitching: () => undefined, calls recordForFn with the clickFirstUnselected repro case

*/

class InboxPerformanceProfiler {
  constructor() {
    this.shouldTrace = false;
    this.updateCount = 0;
  }
  startRecording() {
    if (this.updateCount !== 0) {
      // eslint-disable-next-line no-console
      console.warn('starting recording with a non-zero updateCount');
    }
    this.shouldTrace = true;
  }
  endRecording() {
    this.shouldTrace = false;
  }
  resetUpdateCount() {
    this.updateCount = 0;
  }
  getUpdateCount() {
    return this.updateCount;
  }
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  clickFirstUnselected() {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    document.querySelector('[data-test-id=thread-list-member-row][data-selected=false]')
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'click' does not exist on type 'Element'.
    .click();
    return sleep(5000);
  }
  loopRepro(fn, iterations) {
    if (iterations === 1) return this.clickFirstUnselected();
    const maybePromise = fn();
    return (maybePromise.then ? fn : this.sleep(5000)).then(() => this.loopRepro(fn, iterations - 1));
  }
  recordForFn(fn, iters = 4) {
    if (this.shouldTrace) {
      // eslint-disable-next-line no-console
      console.log('Already recording, aborting...');
      return;
    }
    this.resetUpdateCount();
    this.startRecording();
    this.loopRepro(fn, iters).then(() => {
      this.endRecording();
      // eslint-disable-next-line no-console
      console.log(`${this.updateCount / iters} updates/iteration`);
      this.resetUpdateCount();
    }).catch(error => {
      reportError({
        error
      });
    });
  }
  runThreadSwitching(iters) {
    return this.recordForFn(this.clickFirstUnselected, iters);
  }
}
export const InboxReactProfiler = () => {
  let shouldProfile;
  try {
    shouldProfile = window.localStorage.getItem('INBOX_RENDER_PROFILING') === 'true';
  } catch (error) {
    shouldProfile = false;
  }
  useEffect(() => {
    if (shouldProfile) {
      window.inboxPerf = new InboxPerformanceProfiler();
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__.on('traceUpdates', traceUpdates => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'inboxPerf' does not exist on type 'Windo... Remove this comment to see the full error message
        if (window.inboxPerf.shouldTrace) {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'inboxPerf' does not exist on type 'Windo... Remove this comment to see the full error message
          window.inboxPerf.updateCount += traceUpdates.size;
        }
      });
    }
  }, [shouldProfile]);
  return null;
};