// @ts-expect-error no ts for this repo yet
import { isAssignmentUpdateMessage } from 'conversations-message-history/assignment-update-message/operators/isAssignmentUpdateMessage';
// @ts-expect-error no ts for this repo yet
import { getTopLevelType } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { client } from 'conversations-inbox-lib/data-fetching-client/public/clients';
import { CHANNEL_CHANGE } from 'conversations-internal-pub-sub/channel-change/records/ChannelChange';
export const handleThreadMessage = ({
  data: message
}) => {
  if (isAssignmentUpdateMessage(message) || getTopLevelType(message) === CHANNEL_CHANGE) {
    client.refetchQueries({
      include: ['fetchCurrentAssignee']
    }).catch(error => {
      reportError({
        error
      });
    });
  }
};