'use es6';

import InboxSettingsRecord from '../data/InboxSettingsRecord';
import { enableAccount } from 'InboxConnectUtils/actions/EmailIntegrationActions';
import { transformGenericToExchange, transformExchangeToGeneric, transformFacsimileResponseToGeneric } from '../data/transforms';
import { filterFalseyValuesFromObject } from '../utils/ObjectUtils';
const baseUrl = 'facsimile/v1/inboxes';
const create = (apiClient, data) => apiClient.put(`${baseUrl}/generic/custom`, {
  type: 'PUT',
  timeout: 60000,
  data
});
export const discover = (apiClient, email) => apiClient.get(`${baseUrl}/generic-settings/discover?email=${email}`).then(map => {
  const inboxSettings = filterFalseyValuesFromObject({
    imapHost: map.getIn(['imap', 'host']),
    imapPort: map.getIn(['imap', 'port']),
    smtpHost: map.getIn(['smtp', 'host']),
    smtpPort: map.getIn(['smtp', 'port'])
  });
  inboxSettings.useStaticIps = map.get('use_static_ips');
  inboxSettings.acceptUntrustedCert = map.get('user_accepts_untrusted_certificates');
  inboxSettings.settingsSource = map.get('settings_source');
  return InboxSettingsRecord(inboxSettings);
}).catch(() => InboxSettingsRecord());
const createFacsimilePayload = inboxRecord => {
  const facsimilePayload = {
    login_info: {
      email: inboxRecord.email,
      password: inboxRecord.password
    },
    settings: {
      custom_username: inboxRecord.customUsername !== inboxRecord.email ? inboxRecord.customUsername : null,
      username_strategy: inboxRecord.customUsername ? 'CUSTOM' : 'EMAIL',
      imap_host_name: inboxRecord.imapHost,
      imap_port: inboxRecord.imapPort,
      smtp_host_name: inboxRecord.smtpHost,
      smtp_port: inboxRecord.smtpPort,
      user_accepts_untrusted_certificates: inboxRecord.acceptUntrustedCert
    }
  };
  if (!inboxRecord.smtpSameCreds) {
    facsimilePayload.login_info_smtp = {
      email: inboxRecord.smtpUsername,
      password: inboxRecord.smtpPassword
    };
  }
  return facsimilePayload;
};
const transformError = err => {
  if (err && err.status >= 500) {
    return {
      error: '500'
    };
  }
  const data = err && err.data && JSON.parse(err.data) || {};
  const {
    message: errorCode
  } = data;
  const error = (errorCode || err.status || '0').toString();
  return {
    error
  };
};
export const createImapInbox = (apiClient, portalId, inboxRecord) => {
  let newRecord;
  const enableImapAccount = inbox => enableAccount(apiClient, inbox.email);
  const mergeFacsimileResponse = responseInboxRecord => {
    newRecord = inboxRecord.mergeWith((userInput, serverResponse) => serverResponse || userInput, responseInboxRecord);
    return newRecord;
  };
  return create(apiClient, createFacsimilePayload(inboxRecord)).then(transformFacsimileResponseToGeneric).then(mergeFacsimileResponse).then(enableImapAccount).then(() => newRecord).catch(transformError);
};
export const createExchange = (apiClient, data) => apiClient.put(`${baseUrl}/exchange/create`, {
  type: 'PUT',
  timeout: 60000,
  data
});
const discoverExchange = (apiClient, {
  email,
  password,
  customUsername
}) => apiClient.put(`${baseUrl}/exchange/autodiscover`, {
  data: {
    email,
    password,
    customUsername
  },
  query: {
    username: customUsername
  }
});
export const createExchangeInbox = (apiClient, portalId, exchangeRecord) => {
  let newRecord;
  const inboxRecord = transformExchangeToGeneric(exchangeRecord);
  const enableImapAccount = inbox => enableAccount(apiClient, inbox.email);
  const mergeFacsimileResponse = responseInboxRecord => {
    newRecord = exchangeRecord.mergeWith((userInput, serverResponse) => serverResponse || userInput, responseInboxRecord);
    return newRecord;
  };
  const createOrDiscover = exchangeRecord.exchangeUrl ? createExchange(apiClient, createFacsimilePayload(inboxRecord)) : discoverExchange(apiClient, exchangeRecord);
  return createOrDiscover.then(transformFacsimileResponseToGeneric).then(transformGenericToExchange).then(mergeFacsimileResponse).then(enableImapAccount).then(() => newRecord).catch(transformError);
};