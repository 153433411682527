'use es6';

const CONVERSATIONS_ADMIN_SCOPE = 'conversations-admin';

// This scope should ONLY be used in the context of inbox connect, unles you know what
// you're doing. See Atlas: https://private.hubteam.com/atlas/scopes/1139/roles
const JITA_SCOPE = 'jita-user';
const extractScopesAndRoles = userInfo => {
  const {
    scopes,
    roles
  } = userInfo && userInfo.user || {};
  return {
    scopes: scopes || [],
    roles: roles || []
  };
};
export const hasInboxConnectAccess = userInfo => !extractScopesAndRoles(userInfo).scopes.includes(JITA_SCOPE);
export const isConversationsAdmin = userInfo => extractScopesAndRoles(userInfo).scopes.indexOf(CONVERSATIONS_ADMIN_SCOPE) >= 0;