import { AI_CHATBOT } from '../constants/RoutingTypes';
import { FALLBACK } from '../constants/keyPaths';
import { getFallback } from '../operators/routingRulesGetters';
import { setFallback } from '../utils/buildRoutingRules';
import RoutingRuleBase from './RoutingRuleBase';
export default class AiChatbotRoutingRules extends RoutingRuleBase({
  '@type': AI_CHATBOT,
  fallback: null,
  aiChatbotId: null
}, 'AiChatbotRoutingRules') {
  constructor(options) {
    const aiChatbotId = (options === null || options === void 0 ? void 0 : options.aiChatbotId) || null;
    super(options);
    return this.set('aiChatbotId', aiChatbotId).setIn(FALLBACK, getFallback(setFallback(this)));
  }
}
export const buildAiChatbotRoutingRules = options => new AiChatbotRoutingRules(options);