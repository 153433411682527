'use es6';

/**
 * Converts responses to immutable objects using the immutable library.
 * Note: Depends on immutable.
 *
 * In most cases, you should use immutableResponse to convert a string json response to an immutable object.
 * In cases where you have other middlewares that expect a plain js object, you can compose jsonResponse and
 * fromObjectToImmutableResponse independently. Eg:
 *
 * createStack(
 *   // ...
 *   responseMiddlewareThatExpectsStrings
 *   jsonResponse
 *   responseMiddlewareThatExpectsJsObjects
 *   fromObjectToImmutableResponse
 *   // ...
 * );
 */
import { fromJS } from 'immutable';
import { createStack } from 'hub-http';
import { onResponse, jsonResponse } from 'hub-http/middlewares/core';
import { setIf } from 'hub-http/helpers/update';
export const immutableResponseWithReviver = fromJSReviver => {
  const middleware = onResponse(response => setIf(typeof response.data === 'object', 'data', fromJS(response.data, fromJSReviver))(response));
  return createStack(jsonResponse, middleware);
};
export const immutableResponse = immutableResponseWithReviver();