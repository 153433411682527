import apiClient from 'hub-http/clients/apiClient';
import { registerMutation, useMutation } from 'data-fetching-client';
const payOnFileCheckoutSession = ({
  checkoutSessionId,
  expectedPaymentAmount
}) => apiClient.post(`payments-checkout/v1/checkout-sessions/on-file/pay`, {
  data: {
    checkoutSessionId,
    expectedPaymentAmount
  }
});
const PAY_ON_FILE_CHECKOUT_SESSION = registerMutation({
  fieldName: 'payOnFileCheckoutSession',
  args: ['checkoutSessionId', 'expectedPaymentAmount'],
  fetcher: payOnFileCheckoutSession
});
export const usePayOnFileCheckoutSession = () => {
  return useMutation(PAY_ON_FILE_CHECKOUT_SESSION);
};