import quickFetch from 'quick-fetch';
import { ApolloLink } from '@apollo/client';
// @ts-expect-error js module
import { getEarlyRequesterAsPromise } from './getEarlyRequesterAsPromise';
export const earlyRequestLink = new ApolloLink((operation, forward) => {
  const {
    operationName,
    variables
  } = operation;
  const cardLocation = variables.cardLocation || '';
  // See: https://git.hubteam.com/HubSpot/CRM/blob/9621b95809e669f84172b06015331a4b42ee68f4/crm_ui/static/js/graphql-early-requester/profileGraphQLEarlyRequester.js#L51-L52
  const requestName = operationName + cardLocation + variables.objectType + variables.subjectId;
  const earlyGraphQLRequest = quickFetch.getRequestStateByName(requestName);
  if (earlyGraphQLRequest && !earlyGraphQLRequest.error) {
    operation.setContext({
      earlyRequest: getEarlyRequesterAsPromise(requestName)
    });
  }
  return forward(operation);
});