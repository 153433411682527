export const HS_CURRENCY = 'hs_currency';
export const HS_LOCALE = 'hs_locale';
export const HS_INVOICE_PAYMENT_COLLECTION_METHOD = 'hs_invoice_payment_collection_method';
export const HS_ALLOW_PARTIAL_PAYMENTS = 'hs_allow_partial_payments';
export const HS_COMMENTS = 'hs_comments';
export const HS_DOMAIN = 'hs_domain';
export const HS_SLUG = 'hs_slug';
export const HS_TIMEZONE = 'hs_timezone';
export const HS_TAX_ID = 'hs_tax_id';
export const HS_STORE_PAYMENT_METHOD_AT_CHECKOUT = 'hs_store_payment_method_at_checkout';
export const HS_ENABLE_AUTOMATED_TAXES = 'hs_enable_automated_taxes';
export const HS_AUTO_TAX_AMOUNT = 'hs_auto_tax_amount';
export const HS_TAXES_TOTAL = 'hs_taxes_total';
export const HS_TAX_AMOUNT = 'hs_tax_amount';
export const HS_TAX_RATE = 'hs_tax_rate';
export const HS_POST_TAX_AMOUNT = 'hs_post_tax_amount';
export const HS_TAX_LABEL = 'hs_tax_label';
export const HS_RECIPIENT_COMPANY_ADDRESS = 'hs_recipient_company_address';
export const HS_RECIPIENT_COMPANY_ADDRESS2 = 'hs_recipient_company_address2';
export const HS_RECIPIENT_COMPANY_CITY = 'hs_recipient_company_city';
export const HS_RECIPIENT_COMPANY_STATE = 'hs_recipient_company_state';
export const HS_RECIPIENT_COMPANY_ZIP = 'hs_recipient_company_zip';
export const HS_RECIPIENT_COMPANY_COUNTRY = 'hs_recipient_company_country';
export const HS_RECIPIENT_COMPANY_COUNTRY_CODE = 'hs_recipient_company_country_code';
export const HS_RECIPIENT_SHIPPING_NAME = 'hs_recipient_shipping_name';
export const HS_RECIPIENT_SHIPPING_ADDRESS = 'hs_recipient_shipping_address';
export const HS_RECIPIENT_SHIPPING_ADDRESS2 = 'hs_recipient_shipping_address2';
export const HS_RECIPIENT_SHIPPING_CITY = 'hs_recipient_shipping_city';
export const HS_RECIPIENT_SHIPPING_STATE = 'hs_recipient_shipping_state';
export const HS_RECIPIENT_SHIPPING_ZIP = 'hs_recipient_shipping_zip';
export const HS_RECIPIENT_SHIPPING_COUNTRY = 'hs_recipient_shipping_country';
export const HS_RECIPIENT_SHIPPING_COUNTRY_CODE = 'hs_recipient_shipping_country_code';
export const INVOICE_PROPERTY_VALUES = {
  [HS_INVOICE_PAYMENT_COLLECTION_METHOD]: {
    AUTOMATIC: 'automatic',
    NONE: 'none',
    MANUAL: 'manual'
  }
};
export const INVOICE_STATUS_DETAILS_PROPERTY_VALUES = {
  PARTIALLY_PAID: 'partially_paid'
};
export const CONTACT_ADDRESS_PROPERTIES = {
  ADDRESS: 'address',
  ADDRESS_2: 'address2',
  CITY: 'city',
  STATE: 'state',
  COUNTRY: 'country',
  ZIP: 'zip',
  HS_COUNTRY_REGION_CODE: 'hs_country_region_code'
};
export const COMPANY_ADDRESS_PROPERTIES = {
  ADDRESS: 'address',
  ADDRESS_2: 'address2',
  CITY: 'city',
  STATE: 'state',
  COUNTRY: 'country',
  ZIP: 'zip',
  HS_COUNTRY_CODE: 'hs_country_code'
};