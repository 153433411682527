import { setTabUnreadCount } from '../browser/util/setTabUnreadCount';
import { threadsUpdated } from 'find-and-filter-data/view-member-optimistic-updates/public';
import { viewMemberUpdated } from 'find-and-filter-data/realtime-updates/public';
import { selectViewMembersWithUpdates } from 'find-and-filter-data/view-members-data/public';
import { hasLatestReceivedTSIncreased } from '../thread-list-member/operators/hasLatestReceivedTSIncreased';
import { getCurrentViewId } from '../thread-list/selectors/getCurrentViewId';
const InboxNotificationMiddleware = store => {
  let focusEvent = null;
  return next => action => {
    const prevState = store.getState();
    const appliedAction = next(action);
    const nextState = store.getState();
    const receivedVisitorMessage = hasLatestReceivedTSIncreased(selectViewMembersWithUpdates(prevState, {
      viewId: getCurrentViewId(prevState)
    }), selectViewMembersWithUpdates(nextState, {
      viewId: getCurrentViewId(nextState)
    }));
    const haveMembersUpdated = action.type === threadsUpdated.type || action.type === viewMemberUpdated.type;
    if (haveMembersUpdated && receivedVisitorMessage && !document.hasFocus()) {
      setTabUnreadCount(1);
      window.removeEventListener('focus', focusEvent);
      focusEvent = window.addEventListener('focus', () => {
        setTabUnreadCount(0);
        window.removeEventListener('focus', focusEvent);
      });
    }
    return appliedAction;
  };
};
export default InboxNotificationMiddleware;