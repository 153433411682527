import { createTracker } from 'usage-tracker';
import events from 'calling-lifecycle-usage-tracker/events.yaml';
import { logCallingError } from 'calling-error-reporting/report/error';
const _tracker = createTracker({
  events,
  onError(err) {
    console.error(err);
    logCallingError({
      errorMessage: `Inbound Calling Usage Tracker - Error`,
      extraData: {
        err
      }
    });
  }
});
export class InboundCallingTracker {
  /** Call Tab Tracking **/
  trackCallTabPageLoad() {
    _tracker.track('callTabPageLoad');
  }
  trackCallTabAlertView({
    alertType
  }) {
    _tracker.track('callTabAlertView', {
      alertType
    });
  }
  trackCallTabBannerView({
    bannerType,
    errorCode,
    startCallError
  }) {
    _tracker.track('callTabBannerView', {
      bannerType,
      errorCode,
      startCallError
    });
  }
  trackInboundCallNotificationShown({
    callId,
    isChannelConnectedRepNumber,
    connectedChannelType,
    numberOfUsersRung
  }) {
    _tracker.track('inboundCallNotificationShown', {
      callId,
      isChannelConnectedRepNumber,
      connectedChannelType,
      numberOfUsersRung
    });
  }
  trackCallTabAnswerCall() {
    _tracker.track('callTabInteraction', {
      action: 'answer call'
    });
  }
  trackCallTabRejectCall() {
    _tracker.track('callTabInteraction', {
      action: 'reject call'
    });
  }
  trackCallTabAnswerTransfer() {
    _tracker.track('callTabInteraction', {
      action: 'answer transfer request'
    });
  }
  trackCallTabRejectTransfer() {
    _tracker.track('callTabInteraction', {
      action: 'reject transfer request'
    });
  }
  trackCallTabReconnectButtonClick() {
    _tracker.track('callTabInteraction', {
      action: 'click reconnect button'
    });
  }
  trackCallTabCriticalErrorButtonClick() {
    _tracker.track('callTabInteraction', {
      action: 'click open new tab button'
    });
  }
  trackCallTabHangUpCall() {
    _tracker.track('callTabInteraction', {
      action: 'hang up call'
    });
  }
  trackCallTabMuteClick() {
    _tracker.track('callTabInteraction', {
      action: 'mute/unmute button click'
    });
  }
  trackCallTabOpenKeypad() {
    _tracker.track('callTabInteraction', {
      action: 'open keypad'
    });
  }
  trackCallTabAudioDevicesClick() {
    _tracker.track('callTabInteraction', {
      action: 'audio devices icon click'
    });
  }
  trackCallTabAudioDevicesTest() {
    _tracker.track('callTabInteraction', {
      action: 'open test audio devices'
    });
  }
  trackCallTabStartOutboundCall({
    appIdentifier
  }) {
    _tracker.track('callTabInteraction', {
      action: 'start call from dropdown',
      appIdentifier
    });
  }
  trackInitiateTransfer() {
    _tracker.track('callTabInteraction', {
      action: 'initiate transfer request button click'
    });
  }
  trackConfirmTransferHandoff() {
    _tracker.track('callTabInteraction', {
      action: 'confirm transfer handoff button click'
    });
  }
  trackCancelTransferRequest() {
    _tracker.track('callTabInteraction', {
      action: 'cancel transfer request button click'
    });
  }
  trackAnswerTransferRequest() {
    _tracker.track('callTabInteraction', {
      action: 'answer transfer request button click'
    });
  }
  trackRejectTransferRequest() {
    _tracker.track('callTabInteraction', {
      action: 'reject transfer request button click'
    });
  }
  trackHoldButtonClick() {
    _tracker.track('callTabInteraction', {
      action: 'hold button click'
    });
  }
  trackRecordButtonClick() {
    _tracker.track('callTabInteraction', {
      action: 'record/unrecord button click'
    });
  }
}
export default new InboundCallingTracker();