export const getLocalStorageValue = getStorageStrategyValue(localStorage);
export const setLocalStorageValue = setStorageStrategyValue(localStorage);
export const clearLocalStorageValue = clearStorageStrategyValue(localStorage);
export const getSessionStorageValue = getStorageStrategyValue(sessionStorage);
export const setSessionStorageValue = setStorageStrategyValue(sessionStorage);
export const clearSessionStorageValue = clearStorageStrategyValue(sessionStorage);
function getStorageStrategyValue(strategy) {
  return function getStoredValue(key) {
    try {
      const entry = strategy.getItem(key);
      if (!entry) {
        return null;
      }
      return JSON.parse(entry);
    } catch (_unused) {
      return null;
    }
  };
}
function setStorageStrategyValue(strategy) {
  return function setStoredValue(key, value) {
    try {
      strategy.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
    } catch (_unused2) {
      return;
    }
  };
}
function clearStorageStrategyValue(strategy) {
  return function clearStoredValue(key) {
    try {
      strategy.removeItem(key);
    } catch (_unused3) {
      return;
    }
  };
}