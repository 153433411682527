'use es6';

import { Map as ImmutableMap } from 'immutable';
import reduce from 'transmute/reduce';
import { CONTACTS, CRM_UI } from 'customer-data-objects/property/PropertySourceTypes';
import ContactRecord from 'customer-data-objects/contact/ContactRecord';
import { userScopes } from 'crm_data/scopes/UserScopes';
import { send } from 'crm_data/api/ImmutableAPI';
import { del as sharedAPIDelete } from 'crm_data/inboundDB/sharedAPI';
import { POST, PUT, DELETE, GET } from 'crm_data/constants/HTTPVerbs';
import { EMPTY } from 'crm_data/constants/LoadingStatus';
import { getQueryParams } from './ContactsExtraParams';
import { byIds } from './ContactsAPIQuery';
import quickFetch from 'quick-fetch';
const BASE_URI = 'contacts/v1/contact';
const BASE_URI_V2 = 'contacts/v2/contact';
const BASE_URI_V3 = 'contacts/v3/contacts';
const URI_CREATE = 'companies/v2/create-contact';
const URI_CREATE_MARKETABLE = 'companies/v2/create-contact?setMarketable=true';
const CONTACT_EMAIL = 'contacts/v1/contactemail';
const getParamOptions = () => {
  return userScopes().then(scopes => ImmutableMap({
    showListMemberships: scopes.has('contacts-lists-read')
  }));
};
export function createContact(payload) {
  return send({
    headers: {
      'X-Properties-Source': CONTACTS,
      'X-Properties-SourceId': CRM_UI
    },
    type: POST
  }, URI_CREATE, {
    properties: payload
  }, result => ImmutableMap().set(result.vid, ContactRecord.fromJS(result)));
}
export function createMarketableContact(payload) {
  return send({
    headers: {
      'X-Properties-Source': CONTACTS,
      'X-Properties-SourceId': CRM_UI
    },
    type: POST
  }, URI_CREATE_MARKETABLE, {
    properties: payload
  }, result => ImmutableMap().set(result.vid, ContactRecord.fromJS(result)));
}
export function deleteContact(vid) {
  return sharedAPIDelete(`${BASE_URI}/vid/${vid}`);
}
export function getFetchQueryParams(ids, options) {
  return getQueryParams(options).merge(byIds(ids));
}
const fetchContacts = (ids, options) => send({
  headers: {
    'X-Properties-Source': CONTACTS,
    'X-Properties-SourceId': CRM_UI
  },
  type: GET
}, `${BASE_URI}/vids/batch/`, getFetchQueryParams(ids, options), result => reduce(ImmutableMap(), (coll, val, key) => coll.set(key, ContactRecord.fromJS(val)), result)).catch(error => {
  if (error.status === 404) {
    return EMPTY;
  }
  throw error;
});
const paramsToSortedQueryString = immutableParams => {
  const params = immutableParams.toJS();
  const paramArray = Object.entries(params).reduce((collector, [param, value]) => {
    if (value !== undefined && value !== '' && !(Array.isArray(value) && value.length === 0)) {
      collector.push(`${param}=${value}`);
    }
    return collector;
  }, []);
  return paramArray.sort().join('&');
};
export function getQuickFetchKey(ids, options) {
  const queryParams = getFetchQueryParams(ids, options);
  //To match quickfetch, we need to sort the query string.
  const sortedParamString = paramsToSortedQueryString(queryParams);
  const contactIds = ids.sort().join();
  return `quickFetchContact:${contactIds}:${sortedParamString}`;
}
const quickFetchContactRequest = (ids, options, fallback) => {
  const quickFetchKey = getQuickFetchKey(ids, options);
  const quickFetchRequest = quickFetch.getRequestStateByName(quickFetchKey);
  if (quickFetchRequest) {
    return new Promise((resolve, reject) => {
      quickFetchRequest.whenFinished(result => {
        resolve(reduce(ImmutableMap(), (coll, val, key) => coll.set(key, ContactRecord.fromJS(val)), result));
        quickFetch.removeEarlyRequest(quickFetchKey);
      });
      quickFetchRequest.onError((__xhr, __errorMsg) => {
        quickFetch.removeEarlyRequest(quickFetchKey);
        fallback().then(resolve, reject);
      });
    });
  }
  return fallback();
};
export const fetchWithQuickFetch = ids => {
  return getParamOptions().then(options => {
    const fallback = () => fetchContacts(ids, options);
    return quickFetchContactRequest(ids, options, fallback);
  });
};
export function fetch(ids) {
  return getParamOptions().then(options => {
    return fetchContacts(ids, options);
  });
}
export function fetchByEmail(email) {
  return send({
    headers: {
      'X-Properties-Source': CONTACTS,
      'X-Properties-SourceId': CRM_UI
    },
    type: POST
  },
  // we use a batch endpoint here as the single-fetch endpoint requires
  // putting the contact email in the uri itself, which is a security risk
  // for more info see https://issues.hubspotcentral.com/browse/HMSP-1683
  `${BASE_URI_V2}/emails/batch`, [email], result => Object.keys(result).reduce((acc, vid) => acc.set(vid, ContactRecord.fromJS(result[vid])), ImmutableMap()));
}
export function updateContactProperties(contact, updates) {
  const vid = contact.get('vid');
  return send({
    headers: {
      'X-Properties-Source': CONTACTS,
      'X-Properties-SourceId': CRM_UI,
      'X-Properties-Flag': 'FORCE_LIFECYCLE_STAGE'
    },
    type: POST
  }, `${BASE_URI}/vid/${contact.get('vid')}/profile`, {
    vid,
    properties: updates.reduce((acc, value, property) => {
      acc.push({
        property,
        value,
        source: CRM_UI
      });
      return acc;
    }, [])
  });
}
export function promoteEmailToPrimary(vid, email) {
  return send({
    headers: {
      'X-Properties-Source': CONTACTS,
      'X-Properties-SourceId': CRM_UI
    },
    type: POST
  }, `${CONTACT_EMAIL}/${vid}/secondary-email/${encodeURIComponent(email)}/promote`);
}
export function addSecondaryEmail(vid, email) {
  return send({
    headers: {
      'X-Properties-Source': CONTACTS,
      'X-Properties-SourceId': CRM_UI
    },
    type: POST
  }, `${CONTACT_EMAIL}/${vid}/secondary-email/${encodeURIComponent(email)}`);
}
export function deleteSecondaryEmail(vid, email) {
  return send({
    headers: {
      'X-Properties-Source': CONTACTS,
      'X-Properties-SourceId': CRM_UI
    },
    type: DELETE
  }, `${CONTACT_EMAIL}/${vid}/secondary-email/${encodeURIComponent(email)}`);
}
export function updateSecondaryEmail(vid, prevEmail, nextEmail) {
  return send({
    headers: {
      'X-Properties-Source': CONTACTS,
      'X-Properties-SourceId': CRM_UI
    },
    type: PUT
  }, `${CONTACT_EMAIL}/${vid}/secondary-email/${encodeURIComponent(prevEmail)}/${encodeURIComponent(nextEmail)}`);
}
export function removeEmail(vid, email) {
  return send({
    headers: {
      'X-Properties-Source': CONTACTS,
      'X-Properties-SourceId': CRM_UI
    },
    type: DELETE
  }, `${CONTACT_EMAIL}/${vid}/secondary-email/${encodeURIComponent(email)}`);
}
export function fetchById(id) {
  return getParamOptions().then(options => {
    return send({
      headers: {
        'X-Properties-Source': CONTACTS,
        'X-Properties-SourceId': CRM_UI
      },
      type: GET
    },
    // includeDeletes=true to include deleted/merged contacts
    `${BASE_URI_V3}/${id}?includeDeletes=true`, getQueryParams(options), result => ContactRecord.fromJS(result)).catch(error => {
      if (error.status === 404) {
        return EMPTY;
      }
      throw error;
    });
  });
}