import { PAGE_NAVIGATION } from '../constants/EventListeners';
export default function handlePageNavigation({
  subjectId,
  objectTypeId
}) {
  // Send back to host page
  if (window.hsCalling && window.hsCalling.triggerEvent) window.hsCalling.triggerEvent({
    event: PAGE_NAVIGATION,
    data: {
      subjectId,
      objectTypeId
    }
  });
}