import { getRecipients } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { isCommonMessageFormat, isEmailCMF
// @ts-expect-error module not typed
} from 'conversations-message-history/common-message-format/operators/cmfComparators';
import { getActorId, getName, getDeliveryIdentifier } from 'conversations-message-history/common-message-format/operators/recipientGetters';
export const getRecipientName = ({
  recipient,
  friendlyNames = []
}) => {
  var _getActorId, _getDeliveryIdentifie, _getDeliveryIdentifie2, _getName;
  const recipientActorId = (_getActorId = getActorId(recipient)) !== null && _getActorId !== void 0 ? _getActorId : null;
  const recipientEmail = (_getDeliveryIdentifie = (_getDeliveryIdentifie2 = getDeliveryIdentifier(recipient)) === null || _getDeliveryIdentifie2 === void 0 ? void 0 : _getDeliveryIdentifie2.value) !== null && _getDeliveryIdentifie !== void 0 ? _getDeliveryIdentifie : null;
  const recipientName = (_getName = getName(recipient)) !== null && _getName !== void 0 ? _getName : null;
  const matchedFriendlyName = friendlyNames.find(({
    genericRecipient: friendlyRecipient
  }) => {
    var _friendlyRecipient$de, _friendlyRecipient$de2, _friendlyRecipient$ac, _friendlyRecipient$na;
    if (!friendlyRecipient) return false;
    const friendlyIdentifier = (_friendlyRecipient$de = (_friendlyRecipient$de2 = friendlyRecipient.deliveryIdentifier) === null || _friendlyRecipient$de2 === void 0 ? void 0 : _friendlyRecipient$de2.value) !== null && _friendlyRecipient$de !== void 0 ? _friendlyRecipient$de : null;
    const friendlyActorId = (_friendlyRecipient$ac = friendlyRecipient.actorId) !== null && _friendlyRecipient$ac !== void 0 ? _friendlyRecipient$ac : null;
    const friendlyRecipientName = (_friendlyRecipient$na = friendlyRecipient.name) !== null && _friendlyRecipient$na !== void 0 ? _friendlyRecipient$na : null;
    return friendlyIdentifier === recipientEmail && friendlyActorId === recipientActorId && friendlyRecipientName === recipientName;
  });
  return (matchedFriendlyName === null || matchedFriendlyName === void 0 ? void 0 : matchedFriendlyName.resolvedFriendlyName) || recipientEmail;
};
export const getMessageRecipientNames = ({
  message,
  friendlyNames = []
}) => {
  if (isCommonMessageFormat(message)) {
    var _getRecipients;
    const allRecipients = ((_getRecipients = getRecipients(message)) === null || _getRecipients === void 0 ? void 0 : _getRecipients.toJS()) || [];
    if (isEmailCMF(message)) {
      return allRecipients.reduce((recipientsList, messageRecipient) => {
        const matchedRecipient = getRecipientName({
          recipient: messageRecipient,
          friendlyNames
        });
        return matchedRecipient ? [...recipientsList, matchedRecipient] : recipientsList;
      }, []);
    } else {
      // check shape of non-email recipients here in future work?
      return [];
    }
  } else {
    // For now, not intending to return display names for non-CMF messages
    return [];
  }
};