import { getSettingString } from 'calling-internal-common/utils/localSettingsOperators';
const SUPPORTS_REMOTE_KEY = 'supportsRemote';
const isExtensionsRemoteEnabledInLocalStorage = () => {
  const setting = getSettingString(SUPPORTS_REMOTE_KEY);
  return setting === 'true';
};

/**
 * Toggles the calling remote in all HS tabs
 * @param gates
 * @returns boolean
 */
export const isExtensionsRemoteEnabled = () => {
  return isExtensionsRemoteEnabledInLocalStorage();
};